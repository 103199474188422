<template>
  <span class="selection-switcher">
    {{ $t('select') }} <a @click="$emit('new-selection', elements.map(el => el.id))">{{ $t('all') }}</a> - <a @click="$emit('new-selection', [])">{{ $t('none') }}</a> - <a @click="invertSelection">{{ $t('invert') }}</a>
  </span>
</template>

<script>

export default {
  props: {
    elements: {
      type: Array,
      required: true,
    },
    elementsSelectedId: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { };
  },
  methods: {
    invertSelection() {
      const newSelection = [];
      for (const element of this.elements) {
        if (this.elementsSelectedId.indexOf(element.id) < 0) newSelection.push(element.id);
      }
      this.$emit('new-selection', newSelection);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "select": "Selection:",
    "all": "all",
    "none": "none",
    "invert": "invert"
  },
  "fr": {
    "select": "Sélection :",
    "all": "tout",
    "none": "aucun",
    "invert": "inverse"
  }
}
</i18n>
