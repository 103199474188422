<template>
  <f7-button
    large
    block
    outline-ios
    outline-aurora
    raised-md
    :icon-ios="ios"
    :icon-aurora="aurora"
    :icon-md="md"
    v-bind="$attrs"
    class="provider"
    :class="name"
  >
    <span>{{ label }}</span>
  </f7-button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'signup',
    },
  },
  data() {
    return {
      ios: 'f7:chat_bubble_2_fill',
      aurora: 'f7:chat_bubble_2_fill',
      md: 'material:forum',
    };
  },
  computed: {
    label() {
      if (this.name === 'Test') {
        return this.$t('test');
      } if (this.name === 'Email') {
        return this.$t(this.mode, { name: this.$t('email') });
      }
      return this.$t(this.mode, { name: this.name });
    },
  },
  mounted() {
    if (this.name === 'Test') {
      this.ios = 'f7:hourglass';
      this.md = 'material:visibility';
    } else if (this.name === 'Email') {
      this.ios = 'f7:envelope_fill';
      this.md = 'material:email';
    } else {
      if (this.name === 'Google') {
        this.ios = 'f7:logo_google';
      } else if (this.name === 'Apple') {
        this.ios = 'f7:logo_apple';
      } else if (this.name === 'Facebook') {
        this.ios = 'f7:logo_facebook';
      }
      this.md = this.ios;
    }
    this.aurora = this.ios;
  },
};
</script>

<style lang="less">
.button.provider {
  display: flex;
  margin: calc(var(--f7-login-screen-blocks-margin-vertical) / 3) auto;

  span {
    flex-grow: 1;
  }
  .icon {
    font-size: 28px;
  }

  &.Google {
    border-color: #808080;
    color: #808080;

    .icon {
      color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='-50 -50 650 650' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M534 278c0-18-2-37-5-55H272v105h147c-6 34-26 64-54 83v68h87c52-48 82-118 82-201z' fill='%234285f4'/%3E%3Cpath d='M272 544c74 0 135-24 181-65l-88-68c-25 16-56 26-93 26-71 0-131-48-153-113H29v70c46 92 140 150 243 150z' fill='%2334a853'/%3E%3Cpath d='M119 324c-11-33-11-70 0-104v-70H29c-39 77-39 168 0 244l90-70z' fill='%23fbbc04'/%3E%3Cpath d='M272 108c39-1 76 14 105 41l77-78a272 272 0 00-425 79l90 70c22-64 82-112 153-112z' fill='%23ea4335'/%3E%3C/svg%3E");
    }
  }

  &.Apple {
    border-color: black;
    color: black;
  }

  &.Facebook {
    border-color: #1877F2;
    color: #1877F2;
  }

}
</style>

<i18n>
{
  "en": {
    "login": "Sign in with {name}",
    "signup": "Sign up with {name}",
    "reauth": "Reauth with {name}",
    "email": "your email",
    "test": "Create test account"
  },
  "fr": {
    "login": "Se connecter avec {name}",
    "signup": "S'inscrire avec {name}",
    "reauth": "Se reconnecter avec {name}",
    "email": "votre email",
    "test": "Créer un compte de test"
  }
}
</i18n>
