<template>
  <f7-page name="hashtags">
    <f7-navbar back-link :title="$t('title')" />
    <f7-list v-if="hashtagsStats.length">
      <f7-list-item
        v-for="hashtag in hashtagsStats"
        :key="hashtag.name"
        :link="'/p/hashtag-search/'+hashtag.name+'/'"
        :title="'#'+hashtag.name"
        :after="'x'+hashtag.hits"
        panel-close
      />
    </f7-list>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <f7-block v-else strong v-html="$t('no-hashtags')" />
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return { };
  },
  computed: {
    ...mapGetters([
      'hashtagsStats',
    ]),
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Hashtags",
    "no-hashtags": "<p>You haven't used hashtags yet.</p><p>Hashtags can be added in any note, whether it is a Contact or an Interaction, and are a powerful way to organize your Kipinto!</p><p><em>#hashtags are made of a hash followed by simple alphanumeric characters, dashes or underscores. Ex: #colleague #client...</em></p>"
  },
  "fr": {
    "title": "Hashtags",
    "no-hashtags": "<p>Vous n'avez pas utilisé d'hashtags pour le moment.</p><p>Les hashtags peuvent être ajoutés dans n'importe quelle note, qu'il s'agisse d'un Contact ou d'une Interaction. C'est une manière efficace d'organiser votre Kipinto !</p><p><em>Les #hashtags sont formés d'un dièse suivi d'un mélange de caractères alpha numériques simples (sans accents), de traits d'union et d'underscore. Ex: #collegue #client...</em></p>"
  }
}
</i18n>
