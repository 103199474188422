<template>
  <f7-list v-if="contactsNamed.length === 1">
    <f7-list-item :title="title" :after="after" :link="'/contact/'+contactsNamed[0].id+'/'">
      <event-icon slot="media" :event="event" color="gray" />
    </f7-list-item>
  </f7-list>
  <f7-list v-else accordion-list>
    <f7-list-item accordion-item :title="title" :after="after">
      <event-icon slot="media" :event="event" color="gray" />
      <f7-accordion-content>
        <f7-list>
          <f7-list-item
            v-for="contact in contactsNamed"
            :key="contact.id"
            :title="contact.name"
            :link="'/contact/'+contact.id+'/'"
          />
        </f7-list>
      </f7-accordion-content>
    </f7-list-item>
  </f7-list>
</template>

<script>
import DBEvent from '../class/dbEvent.class';
import EventIcon from './event-icon.vue';

export default {
  components: { EventIcon },
  props: {
    event: {
      type: DBEvent,
      default: () => {},
    },
    showVia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { };
  },
  computed: {
    contactsNamed() {
      const contactsNamed = [];
      // We enrich the contacts list of the interaction with the contact name
      for (let c = 0; c < this.event.contacts.length; c++) {
        const contactId = this.event.contacts[c];
        const contact = this.$store.getters.contactById(contactId);
        contactsNamed.push({
          id: contactId,
          name: contact ? contact.name : 'Error',
        });
      }
      return contactsNamed;
    },
    contactTitle() {
      if (this.event.contacts.length === 1) return this.contactsNamed[0].name;
      return this.$tc('eventContactsCount', this.event.contacts.length);
    },
    title() { return this.showVia ? this.$t(`via.${this.event.via}`) : this.contactTitle; },
    after() { return this.showVia ? this.contactTitle : null; },
  },
};
</script>

<i18n>
{
  "en": {
    "eventContactsCount": "0 contact | 1 contact | {n} contacts"
  },
  "fr": {
    "eventContactsCount": "0 contact | 1 contact | {n} contacts"
  }
}
</i18n>
