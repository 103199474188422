import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
// import 'firebase/storage';

const RELEASE = process.env.RELEASE;

// firebase init goes here
let config;
if (RELEASE === 'prod' || RELEASE === 'beta') { // PRODUCTION
  config = {
    apiKey: 'AIzaSyDgdOkFAOBTxzy-vvingJq3HJdM3MdoXDc',
    authDomain: 'app.kipinto.ch',
    databaseURL: 'https://kipinto-app.firebaseio.com',
    projectId: 'kipinto-app',
    storageBucket: 'kipinto-app.appspot.com',
    messagingSenderId: '800628279242',
    appId: '1:800628279242:web:bf6c0172f37e6ce7bef133',
    measurementId: 'G-JS53F9FZ4F',
  };
} else if (RELEASE === 'preprod' || RELEASE === 'alpha') { // PREPRODUCTION
  config = {
    apiKey: 'AIzaSyBK5bO2NCg1UxId-doTcpcae_MjEDbWsxM',
    authDomain: 'kipinto-preprod.firebaseapp.com',
    databaseURL: 'https://kipinto-preprod.firebaseio.com',
    projectId: 'kipinto-preprod',
    storageBucket: 'kipinto-preprod.appspot.com',
    messagingSenderId: '534879821341',
    appId: '1:534879821341:web:94d7eed4895ff24f2430f4',
    measurementId: 'G-N2L64H58WD',
  };
} else { // DEV OR EMULATOR
  config = {
    apiKey: 'AIzaSyBMaUrVD4rw6vvzY2FO_EsTp0uxQM5MFD4',
    authDomain: 'kipinto-dev-f7v4.firebaseapp.com',
    databaseURL: 'https://kipinto-dev-f7v4.firebaseio.com',
    projectId: 'kipinto-dev-f7v4',
    storageBucket: 'kipinto-dev-f7v4.appspot.com',
    messagingSenderId: '358827088394',
    appId: '1:358827088394:web:6d9fa10d69f899b0',
    measurementId: 'G-155RBHE6EG',
  };
}
firebase.initializeApp(config);

// firebase utils
export const firestore = firebase.firestore;
export const analytics = firebase.analytics;

if (RELEASE === 'emulator') {
  // If running Firestore Emulator
  firebase.firestore().settings({ experimentalForceLongPolling: true, host: 'tornadouli.ddns.net:3344', ssl: false });
} else {
  firebase.firestore().settings({
    experimentalForceLongPolling: true, // Attempt to correct the cordova issue
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  });
  // We enable offline support (with multi tab support)
  firebase.firestore().enablePersistence({
    synchronizeTabs: true,
  })
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        console.error('enable persistence failed', err);
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        console.error('enable persistence failed', err);
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
}
// We set the log level
// firebase.firestore.setLogLevel('debug');

export const auth = firebase.auth;
const { currentUser } = firebase.auth();

export const functions = firebase.app().functions('europe-west1');
if (RELEASE === 'emulator') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

// firebase collections
// const usersCollection = db.collection('users');

export default {
  firestore,
  functions,
  analytics,
  auth,
  currentUser,
  // usersCollection
};
