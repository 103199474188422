<template>
  <f7-page name="verify-email">
    <f7-navbar :title="$t('title')" />
    <div class="container">
      <f7-block strong inset>
        <h2>{{ $t('subtitle') }}</h2>
        <p>{{ $t('text-1') }}</p>
        <p><strong>{{ $t('text-2') }}</strong></p>
        <p>{{ $t('text-3') }}</p>
        <f7-button v-if="emailSent" fill large disabled>
          {{ $t('wait', {'seconds': seconds}) }}
        </f7-button>
        <f7-button v-else fill large @click="sendEmail">
          {{ $t('resend') }}
        </f7-button>
      </f7-block>
      <f7-block-footer class="text-align-center">
        {{ $t('auto-close') }}
      </f7-block-footer>
    </div>
    <f7-block class="no-margin-top">
      <f7-button small popup-close>
        {{ $t('no-access') }}
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      emailSent: false,
      seconds: 60,
    };
  },
  mounted() {
    if (this.$store.getters.userMustVerifyEmail) {
      const interval = window.setInterval(() => {
        this.$store.dispatch('reloadUser');
        if (!this.$store.getters.userMustVerifyEmail) {
          clearInterval(interval);
        }
      }, 1000);
    }
  },
  methods: {
    sendEmail() {
      if (this.emailSent) return;
      this.emailSent = true;
      this.$store.dispatch('sendEmailVerification')
        .then(() => {
          this.seconds = 60;
          const interval = window.setInterval(() => {
            this.seconds -= 1;
            if (this.seconds === 0) {
              clearInterval(interval);
              this.emailSent = false;
            }
          }, 1000);
        })
        .catch((error) => {
          this.$f7.dialog.alert(`Error when sending the email: ${error.message}`);
          this.emailSent = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container {
  max-width: 450px;
  margin: auto;
}
</style>

<i18n>
{
  "en": {
    "title": "Kipinto",
    "subtitle": "Email address verification",
    "text-1": "Sorry to bother you, but it's for your own good!",
    "text-2": "To allow you to reset your password if you forget it, we need to verify your email address.",
    "text-3": "Click on the link contained in the email we sent you when you created your account. Click on the button below to receive a new email.",
    "wait": "Wait {seconds}s before sending again",
    "resend": "Resend Verification Email",
    "auto-close": "This window will automatically close once you verified your email.",
    "no-access": "I will do it later"
  },
  "fr": {
    "title": "Kipinto",
    "subtitle": "Vérification de l'adresse email",
    "text-1": "Désolé de vous déranger, mais c'est pour votre bien !",
    "text-2": "Pour vous permettre de réinitialiser votre mot de passe en cas d'oubli, nous devons vérifier votre adresse email.",
    "text-3": "Cliquez sur le lien contenu dans l'email que nous vous avons envoyé lors de la création de votre compte. Cliquez sur le bouton ci-dessous pour recevoir un nouvel email.",
    "wait": "Attendez {seconds}s pour renvoyer un email",
    "resend": "Renvoyer un email de vérification",
    "auto-close": "Cette fenêtre se fermera automatiquement une fois que vous aurez vérifié votre adresse.",
    "no-access": "Je le ferai plus tard"
  }
}
</i18n>
