<template>
  <f7-actions
    ref="frequencySelector"
    class="frequency-selector"
    :convert-to-popover="true"
    v-bind="$attrs"
    @actions:closed="$emit('actions:closed', $event)"
  >
    <f7-actions-group>
      <f7-actions-label>{{ $t('reminders-frequency') }}</f7-actions-label>
      <f7-actions-button
        v-for="frequency in [1, 7, 15, 30, 90, 180, 365]"
        :key="frequency"
        @click="setFrequency(frequency)"
      >
        {{ $t('frequency.every'+frequency+'days') }}
      </f7-actions-button>
    </f7-actions-group>
    <f7-actions-group>
      <f7-actions-button @click="setFrequency(0)">
        {{ $t('frequency.every0days') }}
      </f7-actions-button>
    </f7-actions-group>
    <f7-actions-group>
      <f7-actions-button color="red">
        {{ $t('common.cancel') }}
      </f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>

<script>
import Contact from '../class/contact.class';

export default {
  props: {
    contactsSelectedId: {
      type: Array,
      default: () => [],
    },
    contactClicked: {
      type: Contact,
      default: null,
    },
  },
  data() {
    return { };
  },
  computed: { },
  methods: {
    setFrequency(newFrequency) {
      if (this.contactClicked) {
        if (this.contactClicked.frequency !== newFrequency) {
          this.$store.dispatch('updateContactFrequency', {
            contact: this.contactClicked,
            frequency: newFrequency,
          });
        }
      } else {
        const contactsToUpdate = [];
        for (const contactId of this.contactsSelectedId) {
          const contact = this.$store.getters.contactById(contactId);
          if (contact && contact.frequency !== newFrequency) {
            contactsToUpdate.push(contact);
          }
        }
        if (contactsToUpdate.length) {
          this.$store.dispatch('batchUpdateContactsFrequency', {
            contacts: contactsToUpdate,
            frequency: newFrequency,
          });
        }
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "reminders-frequency": "Reminders frequency"
   },
  "fr": {
    "reminders-frequency": "Fréquence des rappels"
  }
}
</i18n>
