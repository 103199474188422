<template>
  <f7-page name="account-permanentify" class="page-account-permanentify">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="authentication-wrapper">
      <provider-button
        v-for="provider of availableOAuthProviders"
        :key="provider.id"
        :name="provider.name"
        mode="signup"
        @click.native="linkWithOAuth(provider.id)"
      />
      <fieldset>
        <f7-list form @submit="linkWithEmailPassword">
          <f7-list-input
            type="email"
            autocomplete="email"
            :placeholder="$t('email-placeholder')"
            clear-button
            :value="email"
            @input="email = $event.target.value"
          />
          <f7-list-input
            type="password"
            autocomplete="new-password"
            :placeholder="$t('password-placeholder')"
            clear-button
            :value="password"
            @input="password = $event.target.value"
          />
          <f7-list-item-row class="no-padding-bottom">
            <f7-list-item-cell>
              <f7-button type="submit" large fill>
                {{ $t('signup-withEmail') }}
              </f7-button>
            </f7-list-item-cell>
          </f7-list-item-row>
        </f7-list>
      </fieldset>

      <f7-block class="text-align-center">
        <strong>{{ $t('no-data-loss') }}</strong>
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

import ProviderButton from '../components/provider-button.vue';

export default {
  components: { ProviderButton },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters([
      'availableOAuthProviders',
    ]),
  },
  methods: {
    linkWithEmailPassword() {
      this.$store.dispatch('linkWithEmailPassword', {
        email: this.email,
        password: this.password,
      })
        .then(() => {
          this.$f7.popup.close();
          this.$f7.dialog.alert(
            this.$t('signup-verifyEmail'),
            this.$t('signup-successful'),
          );
        })
        .catch(error => this.$f7.dialog.alert(`Oopsie in linking Email. ${error.message}`));
    },
    linkWithOAuth(provider) {
      this.$store.dispatch('linkWithOAuth', provider)
        .then(() => this.$f7.popup.close())
        .catch(error => this.$f7.dialog.alert(`Oopsie in OAuthLogin. ${error.message}`));
    },
  },
};
</script>

<style lang="less">
.page-account-permanentify {
  --f7-page-bg-color: #ffffff;
  display: flex;
  flex-direction: column;

  .page-content {
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    max-height: 100%;
    width: 100%;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Create the account",
    "signup-successful": "Signup Successful!",
    "signup-verifyEmail": "You received an email to verify your adress. Remember to click on the link it contains!",
    "signup": "Signup",
    "signup-withEmail": "Signup with your email",
    "with-email": "With an email & password",
    "with-provider": "With a service provider",
    "email-placeholder": "Your email",
    "password-placeholder": "Your password",
    "no-data-loss": "You will not lose your data."
  },
  "fr": {
    "title": "Créer un compte",
    "signup-successful": "Inscription réussie !",
    "signup-verifyEmail": "Vous avez reçu un email pour vérifier votre adresse. Pensez à cliquer sur le lien qu'il contient !",
    "signup": "S'inscrire",
    "signup-withEmail": "S'inscrire avec votre email",
    "with-email": "Avec un email & un mot de passe",
    "with-provider": "Avec un fournisseur de services",
    "email-placeholder": "Votre email",
    "password-placeholder": "Votre mot de passe",
    "no-data-loss": "Vous ne perdrez pas vos données."
  }
}
</i18n>
