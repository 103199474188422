<template>
  <f7-app :params="f7params">
    <!-- Left panel with cover effect-->
    <f7-panel left reveal theme-dark>
      <f7-view url="/parameters/" name="parameters" />
    </f7-panel>

    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <f7-toolbar tabbar labels bottom>
        <f7-link
          tab-link="#view-home"
          tab-link-active
          icon-ios="f7:tray_full"
          icon-aurora="f7:tray_full"
          icon-md="material:assignment"
          :text="$t('tab-home')"
        />
        <f7-link
          tab-link="#view-events"
          icon-ios="f7:calendar"
          icon-aurora="f7:calendar"
          icon-md="material:today"
          :text="$t('tab-events')"
        />
        <f7-link
          tab-link="#view-contacts"
          icon-ios="f7:person_2"
          icon-aurora="f7:person_2"
          icon-md="material:group"
          :text="$t('tab-contacts')"
        />
      </f7-toolbar>

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      <f7-view
        id="view-home"
        name="home"
        main
        tab
        tab-active
        url="/home/"
      />

      <!-- Events View -->
      <f7-view id="view-events" name="events" tab url="/events/" />

      <!-- Contacts View -->
      <f7-view id="view-contacts" name="contacts" tab url="/contacts/" />
    </f7-views>


    <f7-popup v-show="verifyEmailPopupVisible" tablet-fullscreen :opened="userMustVerifyEmail" @popup:open="verifyEmailPopupVisible = true" @popup:closed="verifyEmailPopupVisible = false">
      <f7-view v-if="verifyEmailPopupVisible" name="verify-email" url="/verify-email/" />
    </f7-popup>

    <f7-popup v-show="onboardingPopupVisible" :opened="showOnboardingPopup" tablet-fullscreen @popup:open="onboardingPopupVisible = true" @popup:closed="onboardingPopupVisible = false">
      <f7-view v-if="onboardingPopupVisible" name="onboarding" url="/onboarding/" />
    </f7-popup>

    <f7-popup v-show="updateDbPopupVisible" tablet-fullscreen :opened="showUpdateDbPopup" @popup:open="updateDbPopupVisible = true" @popup:closed="updateDbPopupVisible = false">
      <f7-view v-if="updateDbPopupVisible" name="update-db" url="/update-db/" />
    </f7-popup>

    <!-- Intro -->
    <!--
    <f7-popup tablet-fullscreen :opened="false" @popup:open="introPopupVisible = true" @popup:closed="introPopupVisible = false">
      <f7-view v-if="introPopupVisible" name="intro" url="/intro/" />
    </f7-popup>
    -->

    <f7-login-screen v-show="loginPopupVisible" :opened="showLoginPopup" @loginscreen:open="loginPopupVisible = true" @loginscreen:closed="loginPopupVisible = false">
      <f7-view v-if="loginPopupVisible" name="login" url="/login-signup/" />
    </f7-login-screen>
  </f7-app>
</template>

<script>
import { mapGetters } from 'vuex';

import firebase from '../js/firebase-config'; // REDUCE SIZE
import cordovaApp from '../js/cordova-app';
import routes from '../js/routes';

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: 'com.kipinto.app', // App bundle ID
        name: 'Kipinto', // App name
        theme: 'auto', // Automatic theme detection
        // App root data
        data() {
          return { };
        },

        // App routes
        routes,

        smartSelect: {
          pageBackLinkText: this.$t('common.back'),
          searchbarPlaceholder: this.$t('common.search'),
          searchbarDisableText: this.$t('common.cancel'),
          sheetCloseLinkText: this.$t('common.ok'),
        },

        toast: {
          closeTimeout: 3000,
          destroyOnClose: true,
          closeButton: true,
        },

        dialog: {
          buttonOk: this.$t('common.ok'),
          buttonCancel: this.$t('common.cancel'),
        },

        // Register service worker
        serviceWorker: this.$device.cordova
          ? {}
          : {
            path: '/service-worker.js',
          },
        // Input settings
        input: {
          scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          iosTextColor: 'white',
          androidTextColor: 'white',
        },
        // Popup
        popup: {
          closeByBackdropClick: false,
        },
      },
      // LoginPage
      showLoginPopup: true, // Begin with true so OnBoarding does not load with user not connected
      showIntroPopup: false,
      loginPopupVisible: false,
      introPopupVisible: false,
      onboardingPopupVisible: false,
      updateDbPopupVisible: false,
      verifyEmailPopupVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      'userMustVerifyEmail', 'mustUpdateDb', 'dbLoaded', 'userDoc', 'tasksUpcoming', 'notifications',
    ]),
    showOnboardingPopup() {
      return !this.showLoginPopup && !this.userDoc.onboarded;
    },
    showUpdateDbPopup() {
      return this.dbLoaded && this.mustUpdateDb;
    },
  },
  mounted() {
    this.$f7ready((f7) => {
      // Remove loading class on html
      document.documentElement.classList.remove('loading');

      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here

      // Handle the case of signin / signup / linkin where the app activity is destroyed:
      firebase.auth().getRedirectResult().then((result) => {
        if (result.credential) {
          console.log('getRedirectResult', result);
          this.$store.commit('setUser', result.user);
          // this.loginSuccessful('google', result);
        }
      }).catch((error) => {
        this.$f7.dialog.alert(`Oopsie in getRdirectResult. ${error.message}`);
      });

      // We enable analytics
      this.$store.dispatch('enableAnalytics', true);

      // We follow the current displayed path
      let currentlyDisplayedPath = null;
      this.$f7.on('tabShow panelOpen panelClose pageAfterIn popupOpened popupClosed loginScreenOpened loginScreenClosed', (page) => {
        let path = null;
        if (page.type === 'popup' && page.opened && page.route) path = page.route.path.slice(0, 3) === '/p/' ? page.route.path.slice(2) : page.route.path;
        else path = this.$f7.views.current.history[this.$f7.views.current.history.length - 1];
        if (path && currentlyDisplayedPath !== path) {
          currentlyDisplayedPath = path;
          this.$store.dispatch('trackPage', path);
        }
      });

      // We hide/show login form based on user authentication and if he has read the intro or not
      let appLoaded = false; // See below for explanation
      firebase.auth().onAuthStateChanged(() => {
        const user = firebase.auth().currentUser;
        console.log('onAuthStateChanged app.vue');
        /*
        // We hide / show the intro and the login page
        let introRead = false;
        try {
          introRead = JSON.parse(localStorage.getItem('introRead'));
        } catch (error) { console.error(error); }
        console.log('authentication change detected');
        this.showLoginPopup = !user && introRead;
        this.showIntroPopup = !user && !introRead;
        */
        this.showLoginPopup = !user;
        // If the user hasn't verified its email, we remind him to do so
        if (user && user.email && !user.emailVerified && !user.isAnonymous && !this.userMustVerifyEmail) {
          this.$f7.dialog.create({
            title: this.$t('verifyEmail-title'),
            text: this.$t('verifyEmail-text'),
            buttons: [
              {
                text: this.$t('verifyEmail-resend'),
                onClick: () => {
                  this.$store.dispatch('sendEmailVerification')
                    .then(() => this.$f7.toast.show({ text: this.$t('verifyEmail-sent') }));
                },
              },
              { text: this.$t('common.ok'), bold: true },
            ],
            verticalButtons: true,
          }).open();
        }
        // We reload all the views if the user has changed
        // When the app load the first time, the routes are already correct, so a reload is not necessary
        // TODO: clearPreviousHistory does not work
        if (appLoaded) {
          const settingsReload = { reloadAll: true };
          this.$f7.panel.close('left', false);
          this.$f7.views.parameters.router.navigate('/parameters/', settingsReload);
          this.$f7.views.home.router.navigate('/home/', settingsReload);
          this.$f7.views.events.router.navigate('/events/', settingsReload);
          this.$f7.views.contacts.router.navigate('/contacts/', settingsReload);
        }
        appLoaded = true;
      });

      // Clicking again a tab already selected move it back to first page
      const $ = this.$$;
      const app = this.$f7;
      let lastTab = $('#framework7-root > .views > .toolbar .tab-link.tab-link-active').attr('data-tab');
      // eslint-disable-next-line func-names
      $(document).on('click', '#framework7-root > .views > .toolbar .tab-link.tab-link-active', function () {
        const currentTab = $(this).attr('data-tab');
        if (currentTab === lastTab) {
          const $viewEl = $(currentTab);
          const view = app.views.get($viewEl);
          if (view.history.length > 1) {
            view.router.back(view.history[0], {
              force: true,
            });
          }
        }
        lastTab = currentTab;
      });

      // When a new service worker is installed, we notify the user so he can restart the app
      f7.on('serviceWorkerRegisterSuccess', (registration) => {
        console.log('serviceWorkerRegisterSuccess');
        const reg = registration;
        reg.onupdatefound = () => {
          console.log('serviceWorker onupdatefound');
          const installingWorker = reg.installing;
          installingWorker.onstatechange = () => {
            console.log('serviceWorker installingWorker onstatechange');
            if (installingWorker.state === 'installed') {
              console.log('serviceWorker installed');
              if (navigator.serviceWorker.controller) {
                console.log('serviceWorker navigator.serviceWorker.controller');
                // We inform the user a new update is available
                this.notifyUserOfUpdate(() => {
                  window.location.reload();
                });
              }
            }
          };
        };
      });

      // If Cordova is loaded
      document.addEventListener('deviceready', () => {
        // We check if there is an update from the App Center
        if (window.codePush) {
          window.codePush.sync((status) => {
            if (status === 1) { // SyncStatus: UPDATE INSTALLED
              this.notifyUserOfUpdate(() => {
                window.codePush.restartApplication();
              });
            }
          });
        }

        // When the app is paused, we updates all the upcoming notifications
        document.addEventListener('pause', () => {
          this.updateNotifications(this.tasksUpcoming);
        }, false);
      });
    });
  },
  methods: {
    notifyUserOfUpdate(restart) {
      this.$f7.notification.create({
        icon: '<img src="static/icons/favicon.png">',
        title: 'Kipinto',
        subtitle: this.$t('newUpdate-title'),
        text: this.$t('newUpdate-text'),
        closeButton: true,
        on: {
          click() {
            restart();
          },
        },
      }).open();
    },
    updateNotifications(tasksUpcoming) {
      if (window.cordova && window.cordova.plugins.notification && window.cordova.plugins.notification.local) {
        const now = new Date();
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        let todoLength = 0;
        const futureNotifications = new Map();
        let lastNotif = 0;
        // We count the length of the todo and we prepare the future notifications
        if (this.notifications.alerts || this.notifications.badge) {
          tasksUpcoming.forEach((task) => {
            if (task.date < tomorrow) todoLength += 1;
            else {
              const date = new Date(task.date.getFullYear(), task.date.getMonth(), task.date.getDate()).getTime();
              if (date > lastNotif) lastNotif = date;
              const notification = futureNotifications.get(date);
              if (notification) {
                notification[task.type] = notification[task.type] ? notification[task.type] + 1 : 1;
              } else {
                futureNotifications.set(date, { [task.type]: 1 });
              }
            }
          });
        }
        // We update the badge
        if (cordova.plugins.notification.badge) {
          if (todoLength && this.notifications.badge) {
            cordova.plugins.notification.badge.set(todoLength);
          } else {
            cordova.plugins.notification.badge.clear();
          }
        }
        // We create the notifications
        const notifications = [];
        // We create all the notifications for the upcoming events
        // let i = 1;
        for (const [date, notification] of futureNotifications) {
          const dayTodos = Object.entries(notification).reduce((total, [, quantity]) => total + quantity, 0);
          todoLength += dayTodos;
          if (this.notifications.badge) {
            notifications.push({
              id: date - 1,
              badge: todoLength,
              silent: true,
              sound: null,
              trigger: { at: new Date(date) },
              // trigger: { in: 4 * i, unit: 'second' },
            });
          }
          if (this.notifications.alerts) {
            notifications.push({
              id: date,
              badge: todoLength,
              title: this.$t('notification-tasksTodo-title'),
              text: this.$tc('notification-tasksTodo-text', dayTodos),
              trigger: { at: new Date(date).setHours(this.notifications.time.hour, this.notifications.time.minute) },
              // trigger: { in: 4 * i + 2, unit: 'second' },
            });
          }
          // i += 1;
        }
        // We create a recurring notification to remember the user to open kipinto
        for (const days of [15, 30, 60]) {
          notifications.push({
            id: days,
            title: this.$t(`notification-beenALongTime-${days}-title`),
            text: this.$t(`notification-beenALongTime-${days}-text`),
            trigger: { in: days, unit: 'day' },
          });
        }
        // We create a last notif that will arrive 30 days after all the other notifs (planned or "recurring")
        const daysBeforeLastNotif = Math.max(Math.round((lastNotif - Date.now()) / (24 * 3600 * 1000)), 90) + 30;
        notifications.push({
          id: 1337,
          title: this.$t('notification-beenALongTime-last-title'),
          text: this.$t('notification-beenALongTime-last-text'),
          trigger: { in: daysBeforeLastNotif, unit: 'day' },
        });
        // Cancel all the scheduled and clear all the triggered notifications
        cordova.plugins.notification.local.cancelAll(() => {
          // We schedule all the notifications
          cordova.plugins.notification.local.schedule(notifications);
        });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "tab-events": "Interactions",
    "tab-home": "Today",
    "tab-contacts": "Contacts",
    "newUpdate-title": "New update available!",
    "newUpdate-text": "It will be installed the next time the app is restarted, or you can click on this notification to reload the app and apply the update immediately.",
    "verifyEmail-title": "Confirm Email",
    "verifyEmail-text": "You received an email to verify your email address. Remember to click on the link it contains!",
    "verifyEmail-resend": "Resend the email",
    "verifyEmail-sent": "Verification email resent",
    "notification-tasksTodo-title": "Time to keep in touch!",
    "notification-tasksTodo-text": "You have a new task waiting for you in Kipinto | You have {n} new tasks waiting for you in Kipinto",
    "notification-beenALongTime-title": "Long time no see!",
    "notification-beenALongTime-text": "Add new contacts, register recent interactions, enrich your notes... Your network is waiting for you!",
    "notification-beenALongTime-15-title": "Take a few minutes to take care of your network",
    "notification-beenALongTime-15-text": "Add new contacts, record new interactions... Regularity is the key to strong relationships. So, who are you going to call today?",
    "notification-beenALongTime-30-title": "Don't wait until you need them to get back in touch with your contacts!",
    "notification-beenALongTime-30-text": "Grow your network regularly so that it bears fruit. So, who are you going to call today?",
    "notification-beenALongTime-60-title": "\"That's too bad, I should have kept in touch...\"",
    "notification-beenALongTime-60-text": "Forget about regrets and take control of your network! The relationships you have today may be the key to the best opportunities tomorrow. So, who are you going to call today?",
    "notification-beenALongTime-last-title": "Is it true? Are you pausing networking?",
    "notification-beenALongTime-last-text": "In that case, we won't send you any more notifications. But we'll be there when you change your mind!"
  },
  "fr": {
    "tab-events": "Interactions",
    "tab-home": "Aujourd'hui",
    "tab-contacts": "Contacts",
    "newUpdate-title": "Mise-à-jour disponible !",
    "newUpdate-text": "Elle sera installée lors du prochain redémarrage de l'application, ou vous pouvez cliquer sur cette notification pour redémarrer l'application et installer la mise-à-jour immédiatement.",
    "verifyEmail-title": "Email à confirmer",
    "verifyEmail-text": "Vous avez reçu un email pour vérifier votre adresse email. Pensez à cliquer sur le lien qu'il contient !",
    "verifyEmail-resend": "Renvoyer l'email",
    "verifyEmail-sent": "Email de vérification renvoyé",
    "notification-tasksTodo-title": "Votre réseau vous attend !",
    "notification-tasksTodo-text": "Une nouvelle tâche vous attend dans Kipinto | {n} nouvelles tâches vous attendent dans Kipinto",
    "notification-beenALongTime-15-title": "Prenez quelques minutes pour entretenir votre réseau !",
    "notification-beenALongTime-15-text": "Ajoutez de nouveaux contacts, enregistrez de nouvelles interactions… La régularité, voilà la clé pour des relations solides. Alors ? Qui allez vous appeler aujourd’hui ?",
    "notification-beenALongTime-30-title": "N’attendez pas d’avoir besoin d’elles pour recontacter vos relations !",
    "notification-beenALongTime-30-text": "Cultivez votre réseau régulièrement pour qu’il porte des fruits. Alors ? Qui allez vous appeler aujourd’hui ?",
    "notification-beenALongTime-60-title": "“C’est dommage, j’aurais dû garder contact…”",
    "notification-beenALongTime-60-text": "Oubliez les regrets et reprenez en main votre réseau ! Les relations que vous entretenez aujourd’hui seront peut-être la clé des plus belles opportunités demain. Alors ? Qui allez vous appeler aujourd’hui ?",
    "notification-beenALongTime-last-title": "C'est vrai ? Vous mettez votre réseau en pause ?",
    "notification-beenALongTime-last-text": "Dans ce cas, on ne vous enverra plus de notifications. Mais on sera là quand vous aurez changé d'avis !"
  }
}
</i18n>
