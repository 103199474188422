<template>
  <f7-popup push>
    <f7-view name="popup" :url="url" />
  </f7-popup>
</template>

<script>

export default {
  data() {
    return {
      url: this.$f7route.url.slice(2),
    };
  },
  mounted() { },
};
</script>
