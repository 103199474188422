<template>
  <f7-page name="login-signup" login-screen>
    <f7-navbar>
      <!--
      <f7-nav-left v-if="$f7route.query.showNavbar">
        <f7-nav-left :back-link="$t('common.back')" />
      </f7-nav-left>
      -->
      <f7-nav-left>
        <f7-link icon-ios="f7:globe" icon-aurora="f7:globe" icon-md="material:translate" popover-open=".popover-language" />
      </f7-nav-left>
      <f7-nav-title>Kipinto</f7-nav-title>
    </f7-navbar>
    <!-- <f7-login-screen-title>Join Kipinto</f7-login-screen-title> -->
    <div class="authentication-wrapper">
      <f7-segmented large strong>
        <f7-button :active="showLogin" @click="mode = 'login'">
          {{ $t('login') }}
        </f7-button>
        <f7-button :active="showSignup" @click="mode = 'signup'">
          {{ $t('signup') }}
        </f7-button>
      </f7-segmented>
      <f7-list v-show="showSignup" no-hairlines-between class="consent">
        <f7-list-item checkbox value="tos" :checked="consent.tos" @change="checkConsent">
          <span v-html="$t('consent-tos')" />
        </f7-list-item>
        <f7-list-item checkbox value="privacy" :checked="consent.privacy" @change="checkConsent">
          <span v-html="$t('consent-privacy')" />
        </f7-list-item>
      </f7-list>
      <provider-button
        v-for="provider of availableOAuthProviders"
        :key="provider.id"
        :name="provider.name"
        :mode="mode"
        @click.native="connect(provider.id)"
      />
      <fieldset>
        <f7-list form @submit="submitForm">
          <f7-list-input
            type="email"
            autocomplete="email"
            :placeholder="$t('email-placeholder')"
            clear-button
            :value="email"
            @input="email = $event.target.value"
          />
          <f7-list-input
            type="password"
            :autocomplete="showLogin ? 'current-password' : 'new-password'"
            :placeholder="$t('password-placeholder')"
            clear-button
            :value="password"
            @input="password = $event.target.value"
          />
          <f7-list-item-row v-show="showSignup" class="no-padding-bottom">
            <f7-list-item-cell>
              <f7-button type="submit" large fill>
                {{ $t('signup-withEmail') }}
              </f7-button>
            </f7-list-item-cell>
          </f7-list-item-row>
          <f7-list-item-row v-show="showLogin">
            <f7-list-item-cell>
              <f7-button type="submit" large fill>
                {{ $t('login-withEmail') }}
              </f7-button>
            </f7-list-item-cell>
          </f7-list-item-row>
          <f7-list-item-row v-show="showLogin" class="no-padding-bottom">
            <f7-list-item-cell>
              <f7-button small @click="resetPassword">
                {{ $t('resetPassword') }}
              </f7-button>
            </f7-list-item-cell>
          </f7-list-item-row>
        </f7-list>
      </fieldset>
      <provider-button v-show="showSignup" name="Test" :mode="mode" @click.native="anonymousLogin" />
    </div>
    <!--
    <fieldset>
      <legend>{{ $t('with-provider') }}</legend>
      <f7-row>
        <f7-col v-for="provider in oauthProviders" :key="provider.id">
          <f7-button
            large
            :disabled="!provider.available"
            :tooltip="provider.name"
            :icon-f7="provider.icon"
            class="provider"
            @click="connect(provider.id)"
          />
        </f7-col>
      </f7-row>
    </fieldset>
    <fieldset v-show="showSignup">
      <legend>{{ $t('with-anonymous') }}</legend>
      <f7-button large block @click="anonymousLogin">
        {{ $t('testWithoutAccount') }}
      </f7-button>
    </fieldset>
    -->
    <f7-popover class="popover-language">
      <f7-list>
        <f7-list-item popover-close title="English" @click="changeLanguage('en')" />
        <f7-list-item popover-close title="Français" @click="changeLanguage('fr')" />
      </f7-list>
    </f7-popover>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

import ProviderButton from '../components/provider-button.vue';

export default {
  components: { ProviderButton },
  data() {
    return {
      mode: this.$f7route.query.mode || 'signup',
      email: '',
      password: '',
      consent: {
        tos: false,
        privacy: false,
      },
      showEmail: false,
    };
  },
  computed: {
    ...mapGetters([
      'availableOAuthProviders',
    ]),
    showSignup() { return this.mode === 'signup'; },
    showLogin() { return this.mode === 'login'; },
  },
  methods: {
    changeLanguage(locale) {
      this.$root.$i18n.locale = locale;
      try {
        localStorage.setItem('locale', locale);
      } catch (error) { console.error(error); }
      this.$f7.popover.close('.popover-language');
    },
    checkConsent(event) {
      const value = event.target.value;
      this.consent[value] = event.target.checked;
    },
    consentCheckedBeforeSignup() {
      if (!this.consent.tos) {
        this.$f7.dialog.alert(this.$t('consent-tos-required'));
        return false;
      }
      if (!this.consent.privacy) {
        this.$f7.dialog.alert(this.$t('consent-privacy-required'));
        return false;
      }
      return true;
    },
    submitForm(e) {
      if (this.mode === 'signup') this.connect('signup');
      if (this.mode === 'login') this.connect('login');
      e.preventDefault();
    },
    async connect(provider) {
      try {
        if (this.mode === 'signup' && !this.consentCheckedBeforeSignup()) return;
        switch (provider) {
          case 'signup':
            await this.$store.dispatch('signUserUp', {
              email: this.email,
              password: this.password,
            });
            break;
          case 'login':
            await this.$store.dispatch('signUserIn', {
              email: this.email,
              password: this.password,
            });
            break;
          case 'anonymous':
            await this.$store.dispatch('signUserAnonymously');
            break;
          default:
            await this.$store.dispatch('signUserInOAuth', provider);
            break;
        }
      } catch (e) {
        this.$f7.dialog.alert(this.$t('connection-error', { err: e.message }));
      }
      this.password = null; // We reset the password
    },
    anonymousLogin() {
      this.$f7.dialog.confirm(
        this.$t('testAccount-explanation'),
        this.$t('testAccount-create'),
        () => {
          this.connect('anonymous');
        },
      );
    },
    resetPassword() {
      if (!this.email) {
        this.$f7.dialog.prompt(this.$t('resetPassword-askEmail'), (email) => {
          this.email = email;
          this.resetPassword();
        });
      } else {
        this.$store.dispatch('resetPassword', this.email)
          .then(() => this.$f7.dialog.alert(this.$t('passwordReset-success')))
          .catch(err => this.$f7.dialog.alert(`Oopsie in ResetPassword. ${err.message}`));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  margin: 0;
}

.consent {
  margin: var(--f7-login-screen-blocks-margin-vertical) auto;

  /deep/ .item-content {
    min-height: 0;

    .item-inner {
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      padding-right: 0;
      min-height: 0;
    }
  }

  /deep/ label.item-checkbox > .icon-checkbox {
    margin-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));
  }
}

/*
p.tos {
  font-size: 0.8em;
}
*/

/*
.separator {
  overflow: hidden;
  text-align: center;
  width: 250px;
  margin: auto;
  font-size: 0.9em;
  color: var(--f7-input-placeholder-color);

  &:before,
  &:after {
    background-color: var(--f7-list-item-border-color);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
*/
</style>

<i18n>
{
  "en": {
    "passwordReset-success": "You received an email with a link to renew your password.",
    "connection-error": "An error occured: {err}",
    "login": "Sign in",
    "signup": "Sign up",
    "login-withEmail": "Login with your email",
    "signup-withEmail": "Sign up with your email",
    "consent-tos": "I agree to the <a href='/p/legals/?tab=tos'>Terms of Services</a>",
    "consent-privacy": "I understand the <a href='/p/legals/?tab=privacy'>Privacy Policy</a>",
    "consent-tos-required": "You must confirm that you accept the TOS",
    "consent-privacy-required": "You must confirm that you understand the privacy policy",
    "with-email": "With an email & password",
    "with-provider": "With an identity provider",
    "with-anonymous": "With a temporary account",
    "email-placeholder": "Your email",
    "password-placeholder": "Your password",
    "resetPassword": "Password forgotten ?",
    "resetPassword-askEmail": "What is your email ?",
    "hideEmail": "Use identity provider",
    "testWithoutAccount": "Test without creating an account",
    "testAccount-create": "Create test account",
    "testAccount-explanation": "<p><strong>We cannot guarante the durability of test account data.</strong></p><p>To retrieve your data from any device, you can convert your test account into a permanent account at any time.</p>"
  },
  "fr": {
    "passwordReset-success": "Vous avez reçu un email avec un lien pour renouveler votre mot de passe.",
    "connection-error": "Une erreur est survenue : {err}",
    "login": "Se connecter",
    "signup": "S'inscrire",
    "login-withEmail": "Se connecter avec votre email",
    "signup-withEmail": "S'inscrire avec votre email",
    "consent-tos": "J'accepte les <a href='/p/legals/?tab=tos'>CGUs</a>",
    "consent-privacy": "J'ai lu la <a href='/p/legals/?tab=privacy'>Politique de Confidentialité</a>",
    "consent-tos-required": "Vous devez confirmer que vous acceptez les CGU",
    "consent-privacy-required": "Vous devez confirmer que vous avez lu la politique de confidentialité",
    "with-email": "Avec un email & un mot de passe",
    "with-provider": "Avec un fournisseur d'identité",
    "with-anonymous": "Avec un compte temporaire",
    "email-placeholder": "Votre email",
    "password-placeholder": "Votre mot de passe",
    "resetPassword": "Mot de passe oublié ?",
    "resetPassword-askEmail": "Quel est votre email ?",
    "hideEmail": "Utiliser un fournisseur d'identité",
    "testWithoutAccount": "Tester sans créer de compte",
    "testAccount-create": "Créer un compte de test",
    "testAccount-explanation": "<p><strong>Nous ne pouvons garantir la pérennité des données des comptes de test.</strong></p><p>Pour retrouver vos données depuis n'importe quel appareil, vous pourrez à tout moment convertir votre compte de test en compte permanent.</p>"
  }
}
</i18n>
