<template>
  <f7-page name="account-management">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title>{{ $t('displayName') }}</f7-block-title>
    <f7-list no-hairlines-md class="formUpdateInList">
      <f7-list-item>
        <f7-input
          slot="title"
          type="text"
          :placeholder="$t('displayName-new')"
          clear-button
          :value="displayName"
          @input="displayName = $event.target.value"
        />
        <f7-button slot="after" :disabled="displayNameUpdating || !user || displayName === user.displayName" @click="updateDisplayName">
          {{ $t('update') }}
        </f7-button>
      </f7-list-item>
    </f7-list>
    <f7-block-title>{{ $t('email-and-password') }}</f7-block-title>
    <f7-list v-if="passwordProvider" no-hairlines-md class="formUpdateInList">
      <f7-list-item>
        <f7-input
          slot="title"
          type="email"
          autocomplete="email"
          :placeholder="$t('email-new')"
          clear-button
          :value="email"
          @input="email = $event.target.value"
        />
        <f7-button slot="after" :disabled="emailUpdating || email === passwordProvider.email" @click="updateEmail">
          {{ $t('update') }}
        </f7-button>
      </f7-list-item>
      <f7-list-item>
        <f7-input
          slot="title"
          type="password"
          autocomplete="new-password"
          :placeholder="$t('password-new')"
          clear-button
          @input="password = $event.target.value"
        />
        <f7-button slot="after" :disabled="passwordUpdating || !password" @click="updatePassword">
          {{ $t('update') }}
        </f7-button>
      </f7-list-item>
    </f7-list>
    <f7-list v-else no-hairlines-md>
      <f7-list-input
        type="email"
        autocomplete="email"
        :placeholder="$t('email-new')"
        clear-button
        :value="email"
        @input="email = $event.target.value"
      />
      <f7-list-input
        type="password"
        autocomplete="current-password"
        :placeholder="$t('password-new')"
        clear-button
        :value="password"
        @input="password = $event.target.value"
      />
      <f7-list-button :title="$t('email-and-password-new')" :disabled="passwordProviderLinking" @click="linkWithEmailPassword" />
    </f7-list>
    <f7-block-title>{{ $t('providers') }}</f7-block-title>
    <f7-list>
      <f7-list-item
        v-for="provider in providers"
        :key="provider.id"
        link
        :title="provider.name"
        :disabled="!provider.available"
        :after="provider.after"
        :footer="provider.email"
        @click="providerClick(provider)"
      >
        <f7-icon slot="media" :f7="provider.icon" />
      </f7-list-item>
      <f7-block-footer>
        {{ $t('providers-explanation') }}
      </f7-block-footer>
    </f7-list>
    <!--
        <f7-list media-list>
          <f7-list-item
            v-for="profile in providerData"
            :key="profile.uid"
            swipeout
            :title="profile.displayName"
            :subtitle="profile.email"
            :after="profile.providerId"
            @swipeout:delete="unlink(profile.providerId)"
          >
            <img v-if="profile.photoURL" slot="media" :src="profile.photoURL" width="44">
            <f7-swipeout-actions right>
              <f7-swipeout-button delete :confirm-text="$t('unlink-confirmation')">
                <f7-icon ios="f7:trash_fill" aurora="f7:trash_fill" md="material:delete" />
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
        -->
    <f7-block>
      <f7-button
        block
        color="red"
        @click="deleteUser"
      >
        {{ $t('account-delete') }}
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      displayName: '',
      displayNameUpdating: false,
      email: '',
      emailUpdating: false,
      password: '',
      passwordUpdating: false,
      passwordProviderLinking: false,
    };
  },
  computed: {
    ...mapGetters([
      'user', 'providersLinked', 'oauthProviders',
    ]),
    passwordProvider() {
      return this.providersLinked.find(elem => elem.providerId === 'password');
    },
    providers() {
      const providers = JSON.parse(JSON.stringify(this.oauthProviders));
      providers.forEach((provider, index) => {
        if (provider.available) {
          if (provider.isLinked) {
            providers[index].after = this.$t('provider-disconnect');
            providers[index].email = provider.email || this.$t('provider-noEmail');
          } else {
            providers[index].after = this.$t('provider-connect');
          }
        } else {
          providers[index].after = this.$t('provider-availableSoon');
        }
      });
      return providers;
    },
  },
  mounted() {
    if (this.passwordProvider) {
      this.email = this.passwordProvider.email || '';
    } else {
      this.email = this.user.email;
    }
    this.displayName = this.user?.displayName ?? '';
  },
  methods: {
    updateDisplayName() {
      if (this.displayNameUpdating) return;
      this.displayNameUpdating = true;
      this.$store.dispatch('updateDisplayName', this.displayName)
        .then(() => {
          this.$f7.toast.show({ text: this.$t('displayName-updated') });
          this.displayNameUpdating = false;
        })
        .catch((error) => {
          this.displayNameUpdating = false;
          this.$f7.dialog.alert(`Oopsie in updating Display Name. ${error.message}`);
        });
    },
    updateEmail() {
      if (this.emailUpdating || !this.email) return;
      this.emailUpdating = true;
      this.$store.dispatch('updateEmail', this.email)
        .then((user) => {
          this.$f7.toast.show({ text: this.$t('email-updated') });
          this.emailUpdating = false;
          if (!user.emailVerified) {
            this.$f7.dialog.alert(
              this.$t('signup-verifyEmail'),
              this.$t('signup-successful'),
            );
          }
        })
        .catch((error) => {
          this.emailUpdating = false;
          if (error.code === 'auth/requires-recent-login') {
            this.$f7.views.main.router.navigate('/p/account-reauth/');
          } else {
            this.$f7.dialog.alert(`Oopsie in updating Email. ${error.message}`);
          }
        });
    },
    updatePassword() {
      if (this.passwordUpdating || !this.password) return;
      this.passwordUpdating = true;
      this.$store.dispatch('updatePassword', this.password)
        .then(() => {
          this.$f7.toast.show({ text: this.$t('password-updated') });
          this.passwordUpdating = false;
          this.password = '';
        })
        .catch((error) => {
          this.passwordUpdating = false;
          if (error.code === 'auth/requires-recent-login') {
            this.$f7.views.main.router.navigate('/p/account-reauth/');
          } else {
            this.$f7.dialog.alert(`Oopsie in updating Password. ${error.message}`);
          }
        });
    },
    providerClick(provider) {
      if (provider.available) {
        if (provider.isLinked) {
          this.$f7.dialog.confirm(
            this.$t('unlink-confirmation'),
            () => {
              this.unlink(provider.id);
            },
          );
        } else {
          this.linkWithOAuth(provider.id);
        }
      }
    },
    linkWithEmailPassword() {
      if (this.passwordProviderLinking) return;
      this.passwordProviderLinking = true;
      this.$store.dispatch('linkWithEmailPassword', {
        email: this.email,
        password: this.password,
      })
        .then((user) => {
          this.passwordProviderLinking = false;
          if (!user.emailVerified) {
            this.$store.dispatch('sendEmailVerification');
            this.$f7.dialog.alert(
              this.$t('signup-verifyEmail'),
              this.$t('signup-successful'),
            );
          }
        })
        .catch((error) => {
          this.passwordProviderLinking = false;
          this.$f7.dialog.alert(`Oopsie in linking Email. ${error.message}`);
        });
    },
    linkWithOAuth(provider) {
      this.$store.dispatch('linkWithOAuth', provider)
        .then(() => this.$f7.popup.close())
        .catch(error => this.$f7.dialog.alert(`Oopsie in OAuthLogin. ${error.message}`));
    },
    unlink(providerId) {
      if (this.providersLinked.length <= 1) {
        this.$f7.dialog.alert('You cannot unlink all the Auth providers if you do not have created an email / password authent before');
      } else {
        this.$store.dispatch('unlinkProvider', providerId)
          .catch(error => this.$f7.dialog.alert(`Oopsie when unlinking. ${error.message}`));
      }
    },
    deleteUser() {
      this.$f7.dialog.confirm(
        this.$t('account-delete-warning'),
        this.$t('account-delete'),
        () => {
          this.$store.dispatch('deleteUser')
            .then(() => this.$f7.popup.close())
            .catch((error) => {
              if (error.code === 'auth/requires-recent-login') {
                this.$f7.views.main.router.navigate('/p/account-reauth/');
              } else {
                this.$f7.dialog.alert(`Oopsie in updating Password. ${error.message}`);
              }
            });
        },
      );
    },
  },
};
</script>

<style lang="less" scoped>
.formUpdateInList /deep/ .item-title {
  flex-grow: 1;
}
</style>

<i18n>
{
  "en": {
    "title": "Manage your account",
    "update": "Update",
    "displayName": "Display Name",
    "displayName-new": "New display name",
    "displayName-updated": "Display Name successfully updated!",
    "email-and-password": "Email & Password authentication",
    "email-new": "New email",
    "email-updated": "Email successfully updated!",
    "password-new": "New password",
    "password-updated": "Password successfully updated!",
    "email-and-password-new": "Link email & password to account",
    "providers": "Authentication providers",
    "provider-connect": "Link",
    "provider-disconnect": "Unlink",
    "provider-noEmail": "Unknown email",
    "provider-availableSoon": "Available Soon!",
    "providers-explanation": "You can link your account to several authentication providers to easily connect to Kipinto.",
    "signup-successful": "Signup Successful!",
    "signup-verifyEmail": "You received an email to verify your adress. Remember to click on the link it contains!",
    "unlink-confirmation": "Are you sure you want to remove this identity provider?",
    "account-delete": "Delete the account",
    "account-delete-warning": "<p>If you delete this account, all the data will be lost forever.</p><p><strong>Have you thought to do a last backup before?</strong></p><p>No reimbursment will be possible once deleted.</p>"
    },
  "fr": {
    "title": "Gérer votre compte",
    "update": "Modifier",
    "displayName": "Nom d'affichage",
    "displayName-new": "Nouveau nom d'affichage",
    "displayName-updated": "Nom d'affichage modifié avec succès !",
    "email-and-password": "Identification par email & mot de passe",
    "email-new": "Nouvel email",
    "email-updated": "Email modifié avec succès !",
    "password-new": "Nouveau mot de passe",
    "password-updated": "Mot de passe modifié avec succès !",
    "email-and-password-new": "Lier un email & mdp à votre compte",
    "providers": "Fournisseurs d'identité",
    "provider-connect": "Associer",
    "provider-disconnect": "Dissocier",
    "provider-noEmail": "Email inconnu",
    "provider-availableSoon": "Bientôt disponible !",
    "providers-explanation": "Vous pouvez relier votre compte à plusieurs fournisseurs d'authentification pour vous connecter facilement à Kipinto.",
    "signup-successful": "Inscription réussie !",
    "signup-verifyEmail": "Vous avez reçu un email pour vérifier votre adresse. Pensez à cliquer sur le lien qu'il contient !",
    "unlink-confirmation": "Êtes-vous sur de vouloir supprimer ce moyen de connexion ?",
    "account-delete": "Supprimer le compte",
    "account-delete-warning": "<p>Si vous supprimez ce compte, toutes les données seront définitivement supprimées.</p><p><strong>Avez-vous pensé à faire un dernier backup avant ?</strong></p><p>Aucun remboursement ne sera possible une fois supprimé.</p>"

    }
}
</i18n>
