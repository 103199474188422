<template>
  <div :class="{ 'search': search }" class="container" v-bind="$attrs">
    <f7-block class="overlay no-margin">
      <p v-if="title" class="title">
        {{ title }}
      </p>
      <div v-if="text" class="text" v-html="text" />
      <slot />
    </f7-block>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { };
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: center no-repeat;
  background-image: url('../static/images/bg-empty.svg');
  background-size: auto 100%;
  background-color: var(--f7-page-bg-color);
  color: gray;

  .device-with-keyboard .keyboard-focused & {
    height: calc(100% + var(--keyboard-height, 0px) - (var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px)));
  }

  .overlay {
    min-height: 80%;
    overflow: auto;
    /*
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(10px) saturate(150%);
    */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.search {
    background-image: url('../static/images/bg-search.svg');

    .overlay {
      min-height: 50%;
    }
  }

  .title {
    font-size: 1.5em;
  }
}
</style>
