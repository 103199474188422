<template>
  <f7-page name="home" @page:tabshow="tabShow">
    <!-- Top Navbar -->
    <f7-navbar large>
      <f7-nav-left>
        <burger-button />
      </f7-nav-left>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:calendar_badge_plus" icon-aurora="f7:calendar_badge_plus" icon-md="material:event_notes" href="/p/event-new/" />
        <f7-link icon-ios="f7:person_badge_plus" icon-aurora="f7:person_badge_plus" icon-md="material:person_add" href="/p/contact-new/" />
      </f7-nav-right>
      <f7-nav-title-large>{{ $t('title') }}</f7-nav-title-large>
      <f7-subnavbar :inner="false">
        <f7-searchbar
          :disable-button-text="$t('common.cancel')"
          :placeholder="$t('search')"
          :clear-button="true"
          :custom-search="true"
          @searchbar:search="search"
        />
      </f7-subnavbar>
    </f7-navbar>

    <search-results v-if="searchQuery && (searchResults.length || searchLoading)" :search-results="searchResults" :search-loading="searchLoading" />
    <empty-content v-if="searchQuery && !searchResults.length && !searchLoading" :title="$t('searchNoResult-title')" :text="$t('searchNoResult-text')" search />

    <div v-if="contacts.length || !dbLoaded" v-show="!searchQuery" class="tasks-container">
      <illustration-todo class="background-illustration" :tasks-done="dbLoaded && !todo.length" />

      <transition name="fade" mode="out-in">
        <f7-swiper v-if="showTasksTodo" id="swiper-tasks-todo" key="tasks-todo" pagination :params="swiperParams">
          <f7-swiper-slide v-for="event in todo" :key="event.hash">
            <event-card-item :event="event" class="event-card elevation-3" />
          </f7-swiper-slide>
        </f7-swiper>

        <div v-if="showTasksDone" key="tasks-done" class="overlay">
          <f7-block>
            <h3>{{ $t('jobDone') }}</h3>
            <p>
              <f7-button fill large round @click="getAhead">
                {{ $t('jobDone-getAhead') }}
              </f7-button>
            </p>
            <p>
              <f7-button icon-ios="f7:shuffle" icon-aurora="f7:suffle" icon-md="material:shuffle" :tooltip="$t('jobDone-randomContact')" @click="gotoRandomContact" />
            </p>
          </f7-block>
        </div>
      </transition>
    </div>
    <div v-else v-show="!searchQuery" class="kickstart-container">
      <div class="overlay">
        <f7-block>
          <h3>{{ $t('kickstart-text') }}</h3>
          <p>
            <f7-button fill large round href="/p/contact-new/">
              {{ $t('kickstart-firstContact') }}
            </f7-button>
          </p>
        </f7-block>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Fuse from 'fuse.js';
import { mapGetters } from 'vuex';
import SearchResults from '../components/search-results.vue';
import EventCardItem from '../components/event-card-item.vue';
import IllustrationTodo from '../components/illustration-todo.vue';
import BurgerButton from '../components/burger-button.vue';
import EmptyContent from '../components/empty-content.vue';

let debounceSearch = null;

export default {
  components: {
    SearchResults, EventCardItem, IllustrationTodo, BurgerButton, EmptyContent,
  },
  data() {
    return {
      searchQuery: null,
      searchResults: [],
      searchLoading: false,
      headStart: 0,
      swiperParams: {
        pagination: { dynamicBullets: true },
        slidesPerView: 1,
        breakpoints: {
          700: { // window width >= 700
            slidesPerView: 2,
          },
          1000: { // window width >= 1000
            slidesPerView: 3,
          },
          1400: { // window width >= 1400
            slidesPerView: 4,
          },
        },
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'contacts',
      'interactions',
      'tasksUpcoming',
      'dbLoaded',
    ]),
    objects() { return [...this.interactions, ...this.contacts]; },
    todo() {
      const todo = [];
      // We select the events to show
      const now = new Date();
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1 + this.headStart);
      this.tasksUpcoming.forEach((event) => {
        // If the event is before tomorrow (+ eventually a "headStart")
        if (event.date < tomorrow) {
          // We add the interaction to the Array
          todo.push(event);
        }
      });
      return todo;
    },
    showTasksTodo() {
      return this.dbLoaded && this.todo.length;
    },
    showTasksDone() {
      return this.dbLoaded && !this.todo.length;
    },
  },
  methods: {
    tabShow() {
      const swiperDom = document.getElementById('swiper-tasks-todo');
      if (swiperDom && swiperDom.swiper) {
        swiperDom.swiper.update();
      }
    },
    search(searchbar, query) {
      clearTimeout(debounceSearch);
      if (query) {
        this.searchQuery = query;
        this.searchLoading = true;
        debounceSearch = setTimeout(() => {
          const options = {
            includeScore: true,
            minMatchCharLength: 3,
            shouldSort: true,
            threshold: 0.4,
            useExtendedSearch: true,
            keys: [
              'notesRaw',
              'name',
              'company',
            ],
            distance: 1000000, // We do not restrict the distance
          };
          const fuse = new Fuse(this.objects, options);
          this.searchResults = fuse.search(query).slice(0, 100);
          this.searchLoading = false;
        }, 750);
      } else {
        this.searchQuery = null;
        this.searchResults = [];
        this.searchLoading = false;
      }
    },
    getAhead() {
      this.headStart += 7;
      if (!this.todo.length) {
        this.$f7.dialog.confirm(this.$t('getAhead-noJob-confirm'), this.$t('getAhead-noJob-title', { x: this.headStart }), () => {
          this.getAhead();
        });
      }
    },
    gotoRandomContact() {
      const randomContact = this.contacts[Math.floor(Math.random() * this.contacts.length)];
      this.$f7router.navigate(`/contact/${randomContact.id}/`);
    },
  },
};
</script>

<style lang="less" scoped>

.swiper-container {
  width: 100%;
  height: 100%;
  min-height: 250px;
}
.swiper-slide {
  position: relative;
  font-size: var(--f7-input-font-size);
  box-sizing: border-box;
  padding: 20px;
}
.event-card {
  height: calc(100% - 2 * var(--f7-card-margin-vertical));
}
.tasks-container, .kickstart-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;

  .overlay {
    overflow: auto;
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(10px) saturate(150%);
    position: relative;
  }

  h3 {
    margin: 2em 0;
  }

  .button {
    margin: auto;
    width: fit-content;
    padding: 0 1em;
  }
}

.tasks-container {

  .background-illustration {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .swiper-container {
    background: rgba(239,239,244,0.8);
    backdrop-filter: blur(10px); // saturate(150%);
  }

}

.kickstart-container {
  background: url('../static/images/bg-kickstart.svg') center no-repeat;
  background-size: auto 100%;
}
</style>

<i18n>
{
  "en": {
    "search": "Global search",
    "searchNoResult-title": "No result",
    "searchNoResult-text": "<p>We did not find any result<br>matching your search.</p><p><em>And yet we searched everywhere:<br>contacts, interactions, memos...</em></p>",
    "title": "Today",
    "jobDone": "We are done for today!",
    "jobDone-getAhead": "Get a head start?",
    "jobDone-randomContact": "Get a random Contact?",
    "getAhead-noJob-title": "Nothing in {x} days",
    "getAhead-noJob-confirm": "Check actions for 7 days after?",
    "kickstart-text": "Find here every day the reminders you have scheduled",
    "kickstart-firstContact": "Add your 1st contact"
  },
  "fr": {
    "search": "Recherche globale",
    "searchNoResult-title": "Aucun résultat",
    "searchNoResult-text": "<p>Nous n'avons trouvé aucun résultat<br>correspondant à votre recherche.</p><p><em>Et pourtant nous avons cherché partout :<br>contacts, interactions, mémos...</em></p>",
    "title": "Aujourd'hui",
    "jobDone": "C'est bon pour aujourd'hui !",
    "jobDone-getAhead": "Prendre de l'avance ?",
    "jobDone-randomContact": "Un contact au hasard ?",
    "getAhead-noJob-title": "Rien d'ici {x} jours",
    "getAhead-noJob-confirm": "Prendre de l'avance sur 7 jours supplémentaires ?",
    "kickstart-text": "Retrouvez ici chaque jour les rappels que vous aurez programmés",
    "kickstart-firstContact": "Créer votre 1er contact"
  }
}
</i18n>
