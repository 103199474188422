import firebase from './firebase-config';

function getOAuthProvider(name) {
  let provider = null;
  switch (name) {
    case 'google.com':
      provider = new firebase.auth.GoogleAuthProvider();
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      break;
    case 'apple.com':
      provider = new firebase.auth.OAuthProvider('apple.com');
      // provider.addScope('email');
      // provider.addScope('name');
      break;
    case 'facebook.com':
      provider = new firebase.auth.FacebookAuthProvider();
      // provider.addScope('email');
      break;
    default:
      provider = null;
  }
  return provider;
}

export default {
  state: {
    user: null,
    userMustVerifyEmail: false,
    oauthProviders: [
      {
        id: 'apple.com', name: 'Apple', icon: 'logo_apple', available: true,
      },
      {
        id: 'facebook.com', name: 'Facebook', icon: 'logo_facebook', available: true,
      },
      {
        id: 'google.com', name: 'Google', icon: 'logo_google', available: true,
      },
    ],
  },
  mutations: {
    setUser(state, user) {
      if (user) {
        state.user = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          isAnonymous: user.isAnonymous,
          emailVerified: user.emailVerified,
          providerData: user.providerData,
        };
        // We check if the user has verified its email (48h "grace" period)
        const creationDate = new Date(user.metadata.creationTime);
        const dayMinus48h = new Date(); dayMinus48h.setDate(dayMinus48h.getDate() - 2);
        state.userMustVerifyEmail = user && user.email && !user.isAnonymous && !user.emailVerified && creationDate < dayMinus48h;
      } else {
        state.user = null;
        state.userMustVerifyEmail = false;
      }
    },
  },
  actions: {
    signUserUp({ commit, dispatch }, { email, password }) {
      return new Promise((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then((result) => {
            if (!result.user.emailVerified) {
              dispatch('sendEmailVerification');
            }
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    signUserIn({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    signUserAnonymously({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInAnonymously()
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    signUserInOAuth({ commit }, provider) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInWithRedirect(getOAuthProvider(provider))
          .then(() => firebase.auth().getRedirectResult()).then(
            (result) => {
              commit('setUser', result.user);
              resolve(result.user);
            },
          )
          .catch(error => reject(error));
      });
    },
    linkWithOAuth({ commit }, provider) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.linkWithRedirect(getOAuthProvider(provider))
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    linkWithEmailPassword({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        firebase.auth().currentUser.linkWithCredential(credential)
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    reauthWithOAuth({ commit }, provider) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.reauthenticateWithRedirect(getOAuthProvider(provider))
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    reauthWithEmailPassword({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        firebase.auth().currentUser.reauthenticateWithCredential(credential)
          .then((result) => {
            console.log('reauth', result);
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch((error) => {
            console.log('reauth fail', error);
            reject(error);
          });
      });
    },
    unlinkProvider({ commit }, providerId) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.unlink(providerId)
          .then((user) => {
            commit('setUser', user);
            resolve(user);
          })
          .catch(error => reject(error));
      });
    },
    resetPassword(context, email) {
      return new Promise((resolve, reject) => {
        firebase.auth().sendPasswordResetEmail(email)
          .then(() => { resolve(); })
          .catch(error => reject(error));
      });
    },
    sendEmailVerification() {
      return new Promise((resolve, reject) => {
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.sendEmailVerification()
            .then(() => { resolve(); })
            .catch(error => reject(error));
        } else {
          reject(new Error('no-user'));
        }
      });
    },
    updateEmail({ getters, dispatch }, newEmail) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.updateEmail(newEmail)
          .then(() => {
            if (!getters.user.emailVerified) {
              dispatch('sendEmailVerification');
            }
            dispatch('reloadUser');
            resolve(getters.user);
          })
          .catch(error => reject(error));
      });
    },
    updatePassword(context, newPassword) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.updatePassword(newPassword)
          .then(() => resolve())
          .catch(error => reject(error));
      });
    },
    updateDisplayName({ dispatch }, newDisplayName) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.updateProfile({
          displayName: newDisplayName,
        })
          .then(() => {
            dispatch('reloadUser');
            resolve();
          })
          .catch(error => reject(error));
      });
    },
    reloadUser({ commit }) {
      return new Promise((resolve, reject) => {
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.reload()
            .then(() => {
              commit('setUser', firebase.auth().currentUser);
              resolve(firebase.auth().currentUser);
            })
            .catch(error => reject(error));
        } else {
          resolve(null);
        }
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        firebase.auth().signOut();
        commit('setUser', null);
        resolve();
      });
    },
    deleteUser() {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.delete()
          .then(() => resolve())
          .catch(error => reject(error));
      });
    },
  },
  getters: {
    user: state => state.user,
    userMustVerifyEmail: state => state.userMustVerifyEmail,
    providersLinked: state => (state.user ? state.user.providerData || [] : []),
    oauthProviders: (state, getters) => {
      const providers = state.oauthProviders;
      providers.forEach((provider, index) => {
        providers[index].email = null;
        providers[index].isLinked = false;
        if (provider.available) {
          const providerLinked = getters.providersLinked.find(elem => elem.providerId === provider.id);
          if (providerLinked) {
            providers[index].email = providerLinked.email;
            providers[index].isLinked = true;
          }
        }
      });
      return providers;
    },
    availableOAuthProviders: (state, getters) => getters.oauthProviders.filter(provider => provider.available),
    linkedOAuthProviders: (state, getters) => getters.oauthProviders.filter(provider => provider.isLinked),
  },
};
