<template>
  <f7-page name="calendar">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block>
      <h3>{{ $t('calendar-intro') }}</h3>
    </f7-block>
    <f7-block v-if="!(userDoc && userDoc.ical && userDoc.ical.token)" strong>
      <p>{{ $t("calendar-getToken-explanation") }}</p>
      <p>
        <f7-button v-if="newTokenLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else fill large round @click="generateToken">
          {{ $t('calendar-getToken') }}
        </f7-button>
      </p>
      <p class="text-align-center">
        <em>{{ $t('internet-access-required') }}</em>
      </p>
    </f7-block>
    <f7-block v-else strong>
      <p>{{ $t('calendar-add-auto-explanation') }}</p>
      <p>
        <f7-button v-if="newTokenLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button
          v-else
          fill
          large
          round
          :href="calendarLink"
          external
          :target="$f7.device.cordova ? '_system' : '_self'"
        >
          {{ $t('calendar-getLink') }}
        </f7-button>
      </p>
      <p>
        {{ $t('calendar-add-manual-explanation') }}
      </p>
      <f7-list no-hairlines class="no-margin">
        <f7-list-input
          ref="calendarLink"
          class="calendar-link"
          outline
          label="Calendrier ICS / iCAL"
          floating-label
          type="text"
          :value="calendarLink"
        >
          <f7-button slot="root-end" @click="copy">
            {{ $t('copy') }}
          </f7-button>
        </f7-list-input>
      </f7-list>
      <p>{{ $t('calendar-newLink') }}</p>
      <p>
        <f7-button v-if="newTokenLoading" small disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else small @click="generateToken">
          {{ $t('calendar-getToken') }}
        </f7-button>
      </p>
    </f7-block>
    <f7-block>
      <p>{{ $t('calendar-update-frequency') }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      release: process.env.RELEASE || 'dev',
      newTokenLoading: false,
      generatedToken: null,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['user', 'userDoc']),
    calendarLink() {
      let domain = null;
      switch (this.release) {
        case 'prod':
          domain = 'app.kipinto.ch';
          break;
        case 'beta':
          domain = 'beta.kipinto.ch';
          break;
        case 'preprod':
          domain = 'preprod.kipinto.ch';
          break;
        case 'alpha':
          domain = 'alpha.kipinto.ch';
          break;
        default:
          domain = 'kipinto-dev-f7v4.web.app';
      }
      return `webcal://${domain}/kipinto.ics?user=${this.user.uid}&token=${this.generatedToken || this.userDoc.ical.token}`;
    },
  },
  mounted() {},
  methods: {
    async generateToken() {
      this.newTokenLoading = true;
      this.generatedToken = await this.$store.dispatch('getICalToken');
      this.newTokenLoading = false;
    },
    copy(e) {
      const input = this.$refs.calendarLink.$el.querySelector('input');
      console.log('calendar link', this.$refs.calendarLink.$el, input);
      input.select();
      document.execCommand('copy');
      this.$f7.toast.show({ text: this.$t('copied') });
      e.preventDefault();
    },
  },
};
</script>

<style lang="less" scoped>
.calendar-link {
  display: flex;
  justify-content: center;
  align-items: center;

  /deep/ .item-content {
    flex-grow: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Add to Calendar",
    "calendar-intro": "To make sure you don't miss any appointments, add reminders and upcoming interactions that you have saved in Kipinto directly to your calendar.",
    "calendar-getToken-explanation": "To do so, we will create a unique link to access your calendar.",
    "calendar-getToken": "Create my secured link",
    "calendar-getLink": "Add Kipinto to my calendar",
    "calendar-add-auto-explanation": "Clicking this button will open your default calendar application if it is compatible.",
    "calendar-add-manual-explanation": "If your application is not compatible with auto-add, copy the link below, and add it manually to your calendar (needs to be compatible with iCal or ICS calendars).",
    "internet-access-required": "Requires to be connected to the internet.",
    "calendar-newLink": "If necessary, you can regenerate a new secure link that will invalidate all existing links.",
    "calendar-update-frequency": "Note: depending on your calendar tool, calendar updates can happen between once per hour and once per day.",
    "copy": "Copy",
    "copied": "Link copied!"
  },
  "fr": {
    "title": "Ajouter au Calendrier",
    "calendar-intro": "Pour ne rater aucun rendez-vous, ajoutez les rappels et les interactions à venir que vous avez enregistrées dans Kipinto directement dans votre calendrier.",
    "calendar-getToken-explanation": "Pour ce faire, nous allons vous créer un lien unique pour accéder à votre calendrier.",
    "calendar-getToken": "Créer mon lien sécurisé",
    "calendar-getLink": "Ajouter Kipinto à mon calendrier",
    "calendar-add-auto-explanation": "Cliquer ce bouton ouvrira votre application de calendrier par défaut si elle est compatible.",
    "calendar-add-manual-explanation": "Si votre application n'est pas compatible avec l'ajout automatique, copiez le lien ci-dessous, et ajoutez le manuellement à votre calendrier (nécessite qu'il soit compatible avec les calendriers iCal ou ICS).",
    "internet-access-required": "Nécessite d'être connecté à internet.",
    "calendar-newLink": "Si besoin, vous pouvez regénérer un nouveau lien sécurisé qui invalidera tous les liens existants.",
    "calendar-update-frequency": "Note : selon votre outil de calendrier, les mises à jours du calendrier peuvent avoir lieu entre 1 fois par heure et 1 fois par jour.",
    "copy": "Copier",
    "copied": "Lien copié !"
  }
}
</i18n>
