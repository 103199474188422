// import { firestore } from 'firebase';
import { makeDate } from './utils';

export default class Metadata {
  constructor(metadata = {}) {
    this.created = metadata.created;
    this.updated = metadata.updated;
    this.deleted = !!metadata.deleted;
  }

  exportToDB(noUpdate = false) {
    return {
      created: this.created || new Date(), // firestore.FieldValue.serverTimestamp(),
      updated: noUpdate ? this.updated : new Date(), // firestore.FieldValue.serverTimestamp(),
      deleted: !!this.deleted,
    };
  }

  set created(v) {
    this._created = makeDate(v);
  }

  get created() {
    return this._created;
  }

  set updated(v) {
    this._updated = makeDate(v);
  }

  get updated() {
    return this._updated;
  }
}
