import firebase from './firebase-config'; // REDUCE SIZE
import HomePage from '../pages/home.vue';
import IntroPage from '../pages/intro.vue';
import OnboardingPage from '../pages/onboarding.vue';
import LoginSignupPage from '../pages/login-signup.vue';
import VerifyEmailPage from '../pages/verify-email.vue';
import AccountPermanentifyPage from '../pages/account-permanentify.vue';
import AccountManagementPage from '../pages/account-management.vue';
import AccountReauthPage from '../pages/account-reauth.vue';
import LegalsPage from '../pages/legals.vue';
import EventsPage from '../pages/events.vue';
import ContactsPage from '../pages/contacts.vue';
import ContactPage from '../pages/contact.vue';
import ContactEditPage from '../pages/contact-edit.vue';
import EventPage from '../pages/event.vue';
import EventEditPage from '../pages/event-edit.vue';
import ParametersPage from '../pages/parameters.vue';
import HashtagsPage from '../pages/hashtags.vue';
import HashtagSearchPage from '../pages/hashtag-search.vue';
import StatsPage from '../pages/stats.vue';
import NotificationsPage from '../pages/notifications.vue';
import CalendarPage from '../pages/calendar.vue';
import BackupRestorePage from '../pages/backup-restore.vue';
import ContactsPhoneListPage from '../pages/contacts-phone-list.vue';
import ContactsPhoneSelectedPage from '../pages/contacts-phone-selected.vue';
import UpdateDBPage from '../pages/update-db.vue';
import Popup from '../pages/popup.vue';
import AuthRequiredPage from '../pages/auth-required.vue';
import NotFoundPage from '../pages/404.vue';

function checkAuth(to, from, resolve, reject) {
  const { currentUser } = firebase.auth();

  if (!currentUser) {
    reject();

    // If the forbidden page is not accessed in a visible view, we do not launch the animation
    const router = this;
    const { app } = router;
    const thisView = router.view;
    const isInCurrentView = thisView === app.views.current;
    this.navigate('/auth-required/', {
      animate: isInCurrentView,
      reloadAll: true, // TODO : ideally, the parameters should be the same than those of the navigation that triggered the guard
    });
  } else resolve();
}

const routes = [{
  path: '/',
  component: HomePage,
  beforeEnter: checkAuth,
  alias: ['/home/'],
},
{
  path: '/intro/',
  component: IntroPage,
},
{
  path: '/onboarding/',
  component: OnboardingPage,
  beforeEnter: checkAuth,
},
{
  path: '/login-signup/',
  component: LoginSignupPage,
},
{
  path: '/verify-email/',
  component: VerifyEmailPage,
},
{
  path: '/legals/',
  component: LegalsPage,
},
{
  path: '/account-permanentify/',
  component: AccountPermanentifyPage,
  beforeEnter: checkAuth,
},
{
  path: '/account-management/',
  component: AccountManagementPage,
  beforeEnter: checkAuth,
},
{
  path: '/account-reauth/',
  component: AccountReauthPage,
  beforeEnter: checkAuth,
},
{
  path: '/events/',
  component: EventsPage,
  beforeEnter: checkAuth,
},
{
  path: '/contacts/',
  component: ContactsPage,
  beforeEnter: checkAuth,
},
{
  path: '/contact/:contactId/',
  component: ContactPage,
  beforeEnter: checkAuth,
},
{
  path: '/contact/',
  component: ContactPage,
  beforeEnter: checkAuth,
},
{
  path: '/contact-edit/:contactId/',
  component: ContactEditPage,
  beforeEnter: checkAuth,
},
{
  path: '/contact-new/',
  component: ContactEditPage,
  beforeEnter: checkAuth,
},
{
  path: '/contacts-phone-list/',
  component: ContactsPhoneListPage,
  beforeEnter: checkAuth,
},
{
  path: '/contacts-phone-selected/',
  component: ContactsPhoneSelectedPage,
  beforeEnter: checkAuth,
},
{
  path: '/event/',
  component: EventPage,
  beforeEnter: checkAuth,
},
{
  path: '/event/:eventId/',
  component: EventPage,
  beforeEnter: checkAuth,
},
{
  path: '/event-edit/:eventId/',
  component: EventEditPage,
  beforeEnter: checkAuth,
},
{
  path: '/event-new/',
  component: EventEditPage,
  beforeEnter: checkAuth,
},
{
  path: '/parameters/',
  component: ParametersPage,
  beforeEnter: checkAuth,
},
{
  path: '/hashtags/',
  component: HashtagsPage,
  beforeEnter: checkAuth,
},
{
  path: '/hashtag-search/:hashtag/',
  component: HashtagSearchPage,
  beforeEnter: checkAuth,
},
{
  path: '/notifications/',
  component: NotificationsPage,
  beforeEnter: checkAuth,
},
{
  path: '/stats/',
  component: StatsPage,
  beforeEnter: checkAuth,
},
{
  path: '/calendar/',
  component: CalendarPage,
  beforeEnter: checkAuth,
},
{
  path: '/backup-restore/',
  component: BackupRestorePage,
  beforeEnter: checkAuth,
},
{
  path: '/update-db/',
  component: UpdateDBPage,
  beforeEnter: checkAuth,
},
{
  path: '/p/(.*)',
  popup: {
    component: Popup,
  },
},
{
  path: '/auth-required/',
  component: AuthRequiredPage,
},
{
  path: '(.*)',
  component: NotFoundPage,
},
];

export default routes;
