<template>
  <f7-link icon-only panel-open="left" v-bind="$attrs">
    <f7-icon ios="f7:menu" aurora="f7:menu" md="material:menu">
      <f7-badge v-if="showBadge" color="orange">
        {{ pendingWrites }}
      </f7-badge>
    </f7-icon>
  </f7-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: { },
  data() {
    return {
      showBadge: false,
      delay: null,
    };
  },
  computed: {
    ...mapGetters(['pendingWrites']),
  },
  watch: {
    pendingWrites: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0 && !this.delay) {
          this.delay = setTimeout(() => { this.showBadge = true; }, 3000);
        } else if (newVal === 0) {
          clearTimeout(this.delay);
          this.delay = null;
          this.showBadge = false;
        }
      },
    },
  },
};
</script>
