<template>
  <f7-page v-if="user" name="parameters">
    <f7-navbar :title="$t('title')" />
    <f7-list media-list inset>
      <f7-list-item :title="username" :subtitle="email" :class="{'text-align-center': !photoURL }">
        <img v-if="photoURL" slot="media" :src="photoURL" width="44">
      </f7-list-item>
      <f7-list-button v-if="user.isAnonymous" :title="$t('anonymousAccount-upgrade')" href="/p/account-permanentify/" panel-close />
      <f7-list-button v-else :title="$t('manageAccount')" href="/p/account-management/" panel-close />
      <f7-list-button v-if="user.isAnonymous" :title="$t('anonymousAccount-delete')" color="red" panel-close @click="deleteUser" />
      <f7-list-button v-else :title="$t('disconnect')" color="red" panel-close @click="logout" />
    </f7-list>
    <f7-list>
      <f7-list-item :title="$t('stats')" link="/p/stats/" panel-close />
      <f7-list-item :title="$t('hashtags')" link="/hashtags/" />
    </f7-list>
    <f7-list>
      <f7-list-item :title="$t('featureRequest')" link="https://shipright.community/kipinto" external target="_blank" panel-close />
      <f7-list-item :title="$t('contactUs')" link="mailto:contact@kipinto.ch" external :target="$f7.device.cordova ? '_system' : '_self'" panel-close />
    </f7-list>
    <f7-list>
      <f7-list-item
        smart-select
        :smart-select-params="{ 'closeOnSelect': true }"
        :title="$t('language')"
      >
        <select v-model="$root.$i18n.locale" name="language">
          <option value="en">
            English
          </option>
          <option value="fr">
            Français
          </option>
        </select>
      </f7-list-item>
      <f7-list-item v-if="showNotificationsLink" :title="$t('notifications')" link="/p/notifications/" panel-close />
    </f7-list>

    <f7-block v-if="pendingWrites" class="text-align-center">
      <f7-chip :text="$tc('pendingWrites', pendingWrites)" media-bg-color="orange" color="orange" @click="showSyncInfo">
        <f7-icon slot="media" ios="f7:arrow_2_circlepath" aurora="f7:arrow_2_circlepath" md="material:sync" />
      </f7-chip>
    </f7-block>

    <f7-block>
      {{ $t('availableOnTheWeb') }} <f7-link :href="'https://'+webAppUrl+'/'" external target="_blank">
        {{ webAppUrl }}
      </f7-link>
    </f7-block>

    <f7-block>
      <a href="/p/legals/?tab=legals" panel-close>{{ $t('legals') }}</a><br>
      <a href="/p/legals/?tab=privacy" panel-close>{{ $t('privacy') }}</a><br>
      <a href="/p/legals/?tab=tos" panel-close>{{ $t('tos') }}</a>
    </f7-block>

    <f7-block-footer @click.native="versionClicked">
      v3.34.1 {{ target === 'web' ? release : '' }}
    </f7-block-footer>

    <template v-if="versionClickedTime >= 10 /* release !== 'prod' && release !== 'beta' */">
      <f7-block-title>Development / Beta tools</f7-block-title>
      <f7-list>
        <f7-list-item :title="$t('calendar')" link="/p/calendar/" panel-close />
        <f7-list-item :title="$t('backupRestore')" link="/p/backup-restore/" panel-close />
        <f7-list-button :title="$t('restart')" @click="reloadApp" />
        <f7-list-button title="Forget Language" @click="forgetLanguage" />
        <f7-list-button title="Forget Intro" @click="forgetIntro" />
        <f7-list-button title="Resync Store" @click="$store.dispatch('resyncStore')" />
        <!-- <f7-list-button title="Load Contacts" @click="loadContacts" /> -->
        <!-- <f7-list-button id="backup" title="Backup" @click="backup" /> -->
      </f7-list>
    </template>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

/*
import firebase from '../js/firebase-config'; // REDUCE SIZE
import dummyContacts from '../dummy/contacts.json';
import dummyInteractions from '../dummy/interactions.json';
*/

export default {
  data() {
    return {
      versionFirstClick: 0,
      versionClickedTime: 0,
      release: process.env.RELEASE || 'dev',
      target: process.env.TARGET || 'web',
      showNotificationsLink: window.cordova && window.cordova.plugins.notification && window.cordova.plugins.notification.local,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'pendingWrites',
    ]),
    username() {
      return this.user.isAnonymous ? this.$t('anonymousAccount-displayName') : this.user.displayName;
    },
    email() { return this.user ? this.user.email : null; },
    photoURL() { return this.user ? this.user.photoURL : null; },
    webAppUrl() {
      switch (this.release) {
        case 'prod':
        case 'beta':
          return 'app.kipinto.ch';
        case 'preprod':
        case 'alpha':
          return 'preprod.kipinto.ch';
        default:
          return 'kipinto-dev-f7v4.web.app';
      }
    },
  },
  watch: {
    '$root.$i18n.locale': (newLocale) => {
      console.log('User set a new language', newLocale);
      // We try to save the choice of the user
      try {
        localStorage.setItem('locale', newLocale);
      } catch (error) { console.error(error); }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    deleteUser() {
      this.$f7.dialog.confirm(
        this.$t('anonymousAccount-delete-warning'),
        this.$t('anonymousAccount-delete'),
        () => {
          this.$store.dispatch('deleteUser');
        },
      );
    },
    showSyncInfo() {
      this.$f7.dialog.alert(this.$t('syncExplanation'), this.$tc('pendingWrites', this.pendingWrites));
    },
    forgetLanguage() {
      // We try to forget the choice of the user
      try {
        localStorage.removeItem('locale');
      } catch (error) { console.error(error); }
    },
    forgetIntro() {
      // We try to forget that we have seen the intro
      try {
        localStorage.removeItem('introRead');
      } catch (error) { console.error(error); }
    },
    reloadApp() {
      window.location.reload();
    },
    versionClicked() {
      console.log(this.versionFirstClick, this.versionClickedTime);
      if (Date.now() - this.versionFirstClick < 10000) {
        this.versionClickedTime += 1;
      } else {
        this.versionFirstClick = Date.now();
        this.versionClickedTime = 0;
      }
    },
    /*
    loadContacts() {
      console.log('Starting import of dummy contacts');
      let importSuccess = 0;
      let importFail = 0;
      const contactsId = [];
      function incrementSuccess() {
        importSuccess += 1;
        return importSuccess;
      }
      function incrementFail() {
        importFail += 1;
        return importFail;
      }
      function getSucess() {
        return importSuccess;
      }
      function getFail() {
        return importFail;
      }
      function insertContactId(contactId) {
        contactsId.push(contactId);
        return contactsId;
      }
      function getContactsId() {
        return contactsId;
      }
      const _this = this;
      for (let i = 0; i < dummyContacts.length; i++) {
        // We update the "date" fields so they are interpreted as date by Firebase
        dummyContacts[i].metadata.updated = new Date(
          dummyContacts[i].metadata.updated,
        );
        dummyContacts[i].metadata.created = new Date(
          dummyContacts[i].metadata.created,
        );
        // We import into firebase
        firebase
          .firestore()
          .collection(`users/${firebase.auth().currentUser.uid}/contacts`)
          .add(dummyContacts[i])
          // If we successfully saved the contact
          .then((docRef) => {
            console.log('New contact created with ID: ', docRef.id);
            insertContactId(docRef.id);
            incrementSuccess();
            // We announce the result of the import
            if (getSucess() + getFail() === dummyContacts.length) {
              _this.$f7.dialog.alert(
                `ImportSuccess: ${getSucess()} ; ImportFail: ${getFail()}`,
              );
              _this.loadInteractions(getContactsId());
            }
          })
          // If creating the contact failed
          .catch((error) => {
            console.error('Error creating the contact: ', error);
            incrementFail();
            // We announce the result of the import
            if (getSucess() + getFail() === dummyContacts.length) {
              _this.$f7.dialog.alert(
                `ImportSuccess: ${getSucess()} ; ImportFail: ${getFail()}`,
              );
              _this.loadInteractions(getContactsId());
            }
          });
      }
    },
    loadInteractions(contactsId) {
      console.log('Starting import of dummy interactions');
      for (let i = 0; i < dummyInteractions.length; i++) {
        // We update the "date" fields so they are interpreted as date by Firebase
        dummyInteractions[i].metadata.updated = new Date(
          dummyInteractions[i].metadata.updated,
        );
        dummyInteractions[i].metadata.created = new Date(
          dummyInteractions[i].metadata.created,
        );
        dummyInteractions[i].date = new Date(
          dummyInteractions[i].date,
        );
        dummyInteractions[i].contacts = [];
        const nbContacts = Math.ceil(Math.random() * 3);
        for (let c = 0; c < nbContacts; c++) {
          dummyInteractions[i].contacts.push(contactsId[Math.floor(Math.random() * contactsId.length)]);
        }
        // We import into firebase
        firebase
          .firestore()
          .collection(`users/${firebase.auth().currentUser.uid}/interactions`)
          .add(dummyInteractions[i])
          // If we successfully saved the contact
          .then((docRef) => {
            console.log('New interaction created with ID: ', docRef.id);
          })
          // If creating the contact failed
          .catch((error) => {
            console.error('Error creating the interaction: ', error);
          });
      }
    },
    */
  },
};
</script>

<i18n>
{
  "en": {
    "language": "Language",
    "title": "Parameters",
    "disconnect": "Disconnect",
    "manageAccount": "Manage Account",
    "stats": "Statistics",
    "hashtags": "Hashtags",
    "calendar": "Add to calendar",
    "notifications": "Manage notifications",
    "backupRestore": "Backup & Restore",
    "contactUs": "Contact us",
    "featureRequest": "Any suggestion?",
    "pendingWrites": "You're synced | 1 update waiting for sync | {n} updates waiting for sync",
    "syncExplanation": "To sync these updates, connect to internet and (re) open Kipinto. If you use several devices, be careful to sync each of them regularly.",
    "anonymousAccount-displayName": "Temporary Account",
    "anonymousAccount-upgrade": "Convert to permanent",
    "anonymousAccount-delete": "Delete the account",
    "anonymousAccount-delete-warning": "<p>If you delete your temporary account, all the data will be lost forever.</p>",
    "restart": "Restart Kipinto",
    "availableOnTheWeb": "Kipinto is available on iOS, Android, and the web:",
    "legals": "Legals",
    "privacy": "Privacy",
    "tos": "Terms of Service"
  },
  "fr": {
    "language": "Langue",
    "title": "Paramètres",
    "disconnect": "Se déconnecter",
    "manageAccount": "Gérer votre compte",
    "stats": "Statistiques",
    "hashtags": "Hashtags",
    "calendar": "Ajouter au calendrier",
    "notifications": "Gérer les notifications",
    "backupRestore": "Sauvegarde & Restauration",
    "contactUs": "Nous contacter",
    "featureRequest": "Des suggestions ?",
    "pendingWrites": "Vous êtes synchronisé | 1 modif à synchroniser | {n} modifs à synchroniser",
    "syncExplanation": "Pour synchroniser ces modifications, connectez-vous à internet et (re) lancez Kipinto. Si vous utilisez plusieurs appareils, faites attention à bien les synchroniser régulièrement.",
    "anonymousAccount-displayName": "Compte Temporaire",
    "anonymousAccount-upgrade": "Convertir en permanent",
    "anonymousAccount-delete": "Supprimer le compte",
    "anonymousAccount-delete-warning": "<p>Si vous supprimez le compte de test, toutes les données seront perdues.</p>",
    "restart": "Redémarrer Kipinto",
    "availableOnTheWeb": "Kipinto est disponible sur iOS, sur Android, et sur le web :",
    "legals": "Mentions Légales",
    "privacy": "Politique de Confidentialité",
    "tos": "CGU"
  }
}
</i18n>
