<template>
  <f7-list-item
    :title="contact.name"
    :link="editMode ? false : link"
    v-bind="$attrs"
    :swipeout="!editMode"
    :checkbox="editMode"
    no-chevron
    class="contact-list-item"
    @change="$emit('change', $event)"
  >
    <template v-if="editMode">
      <span slot="inner" class="item-text">
        <f7-icon ios="f7:tag_fill" md="material:local_offer" aurora="f7:tag_fill" size="16px" color="gray" />
        <template v-if="contact.hashtags.length">
          <!-- <f7-chip v-for="hashtag in contact.hashtags" :key="hashtag" :text="hashtag" /> -->
          <span v-for="hashtag in contact.hashtags" :key="hashtag">#{{ hashtag }} </span>
        </template>
        <template v-else>
          <em>{{ $t('noHashtag') }}</em>
        </template>
      </span>
      <span v-if="editMode && contact.frequency" slot="after">
        {{ $t('frequency.'+contact.frequency+'days') }}
      </span>
      <f7-icon
        v-if="editMode && !contact.frequency"
        slot="after"
        md="material:notifications_off"
        ios="f7:bell_slash_fill"
        aurora="f7:bell_slash_fill"
      />
    </template>
    <template v-else>
      <f7-icon
        slot="media"
        f7="circle_fill"
        size="16px"
        :style="{ color: contact.statusColorHex }"
      />
      <span slot="inner" class="item-text">
        <f7-icon ios="f7:bell_fill" md="material:notifications" aurora="f7:bell_fill" size="16px" color="gray" />
        <span class="time-ago">{{ nextReminderFromNow }}</span>
      </span>
      <f7-swipeout-actions left>
        <f7-swipeout-button v-if="contact.frequency" close color="blue" @click="freezeContact">
          {{ $t('action.freeze') }}
        </f7-swipeout-button>
        <f7-swipeout-button v-else color="blue" close @click="showUnfreezeAS">
          {{ $t('action.unfreeze') }}
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions right>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+contact.id+'&type=email'">
          <event-icon icon="email" />
        </f7-swipeout-button>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+contact.id+'&type=chat'">
          <event-icon icon="chat" />
        </f7-swipeout-button>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+contact.id+'&type=call'">
          <event-icon icon="call" />
        </f7-swipeout-button>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+contact.id+'&type=meeting'">
          <event-icon icon="meeting" />
        </f7-swipeout-button>
      </f7-swipeout-actions>
    </template>
  </f7-list-item>
</template>

<script>
import Moment from 'moment';
import Contact from '../class/contact.class';
import EventIcon from './event-icon.vue';

export default {
  components: { EventIcon },
  props: {
    contact: {
      type: Contact,
      default: new Contact(),
    },
    backLink: {
      type: String,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      link: this.backLink ? `/contact/${this.contact.id}/?back=${this.backLink}` : `/contact/${this.contact.id}/`,
    };
  },
  computed: {
    nextReminderFromNow() {
      if (this.contact.futureInteractionDate) {
        if (!this.contact.lastInteraction) {
          return this.$t('neverContacted');
        }
        if (this.contact.isPostponed) {
          return this.$t('postponedTo{x}', { x: Moment(this.contact.futureInteractionDate).format('DD MMM') });
        }
        if (this.contact.futureInteractionDate.toDateString() === new Date().toDateString()) {
          return this.$t('contactNow');
        }
        if (this.contact.futureInteractionDate < new Date()) {
          return this.$t('{x}DaysLate', { x: Moment(this.contact.futureInteractionDate).fromNow(true) });
        }
        if (this.contact.futureInteractionDate > new Date()) {
          return this.$t('{x}DaysLeft', { x: Moment(this.contact.futureInteractionDate).fromNow(true) });
        }
      }
      return this.$t('contactFrozen');
    },
  },
  beforeCreate() {
    this.Moment = Moment;
  },
  mounted() {
    Moment.locale(this.$i18n.locale);
  },
  methods: {
    freezeContact() {
      this.$store.dispatch('freezeContact', this.contact.id);
    },
    showUnfreezeAS() {
      if (!this.unfreezeActionSheet) {
        this.unfreezeActionSheet = this.$f7.actions.create({
          buttons: [
            [
              {
                text: this.$t('frequency.title'),
                label: true,
              },
              {
                text: this.$t('frequency.every1days'),
                onClick: () => this.setFrequency(1),
              },
              {
                text: this.$t('frequency.every7days'),
                onClick: () => this.setFrequency(7),
              },
              {
                text: this.$t('frequency.every15days'),
                onClick: () => this.setFrequency(15),
              },
              {
                text: this.$t('frequency.every30days'),
                onClick: () => this.setFrequency(30),
              },
              {
                text: this.$t('frequency.every90days'),
                onClick: () => this.setFrequency(90),
              },
              {
                text: this.$t('frequency.every180days'),
                onClick: () => this.setFrequency(180),
              },
              {
                text: this.$t('frequency.every365days'),
                onClick: () => this.setFrequency(365),
              },
            ],
            [
              {
                text: this.$t('common.cancel'),
                color: 'red',
              },
            ],
          ],
        });
      }
      this.unfreezeActionSheet.open();
    },
    setFrequency(value) {
      if (this.contact.frequency !== value) {
        this.$store.dispatch('updateContactFrequency', {
          contact: this.contact,
          frequency: value,
        });
      }
    },
  },
};
</script>

<style lang="less">
.contact-list-item {
  --f7-checkbox-extra-margin: 0px;

  .media-list & .item-media {
    align-self: center;

    .md & .icon {
      margin: 1px;
    }
    .ios & .icon {
      margin: 3px;
    }
  }
  /*
  /deep/ .item-media .icon {
    width: 16px;
    height: 16px;
  }
  */
  .chip {
    margin: 0;
    line-height: inherit;
    height: inherit;
    vertical-align: top;
  }
  .item-after {
    height: 1em;
  }
}
</style>

<i18n>
{
  "en": {
    "{x}DaysLeft": "{x} left",
    "{x}DaysLate": "{x} late",
    "postponedTo{x}": "postponed to {x}",
    "contactFrozen": "contact frozen",
    "contactNow": "contact today",
    "neverContacted": "never contacted",
    "noHashtag": "no hashtag"
  },
  "fr": {
    "{x}DaysLeft": "encore {x}",
    "{x}DaysLate": "{x} de retard",
    "postponedTo{x}": "reporté au {x}",
    "contactFrozen": "contact gelé",
    "contactNow": "contacter aujourd'hui",
    "neverContacted": "jamais contacté",
    "noHashtag": "aucun hashtag"
  }
}
</i18n>
