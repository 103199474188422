<template>
  <f7-page name="events" :page-content="false">
    <f7-navbar ref="navbar" large>
      <f7-nav-left>
        <burger-button />
      </f7-nav-left>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-title-large>{{ $t('title') }}</f7-nav-title-large>
      <f7-subnavbar>
        <f7-segmented strong>
          <f7-button tab-link="#tab-events-upcoming" tab-link-active>
            {{ $t('upcoming') }}
          </f7-button>
          <f7-button tab-link="#tab-events-completed">
            {{ $t('completed') }}
          </f7-button>
        </f7-segmented>
      </f7-subnavbar>
    </f7-navbar>
    <div v-if="toastDate" v-show="isScrolling" class="toast toast-center date" :style="{ opacity: isFast ? 1 : 0 }">
      {{ toastDate }}
    </div>
    <f7-tabs animated>
      <f7-tab id="tab-events-upcoming" ref="upcoming" tab-active class="page-content" @tab:show="adaptNavbarCollapse('upcoming')">
        <events-upcoming />
      </f7-tab>
      <f7-tab id="tab-events-completed" ref="completed" class="page-content" @tab:show="adaptNavbarCollapse('completed')">
        <events-completed @dates="dates = $event" />
      </f7-tab>
    </f7-tabs>
    <f7-fab slot="fixed" position="right-bottom" href="/p/event-new/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add" />
    </f7-fab>
  </f7-page>
</template>

<script>
import Moment from 'moment';
import BurgerButton from '../components/burger-button.vue';
import EventsUpcoming from '../components/events-upcoming.vue';
import EventsCompleted from '../components/events-completed.vue';

export default {
  components: { BurgerButton, EventsUpcoming, EventsCompleted },
  data() {
    return {
      scrolled: {
        upcoming: 0,
        completed: 0,
      },
      navbarLargeHeight: 0,
      isFast: false,
      isScrolling: false,
      dates: { from: null, to: null },
    };
  },
  computed: {
    toastDate() {
      if (this.dates.from && this.dates.to) {
        const dateDifference = this.dates.from.getTime() - this.dates.to.getTime();
        // If the dates are less than 2 months apart
        if (dateDifference < 60 * 24 * 60 * 60 * 1000) {
        // We return the average month
          return Moment((this.dates.from.getTime() + this.dates.to.getTime()) / 2).format('MMMM YYYY');
        }
        // Else we return the average year
        return Moment((this.dates.from.getTime() + this.dates.to.getTime()) / 2).format('YYYY');
      }
      return null;
    },
  },
  mounted() {
    this.navbarLargeHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--f7-navbar-large-title-height'), 10);

    let lastPos = 0;
    let interval = null;
    let stopIntervalTimeout = null;
    let radar = [];
    let debounce = null;
    const delay = 50; // in ms
    const tabCompletedEl = this.$refs.completed.$el;

    const speedMeasure = () => {
      // Compute the speed
      const newPos = tabCompletedEl.scrollTop;
      const speed = Math.abs(newPos - lastPos);
      lastPos = newPos;
      // Add the delta to the radar
      if (radar.length > 10) radar.shift();
      radar.push(speed);
      // Compute avg speed
      const avgSpeed = radar.reduce((sume, el) => sume + el, 0) / radar.length;
      const isFast = avgSpeed > 75;
      // Debounce: once shown, fast is shown for at least 500ms
      if (!this.isFast && isFast) debounce = Date.now();
      this.isFast = isFast || ((Date.now() - debounce) < 500);
    };

    tabCompletedEl.addEventListener('scroll', () => {
      clearTimeout(stopIntervalTimeout);
      this.isScrolling = true;
      if (!interval) {
        speedMeasure();
        interval = setInterval(speedMeasure, delay);
      }
      stopIntervalTimeout = setTimeout(() => {
        clearInterval(interval);
        interval = null;
        lastPos = tabCompletedEl.scrollTop;
        radar = [];
        this.isFast = false;
        this.isScrolling = false;
      }, 500);
    });
  },
  methods: {
    adaptNavbarCollapse(tabShow) {
      // Since the list can be scrolled differently on both tabs, the navbar is not always on the right state when moving from one tab to another
      const tabHide = tabShow === 'upcoming' ? 'completed' : 'upcoming';
      // We update the top position of the hiding tab
      this.scrolled[tabHide] = this.$refs[tabHide].$el.scrollTop;
      // If the navbar is collapsed on the tab left but not in the tab opening
      if (this.scrolled[tabHide] >= 0 && this.scrolled[tabShow] <= this.navbarLargeHeight) {
        // We scroll the list enough so the navbar is collapsed
        this.$refs[tabShow].$el.scrollTop = Math.min(this.scrolled[tabHide], this.navbarLargeHeight);
        // TODO : if there is not enough elements to scroll the list, we should use the same animation than in the else if
      // If the navbar is deployed on the tab left but not in the tab opening
      } else if (this.scrolled[tabHide] < this.navbarLargeHeight && this.scrolled[tabShow] > this.navbarLargeHeight) {
        // We add css transitions, collapse the navbar (with transition), then remove transitions
        const elemToCollapse = this.$refs.navbar.$el.querySelectorAll('.title, .navbar-bg, .subnavbar, .title-large-text');
        // eslint-disable-next-line no-param-reassign
        elemToCollapse.forEach((element) => { element.style.transition = 'all 0.3s'; });
        this.$f7.navbar.collapseLargeTitle(this.$refs.navbar.$el);
        // eslint-disable-next-line no-param-reassign
        setTimeout(() => { elemToCollapse.forEach((element) => { element.style.transition = null; }); }, 300);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.subnavbar .button.tab-link {
  color: white;

  &.tab-link-active {
    color: var(--f7-button-text-color, var(--f7-theme-color))
  }
}
#tab-events-completed {
  background: white;
}

.date, .info {
  padding: 20px;
  pointer-events: none;
  position: fixed;

  &.info {
    transform: translateY(-50px);
    opacity: 1;
    display: block;

  }
}
</style>

<i18n>
{
  "en": {
    "title": "Interactions",
    "completed": "Completed",
    "upcoming": "Upcoming"
  },
  "fr": {
    "title": "Interactions",
    "completed": "Complétées",
    "upcoming": "À venir"
  }
}
</i18n>
