<template>
  <f7-page name="contacts" ptr class="contacts-page" @ptr:refresh="onRefresh">
    <f7-navbar large>
      <f7-nav-left>
        <burger-button />
      </f7-nav-left>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="editMode = !editMode">
          {{ editMode ? $t('finished') : $t('manage') }}
        </f7-link>
      </f7-nav-right>
      <f7-nav-title-large>{{ $t('title') }}</f7-nav-title-large>
      <f7-subnavbar :inner="false">
        <f7-searchbar
          :disable-button-text="$t('common.cancel')"
          :placeholder="$t('searchInContacts')"
          :clear-button="true"
          :custom-search="true"
          @searchbar:search="search"
        />
      </f7-subnavbar>
    </f7-navbar>

    <empty-content
      v-if="!searchQuery && Object.keys(contactsAlphaGrouped).length === 0"
      :title="$t('empty-title')"
      :text="$t('empty-text')"
    />

    <empty-content
      v-if="searchQuery && !contactsFound.length"
      :title="$t('searchNoResult-title')"
      :text="$t('searchNoResult-text')"
      search
    />

    <f7-list-index ref="listIndex" :init="false" />
    <f7-list ref="contactsList" contacts-list>
      <f7-list-group v-for="(group, key) in contactsAlphaGrouped" :key="key" media-list>
        <f7-list-item :title="key" group-title />
        <contact-list-item
          v-for="contact in group"
          :key="contact.id"
          :contact="contact"
          :edit-mode="editMode"
          :back-link="$t('title')"
          :checked="contactsSelectedId.indexOf(contact.id) >= 0"
          @change="checkContact($event, contact.id)"
        />
      </f7-list-group>
    </f7-list>

    <f7-fab v-show="!editMode" slot="fixed" position="right-bottom" href="/p/contact-new/">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add" />
    </f7-fab>

    <f7-fab-backdrop v-show="editMode" slot="fixed" />
    <f7-fab v-if="editMode" slot="fixed" :text="$t('manage')+' '+contactsSelectedId.length" position="right-bottom">
      <f7-icon ios="f7:pencil" aurora="f7:pencil" md="material:edit" />
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close" />
      <f7-fab-buttons position="top">
        <f7-fab-button :label="$t('hashtags')" fab-close @click="showHashtagsModal = true">
          <f7-icon ios="f7:tag_fill" aurora="f7:tag_fill" md="material:local_offer" />
        </f7-fab-button>
        <f7-fab-button :label="$t('reminders')" fab-close @click="showFrequencyAction = true">
          <f7-icon ios="f7:bell_fill" aurora="f7:bell_fill" md="material:notifications" />
        </f7-fab-button>
      </f7-fab-buttons>
      <f7-fab-buttons position="left">
        <f7-fab-button :tooltip="$t('unselectAll')" @click="unselectAll">
          <f7-icon ios="f7:xmark_circle" aurora="f7:xmark_circle" md="material:check_box_outline_blank" />
        </f7-fab-button>
        <f7-fab-button :tooltip="$t('invertSelection')" @click="invertSelection">
          <f7-icon ios="f7:arrow_right_arrow_left_circle" aurora="f7:arrow_right_arrow_left_circle" md="material:swap_horiz" />
        </f7-fab-button>
        <f7-fab-button :tooltip="$t('selectAll')" @click="selectAll">
          <f7-icon ios="f7:checkmark_circle" aurora="f7:checkmark_circle" md="material:check_box" />
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>

    <sheet-hashtags v-if="editMode" :opened="showHashtagsModal" :contacts-selected-id="contactsSelectedId" @sheet:closed="showHashtagsModal = false" />

    <action-frequency-selector v-if="editMode" :opened="showFrequencyAction" :contacts-selected-id="contactsSelectedId" @actions:closed="showFrequencyAction = false" />
  </f7-page>
</template>

<script>
// import Fuse from 'fuse.js';
import { mapGetters } from 'vuex';
import ContactListItem from '../components/contact-list-item.vue';
import BurgerButton from '../components/burger-button.vue';
import EmptyContent from '../components/empty-content.vue';
import SheetHashtags from '../components/sheet-hashtags.vue';
import ActionFrequencySelector from '../components/action-frequency-selector.vue';

const removeAccents = str => (str ? str.normalize('NFD').replace(/[\u0300-\u036F]/g, '') : '');

export default {
  components: {
    ContactListItem, BurgerButton, EmptyContent, SheetHashtags, ActionFrequencySelector,
  },
  data() {
    return {
      listIndex: null,
      searchQuery: null,
      mustUpdateIndex: false,
      editMode: false,
      contactsSelectedId: [],
      showHashtagsModal: false,
      showFrequencyAction: false,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'contacts',
    ]),
    /*
    fuse() {
      const options = {
        shouldSort: true,
        threshold: 0.2,
        ignoreLocation: true,
        keys: [
          { name: 'name', weight: 0.6 },
          { name: 'company', weight: 0.2 },
          { name: 'hashtags', weight: 0.2 },
        ],
        getFn: (...args) => {
          const val = Fuse.config.getFn.apply(this, args);
          return Array.isArray(val) ? val.map(item => `#${removeAccents(item)}`) : removeAccents(val);
        },
      };
      return new Fuse(this.contacts, options);
    },
    */
    contactsFound() {
      if (this.searchQuery) {
        const noAccentSearchQuery = removeAccents(this.searchQuery);
        const reg = RegExp(noAccentSearchQuery, 'i');
        return this.contacts.filter(contact => reg.test(removeAccents(contact.name)) || reg.test(removeAccents(contact.company)) || contact.hashtags.some(hash => reg.test(`#${hash}`)));
        // return this.fuse.search(removeAccents(this.searchQuery));
      }
      return this.contacts;
    },
    contactsAlphaGrouped() {
      const groupedContacts = {};
      const contacts = this.contactsFound;
      contacts.forEach((contact) => {
        // We prepare to add the contact in the right "first-letter" group. Note: the "normalize/replace" removes the accents
        let firstLetter = removeAccents(contact.firstname[0]).toUpperCase();
        // If not alphabetical, we had it to the # group
        if (!firstLetter.match(/[A-Z]/)) firstLetter = '#';
        // If the group does not already exists we initialize it
        if (!groupedContacts[firstLetter]) groupedContacts[firstLetter] = [];
        // We add the contact to the Array
        groupedContacts[firstLetter].push(contact);
      });
      // We sort the groups
      const sortedContacts = Object.keys(groupedContacts)
        .sort()
        .reduce((acc, key) => ({
          ...acc, [key]: groupedContacts[key],
        }), {});
      // We return the sorted contacts
      return sortedContacts;
    },
  },
  watch: {
    contactsAlphaGrouped() {
      this.$nextTick(() => {
        if (this.listIndex) {
          /*
          // If the list is hidden we simply flag it to be updated
          if (this.searchQuery) this.mustUpdateIndexAfterSearch = true;
          // Otherwise we update it
          else this.listIndex.update();
          */
          this.listIndex.update();
        }
      });
    },
    /*
    searchQuery(newVal, oldVal) {
      // If search is finished and the list index is waiting for an update
      if (oldVal && !newVal && this.mustUpdateIndexAfterSearch) {
        this.mustUpdateIndexAfterSearch = false;
        this.$nextTick(() => {
          if (this.listIndex) this.listIndex.update();
        });
      }
    },
    */
  },
  mounted() {
    this.listIndex = this.$f7.listIndex.create({
      el: this.$refs.listIndex.$el,
      listEl: this.$refs.contactsList.$el,
      label: true,
    });
  },
  methods: {
    onRefresh(done) {
      const timeout = setTimeout(() => {
        done();
        this.$f7.toast.show({ text: this.$t('ptr-timedout') });
      }, 5000);
      this.$store.dispatch('testConnection')
        .then(() => {
          clearTimeout(timeout);
          done();
        })
        .catch((error) => {
          clearTimeout(timeout);
          this.$f7.dialog.alert(error);
          done();
        });
    },
    search(searchbar, query) {
      this.searchQuery = query;
    },
    checkContact(event, contactId) {
      if (event.target.checked) {
        this.contactsSelectedId.push(contactId);
      } else {
        this.contactsSelectedId = this.contactsSelectedId.filter(el => el !== contactId);
      }
    },
    invertSelection() {
      for (const contact of this.contactsFound) {
        const contactIndex = this.contactsSelectedId.indexOf(contact.id);
        if (contactIndex < 0) this.contactsSelectedId.push(contact.id);
        else this.contactsSelectedId.splice(contactIndex, 1);
      }
    },
    selectAll() {
      for (const contact of this.contactsFound) {
        const contactIndex = this.contactsSelectedId.indexOf(contact.id);
        if (contactIndex < 0) this.contactsSelectedId.push(contact.id);
      }
    },
    unselectAll() {
      for (const contact of this.contactsFound) {
        const contactIndex = this.contactsSelectedId.indexOf(contact.id);
        if (contactIndex >= 0) this.contactsSelectedId.splice(contactIndex, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
// Make swipeouts start from "after" the letter on MD instead of from the left of the page
.md .contacts-list /deep/ li:not(.list-group-title) {
  padding-left: 0;
  margin-left: 56px;
}
.fab-buttons-top {
  left: unset;
  right: 0;
}
</style>

<i18n>
{
  "en": {
    "manage": "Manage",
    "finished": "Finished",
    "empty-title": "No contact",
    "empty-text": "<p>You do not have any contact... yet.</p><p>Click the + button on the top-right<br />to create your first contact!</p>",
    "searchInContacts": "Search a contact",
    "searchNoResult-title": "No result",
    "searchNoResult-text": "<p>We did not find any contacts<br>matching your search.</p><p><em>And yet we searched everywhere:<br>First name, Last name, Company & Hashtags!</em></p>",
    "title": "Contacts",
    "ptr-timedout": "Refresh error",
    "hashtags": "Hashtags",
    "reminders": "Reminders Frequency",
    "selectAll": "Select all",
    "unselectAll": "Unselect all",
    "invertSelection": "Invert selection"
  },
  "fr": {
    "manage": "Gérer",
    "finished": "Terminé",
    "empty-title": "Aucun contact",
    "empty-text": "<p>Vous n'avez aucun contact... pour le moment.</p><p>Cliquez sur le bouton + en haut à droite<br />pour créer votre premier contact !</p>",
    "searchInContacts": "Rechercher un contact",
    "searchNoResult-title": "Aucun résultat",
    "searchNoResult-text": "<p>Nous n'avons trouvé aucun contact<br>correspondant à votre recherche.</p><p><em>Et pourtant nous avons cherché partout :<br>prénom, nom, entreprise & hashtags !</em></p>",
    "title": "Contacts",
    "ptr-timedout": "Erreur de rafraichissement",
    "hashtags": "Hashtags",
    "reminders": "Fréquence des rappels",
    "selectAll": "Sélectionner tous",
    "unselectAll": "Tout déselectionner",
    "invertSelection": "Inverser la sélection"
  }
}
</i18n>
