<template>
  <f7-list-item
    ref="frequency"
    smart-select
    :smart-select-params="{closeOnSelect: true}"
    :title="$t('frequency.title')"
  >
    <select
      name="frequency"
      class="select-frequency"
      :value="normalizedValue"
      @change="updateValue($event.target.value)"
    >
      <option
        v-for="frequency in [1, 7, 15, 30, 90, 180, 365, 0]"
        :key="frequency"
        :value="frequency"
        :data-display-as="$t('frequency.'+frequency+'days')"
      >
        {{ $t('frequency.every'+frequency+'days') }}
      </option>
    </select>
  </f7-list-item>
</template>

<script>
import Contact from '../class/contact.class';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      default: 90,
    },
  },
  data() {
    return {
      // normalizedValue: this.normalizeValue(this.value),
    };
  },
  computed: {
    normalizedValue() {
      return Contact.normalizeFrequency(this.value);
    },
  },
  watch: {
    normalizedValue(newValue) {
      this.$refs.frequency.f7SmartSelect.setValue(
        newValue,
      );
    },
  },
  methods: {
    updateValue(v) {
      this.$emit('change', Contact.normalizeFrequency(v));
    },
  },
};
</script>
