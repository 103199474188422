<template>
  <empty-content
    v-if="Object.keys(eventsFutureGrouped).length === 0"
    :title="$t('empty-title')"
    :text="$t('empty-text')"
  />
  <f7-list v-else>
    <f7-list-group v-for="(group, key) in eventsFutureGrouped" :key="key" media-list>
      <f7-list-item
        :title="key === 'today' ? $t('today') : key === 'overdue' ? $t('overdue') : Moment(key).format('MMMM YYYY')"
        group-title
        class="month-title"
      />
      <event-list-item v-for="event in group" :key="event.hash" :event="event" :back-link="$t('interactions')" />
    </f7-list-group>
  </f7-list>
</template>

<script>
import Moment from 'moment';
import { mapGetters } from 'vuex';
import EventListItem from './event-list-item.vue';
import EmptyContent from './empty-content.vue';

export default {
  components: { EventListItem, EmptyContent },
  data() {
    return {
      Moment,
    };
  },
  computed: {
    ...mapGetters([
      'tasksUpcoming', // List of all the interactions and reminders to come (or late)
    ]),
    eventsFutureGrouped() {
      // We group the events
      const sortedEvents = {};
      const now = new Date();
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      this.tasksUpcoming.forEach((event) => {
        // If the event is in the future
        if (event.date >= tomorrow) {
          // We prepare to add the event in the right "month" group
          // const month = `${event.date.getFullYear()}-${event.date.getMonth() + 1}-01`;
          const month = Moment(event.date).format('YYYY-MM');
          if (!sortedEvents[month]) {
            sortedEvents[month] = [];
          }
          // We add the event to the Array
          sortedEvents[month].push(event);
        // If the event is today
        } else if (event.date.toDateString() === now.toDateString()) {
          if (!sortedEvents.today) {
            sortedEvents.today = [];
          }
          sortedEvents.today.push(event);
        // Else the event is overdue
        } else {
          if (!sortedEvents.overdue) {
            sortedEvents.overdue = [];
          }
          sortedEvents.overdue.push(event);
        }
      });
      return sortedEvents;
    },
  },
  mounted() {
    Moment.locale(this.$i18n.locale);
  },
};
</script>

<style lang="less" scoped>
.list {
  margin: 0;
}
.list-group-title {
  text-transform: capitalize;
}
</style>

<i18n>
{
  "en": {
    "empty-title": "No interaction",
    "empty-text": "<p>But not for long.</p><p>Click the + button on the top-right<br />to create your first interaction!</p><p><em>You must <a href=\"/p/contact-new/\" title=\"New contact\">create a contact</a> before.</em></p>",
    "overdue": "Overdue",
    "today": "Today",
    "interactions": "Interactions"
  },
  "fr": {
    "empty-title": "Aucune interaction",
    "empty-text": "<p>Mais ça ne saurait tarder...</p><p>Cliquez sur le bouton + en haut à droite<br />pour créer votre première interaction !</p><p><em>Vous devez avoir <a href=\"/p/contact-new/\" title=\"Nouveau contact\">créé un contact</a> avant.</em></p>",
    "overdue": "En retard",
    "today": "Aujourd'hui",
    "interactions": "Interactions"
  }
}
</i18n>
