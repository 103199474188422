import { render, staticRenderFns } from "./account-management.vue?vue&type=template&id=7d4bc805&scoped=true&"
import script from "./account-management.vue?vue&type=script&lang=js&"
export * from "./account-management.vue?vue&type=script&lang=js&"
import style0 from "./account-management.vue?vue&type=style&index=0&id=7d4bc805&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d4bc805",
  null
  
)

/* custom blocks */
import block0 from "./account-management.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports