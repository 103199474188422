import DBEvent from './dbEvent.class';

export default class Interaction extends DBEvent {
  constructor(interaction = {}, id, hasPendingWrites) {
    super(interaction, id, hasPendingWrites);
    this.via = ['email', 'chat', 'call', 'meeting'].includes(interaction.via) ? interaction.via : 'meeting';
  }

  get type() {
    return 'interaction';
  }

  get isInteraction() { return true; }

  exportToDB(noUpdate) {
    const dbObject = super.exportToDB(noUpdate);
    dbObject.via = this.via;
    return dbObject;
  }
}
