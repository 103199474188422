/* eslint-disable class-methods-use-this */
import Metadata from './metadata.class';
import { supportedVersion } from './utils';

export default class DBObject {
  constructor(dbObject = {}, id = null, hasPendingWrites = false) {
    if (this.constructor === DBObject) {
      throw new TypeError('Abstract class "DBObject" cannot be instantiated directly');
    }
    this.id = id;
    this.hasPendingWrites = hasPendingWrites;
    this.notes = dbObject.notes || null;
    this.metadata = new Metadata(dbObject.metadata);
    this.v = dbObject.v || supportedVersion;
  }

  exportToDB(noUpdate = false) { // return the object to store in the DB
    return {
      metadata: this.metadata.exportToDB(noUpdate),
      hashtags: this.hashtags || [],
      mentions: this.mentions || [],
      notes: this.notes,
      v: supportedVersion,
    };
  }

  isReadyToExportToDB() { // return true if the object has the required attributes for saving
    throw new Error('You must implement this function');
  }

  exportToDBDeleted() {
    const metadata = this.exportToDB().metadata;
    metadata.deleted = true;
    return { metadata, v: supportedVersion };
  }

  get collection() { // return the name of the collection in which the object should be stored
    throw new Error('You must define the collection getter');
  }

  get type() { // return the type of the object
    throw new Error('You must define the type getter');
  }

  static sanitizeHtml(html) {
    if (!html) return null;
    const sanitizedHtml = html
      .replace(/[\n\r]/g, '') // Remove all line returns in the html
      .replace(/&nbsp;/gi, '\xA0') // Replace the html non breaking space by its unicode character (so it can be detected by the \s regex)
      .replace(
        /<a [^>]*data-mention="([a-zA-Z0-9]{20,40})"[^>]*>(@[^<]{1,100})<\/a>/g,
        (match, id, name) => `<mention:${id}>${name}</mention>`,
      ) // Replace mentions links by mentions tags
      .replace(/<(\/?[a-zA-Z0-9]+)(:[a-zA-Z0-9]+)?[^>]*>/g, (match, tag, id = '') => `<${tag.toLowerCase()}${id}>`) // Remove all tags' attributes (including self closing slash)
      .replace(/<strong>/g, '<b>') // We replace <strong> by <b>. Perf note: https://jsperf.com/2-replace-or-1-replace-with-capture/1
      .replace(/<\/strong>/g, '</b>') // We replace </strong> by </b>
      .replace(/<em>/g, '<i>') // We replace <em> by <i>
      .replace(/<\/em>/g, '</i>') // We replace </em> by </i>
      .replace(/<p>/g, '<div>') // We replace <p> by <div>
      .replace(/<\/p>/g, '</div>') // We replace </p> by </div>
      .replace(/(^|<\/div>)((?:(?!<\/?div>).)+)(<div>|$)/g, '$1<div>$2</div>$3') // Add <div> tags around text that is outside of a <div> tag
      .replace(/<div>[\t ]*<\/div>/g, '') // <div> containing only spaces and tabs collapse and can be removed
      .replace(/<br>\s*<\/div>/g, '</div>') // <br> before </div> are ignored by the browser. Remove them
      .replace(/<\/div>\s*<div>/g, '<br>') // Replace </div><div> by <br>
      .replace(/<\/?div>/g, '<br>') // Replace remaining <div> or </div> by <br>
      .replace(/(?!<\/?b>|<\/?i>|<\/?u>|<\/?strike>|<br>|<mention:[a-zA-Z0-9]{20,40}>|<\/mention>)<[^>]*>/g, '') // We remove all tags that are not i/b/u/strike/br/mention
      .replace(/(?:<br>|\s)+$/, '') // We delete the <br> and spaces at the end of the notes
      .replace(/^(?:<br>|\s)+/, '') // We delete the <br> and spaces at the beggining of the notes
      // .replace(/(?:(?:\s|<br>)+(<\/p>))?(?:<p>(?:\s|<br>)*<\/p>)*\s*$/, '$1') // Remove empty paragraphs and trailing spaces
      .replace(/\xA0/g, '&nbsp;'); // Replace back the non breaking space characters
    return sanitizedHtml || null; // If sanitizedHtml is an empty string, we return null
  }

  set notes(notes) {
    if (!notes) {
      this._notes = null;
      this._hashtags = [];
    } else {
      this._notes = DBObject.sanitizeHtml(notes); // We clean the notes
      this._hashtags = null; // We reinit the hashtags
      this._mentions = null; // We reinit the mentions
      this._notesHtml = null; // We reinit the html notes
      this._notesRaw = null; // We reinit the raw notes
      this._summary = null; // We reinit summary
    }
  }

  get notes() {
    return this._notes || null;
  }

  get notesHtml() {
    if (this._notesHtml) return this._notesHtml;
    if (!this._notes) this._notesHtml = null;
    else {
      this._notesHtml = this.notes
      // Replace mentions by links
        .replace(
          /<mention:([a-zA-Z0-9]{20,40})>(@[^<]{1,100})<\/mention>/g,
          (match, id, name) => `<span class="delete"><span contenteditable="false"><a href="/contact/${id}/" data-mention="${id}" class="mention">${name}</a></span></span>`,
        )
      // Replace hashtags by links
        .replace(
          /(^| |&nbsp;|<br>)#([a-zA-Z0-9-_]{1,40})/g,
          (match, space, hashtag) => `${space}<span class="delete"><span contenteditable="false"><a href="/p/hashtag-search/${hashtag}/" class="hashtag">#${hashtag}</a></span></span>`,
        );
      // We add a space at the end so we are sure to never end with an undeletable mention / hashtag
      this._notesHtml += '&nbsp;';
    }
    return this._notesHtml;
  }

  static html2text(html) {
    let txt = html.replace(/<br *\/?>/gi, '\n'); // Remove line return
    txt = txt.replace(/<[^>]+>/gi, ''); // Remove all the tags
    // If we are executed on a browser, we convert all the html entities
    if (typeof document === 'object') {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = txt; // Convert HTML entities
      return textarea.value;
    }
    // If on Node, we convert only the most common
    return txt
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, '\'')
      .replace(/&nbsp;/g, '\xA0');
  }

  get notesRaw() {
    if (this._notesRaw) return this._notesRaw;
    this._notesRaw = this._notes ? DBObject.html2text(this._notes) : null;
    return this._notesRaw;
  }

  get summary() {
    if (this._summary) return this._summary;
    const notesRaw = this.notesRaw;
    if (notesRaw) {
      const match = /^((.{3,50}?)(?:[.\n]|$))?([\s\S]*)/.exec(notesRaw);
      this._summary = {
        title: match[2],
        text: match[3].replace(/\n/gi, ' ').replace(/ +/gi, ' '),
      };
    } else {
      this._summary = {
        title: null,
        text: null,
      };
    }
    return this._summary;
  }

  get title() {
    return this.summary.title;
  }

  static extractHashtags(text) {
    if (!text) return [];
    let hashtags = [];
    // const matches = [...text.matchAll(/(?:^| |&nbsp;|<br>)#([a-zA-Z0-9-_]{1,40})/g)]; // does not work with nodejs :'(
    const hashtagRegexp = /(?:^| |&nbsp;|<br>)#([a-zA-Z0-9-_]{1,40})/;
    const matches = text.match(new RegExp(hashtagRegexp, 'g'));
    if (matches) {
      matches.forEach((match => hashtags.push(match.match(hashtagRegexp)[1])));
      hashtags = [...new Set(hashtags)]; // Deduplicate hashtags
    }
    return hashtags;
  }

  get hashtags() {
    if (this._hashtags) return this._hashtags;
    this._hashtags = DBObject.extractHashtags(this.notes);
    return this._hashtags;
  }

  static extractMentions(text) {
    if (!text) return [];
    let mentions = [];
    // const matches = [...text.matchAll(/(?:^| |&nbsp;|<br>)#([a-zA-Z0-9-_]{1,40})/g)]; // does not work with nodejs :'(
    const mentionRegexp = /<mention:([a-zA-Z0-9]{20,40})>(@[^<]{1,100})<\/mention>/;
    const matches = text.match(new RegExp(mentionRegexp, 'g'));
    if (matches) {
      matches.forEach((match => mentions.push(match.match(mentionRegexp)[1])));
      mentions = [...new Set(mentions)]; // Deduplicate mentions
    }
    return mentions;
  }

  get mentions() {
    if (this._mentions) return this._mentions;
    this._mentions = DBObject.extractMentions(this.notes);
    return this._mentions;
  }

  get hash() {
    return `${this.type}-${this.id}-${this.metadata.updated ? this.metadata.updated.getTime() : 'null'}`;
  }

  hasHashtag(hashtag) {
    return this.hashtags.indexOf(hashtag) !== -1;
  }

  addHashtag(hashtag) {
    // We add a space before if the notes were not empty)
    this.notes = `${this.notes ? `${this.notes} ` : ''}#${hashtag}`;
  }

  removeHashtag(hashtag) {
    this.notes = this.notes.replace(new RegExp(` ?#${hashtag} ?`, 'g'), ' ');
  }
}
