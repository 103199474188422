import DBObject from './dbOject.class';
import { makeDate } from './utils';

export default class DBEvent extends DBObject {
  constructor(event = {}, id, hasPendingWrites) {
    super(event, id, hasPendingWrites);
    if (this.constructor === DBEvent) {
      throw new TypeError('Abstract class "DBEvent" cannot be instantiated directly');
    }
    const now = new Date();
    this.date = makeDate(event.date) || now;
    this.contacts = event.contacts || [];
    this.completed = typeof event.completed === 'boolean' ? event.completed : this.date <= now;
  }

  exportToDB(noUpdate) {
    const dbObject = super.exportToDB(noUpdate);
    dbObject.date = this.date;
    dbObject.type = this.type;
    dbObject.contacts = this.contacts;
    dbObject.completed = !!this.completed;
    return dbObject;
  }

  isReadyToExportToDB() {
    const event = this.exportToDB();
    return (event.date && event.type && event.contacts.length);
  }

  get collection() {
    return 'events';
  }

  get type() {
    throw new Error('You must implement this getters');
  }

  get isInteraction() { return false; }

  get isMemo() { return false; }

  get isReminder() { return false; }

  get isMention() { return false; }

  get mentions() { // Filter mentions to return only mentions of contacts not affected to the event
    if (this._mentions) return this._mentions;
    const mentions = super.mentions;
    this._mentions = [];
    mentions.forEach((mention) => {
      if (!this.contacts.includes(mention)) this._mentions.push(mention);
    });
    return this._mentions;
  }

  // getter, setter and dateInput are all necessary for the date inputs
  // If date inputs are replaced by calendars, then we will be able to remove them

  get date() {
    return this._date;
  }

  set date(v) {
    this._date = makeDate(v);
  }
}
