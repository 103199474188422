// Import Vue
import Vue from 'vue';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';

// Import Framework7
import Framework7 from './framework7-custom';

// Import Framework7 Styles
import '../css/framework7-custom.less';

// Import Vue-i18n Plugin
import i18n from './i18n';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';

// Import Firebase
import firebase from './firebase-config';

// Import Store
import store from './store';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Global error handler
Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  console.error('GLOBAL ERROR HANDLED', err, vm, info);
  // Create toast with custom button text
  vm.$root.$f7.toast.show({
    text: vm.$root.$t('common.alert-error', { err: err.message }),
    closeButton: true,
    closeButtonColor: 'red',
    position: 'top',
  });
};

// Handle page reload
let app;
// Init App once firebase auth is loaded
firebase.auth().onAuthStateChanged(() => {
  console.log('onAuthStateChanged app.js');
  if (!app) {
    app = new Vue({
      el: '#app',
      // Register App Component
      components: {
        app: App,
      },
      render: h => h(App),
      i18n,
      store,
    });
  }
});

// Test connexion when app reopens
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    console.log('App went to foreground');
    store.dispatch('testConnection');
  }
});
