<template>
  <f7-list-item
    ref="eventListItem"
    :link="link"
    :title="title"
    :after="after"
    :subtitle="subtitle"
    :text="text"
    :style="style"
    v-bind="$attrs"
    swipeout
    class="event-list-item"
  >
    <template v-if="loading">
      <f7-skeleton-block
        v-if="loading"
        slot="media"
        style="width: 28px; height: 28px; border-radius: 50%"
      />
    </template>
    <template v-else>
      <event-icon slot="media" :event="event" color="gray" />
      <f7-swipeout-actions v-if="event.isReminder" left>
        <f7-swipeout-button v-if="postponable" color="orange" close @click="postpone(event, hideItem)">
          {{ $t('action.postpone') }}
        </f7-swipeout-button>
        <f7-swipeout-button color="blue" close @click="freezeContact(event, hideItem)">
          {{ $t('action.freeze') }}
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions v-else left>
        <f7-swipeout-button v-if="postponable" color="orange" close @click="postpone(event, hideItem)">
          {{ $t('action.postpone') }}
        </f7-swipeout-button>
        <f7-swipeout-button color="red" close @click="deleteEvent(event, hideItem)">
          {{ $t('action.delete') }}
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions v-if="event.isReminder" right>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+event.contactId+'&type=email'">
          <event-icon icon="email" />
        </f7-swipeout-button>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+event.contactId+'&type=chat'">
          <event-icon icon="chat" />
        </f7-swipeout-button>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+event.contactId+'&type=call'">
          <event-icon icon="call" />
        </f7-swipeout-button>
        <f7-swipeout-button color="green" :href="'/p/event-new/?contact='+event.contactId+'&type=meeting'">
          <event-icon icon="meeting" />
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions v-else right>
        <f7-swipeout-button color="green" close @click="toggleCompleted(event, hideItem)" v-html="markCompleted" />
      </f7-swipeout-actions>
    </template>
  </f7-list-item>
</template>

<script>
import Moment from 'moment';
import Interaction from '../class/interaction.class';
import EventIcon from './event-icon.vue';
import DBEvent from '../class/dbEvent.class';
import MixinEvent from '../mixins/event';

export default {
  components: { EventIcon },
  mixins: [MixinEvent],
  props: {
    event: {
      type: DBEvent,
      default: new Interaction(),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    backLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      link: false,
      title: null,
      after: null,
      subtitle: null,
      text: null,
      style: false,
      markCompleted: null,
      postponable: false,
    };
  },
  mounted() {
    if (this.loading) {
      this.title = 'The contact name';
      this.after = '01/01/99';
      this.subtitle = 'Interaction Title';
      this.text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque varius velit ex, vel posuere erat pulvinar ut.';
    } else {
      const todayDate = new Date().toDateString();
      const today = new Date(todayDate);
      const isToday = this.event.date.toDateString() === todayDate;
      const isOverdue = this.event.date < today;
      const firstContact = this.$store.getters.contactById(this.event.contacts[0]);
      this.postponable = !this.event.completed && (isOverdue || isToday);
      if (this.event.isReminder) {
        this.link = `/contact/${this.event.contacts[0]}/`;
        this.title = firstContact ? firstContact.name : 'Error';
        this.after = isToday
          ? this.$t('today')
          : Moment(this.event.date).fromNow();
        this.style = isOverdue || isToday
          ? null
          : 'opacity: 0.5;';
      } else {
        this.link = `/event/${this.event.id}/`;
        if (this.event.contacts.length === 1) {
          this.title = firstContact ? firstContact.name : 'Error';
        } else this.title = `${this.event.contacts.length} contacts`;
        if (isOverdue) this.after = this.event.completed ? Moment(this.event.date).format('L') : Moment(this.event.date).fromNow();
        else this.after = Moment(this.event.date).format('DD MMM');
        this.subtitle = this.event.summary.title;
        this.text = this.event.summary.text;
        if (this.event.completed) this.markCompleted = this.$t('action.uncomplete');
        else this.markCompleted = this.event.date <= new Date() ? this.$t('action.complete') : this.$t('complete-today');
      }
      if (this.backLink) this.link += `?back=${this.backLink}`;
    }
  },
  methods: {
    swipeoutDelete() {
      if (this.event.isReminder) { return this.freezeContact(this.event); }
      return this.deleteEvent(this.event);
    },
    hideItem() {
      return new Promise((resolve) => {
        this.$f7.swipeout.delete(this.$el, () => resolve());
      });
    },
  },
};
</script>

<style lang="less">
.event-list-item .item-inner .item-text {
  white-space: nowrap;
  height: 21px;
  -webkit-line-clamp: 1;
}
</style>

<i18n>
{
  "en": {
    "today": "Today",
    "complete-today": "Completed<br>Today"
  },
  "fr": {
    "today": "Aujourd'hui",
    "complete-today": "Complétée<br>Aujourd'hui"
  }
}
</i18n>
