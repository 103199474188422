<template>
  <empty-content
    v-if="eventsCompleted.length === 0"
    :title="$t('empty-title')"
    :text="$t('empty-text')"
  />
  <f7-list
    v-else
    ref="events"
    media-list
    virtual-list
    :virtual-list-params="{ items: eventsCompleted, renderExternal, height: itemHeight }"
  >
    <ul>
      <event-list-item
        v-for="event in vlData.items"
        :key="event.hash"
        :event="event"
        :style="`top: ${vlData.topPosition}px`"
        :back-link="$t('interactions')"
      />
    </ul>
  </f7-list>
</template>

<script>
import { mapGetters } from 'vuex';
import EventListItem from './event-list-item.vue';
import EmptyContent from './empty-content.vue';

export default {
  components: { EventListItem, EmptyContent },
  data() {
    return {
      vlData: {
        items: [],
      },
      previousPos: 0,
    };
  },
  computed: {
    ...mapGetters([
      'eventsCompleted',
    ]),
  },
  /*
  watch: {
    eventsCompleted(newVal, beforeVal) {
      console.log()
      if (!beforeVal) this.$nextTick(() => this.$refs.events.f7VirtualList.update());
      else this.$refs.events.f7VirtualList.update();
    },
  }, */
  watch: {
    eventsCompleted() {
      this.updateVirtualList();
    },
  },
  methods: {
    renderExternal(vl, vlData) {
      if (vlData.items.length) {
        // We emit the top and bottom visible dates (technically, they are separated by 2 times the height of the screen)
        const goingDown = vlData.topPosition >= this.previousPos;
        const itemLenghts = vlData.items.length;
        const topVisibleItemIndex = goingDown ? Math.floor(itemLenghts * 1 / 2) : Math.floor(itemLenghts * 1 / 4);
        const bottomVisibleItemIndex = goingDown ? itemLenghts - 1 : Math.floor(itemLenghts * 3 / 4);
        const topVisibleItemDate = vlData.items[topVisibleItemIndex].date;
        const bottomVisibleItemDate = vlData.items[bottomVisibleItemIndex].date;
        this.previousPos = vlData.topPosition;
        this.$emit('dates', { from: topVisibleItemDate, to: bottomVisibleItemDate });
      }
      this.vlData = vlData;
    },
    itemHeight(event) {
      if (event.summary.title && event.summary.text) {
        return this.$theme.ios ? 85 : 94;
      } if (event.summary.title) {
        return this.$theme.ios ? 64 : 73;
      } if (event.summary.text) {
        return this.$theme.ios ? 64 : 73;
      }
      return this.$theme.ios ? 48 : 52;
    },
    updateVirtualList() {
      if (this.$refs.events && this.$refs.events.f7VirtualList) {
        this.$refs.events.f7VirtualList.replaceAllItems(this.eventsCompleted);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  margin: 0;
}
</style>

<i18n>
{
  "en": {
    "empty-title": "No interaction",
    "empty-text": "<p>You do not have completed any interaction.</p><p>Click on the + button in the upper right corner<br />to log an interaction with<br />one of your contacts.</p>",
    "interactions": "Interactions"
  },
  "fr": {
    "empty-title": "Aucune interaction",
    "empty-text": "<p>Vous n'avez complété aucune interaction.</p><p>Cliquez sur le bouton + en haut à droite<br />pour enregistrer une interaction<br />avec l'un de vos contacts.</p>",
    "interactions": "Interactions"
  }
}
</i18n>
