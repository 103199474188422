<template>
  <f7-page name="hashtag-search">
    <f7-navbar>
      <f7-nav-title>#{{ hashtag }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <search-results :search-results="searchResults" :search-loading="searchLoading" />
  </f7-page>
</template>

<script>
import Fuse from 'fuse.js';
import { mapGetters } from 'vuex';
import SearchResults from '../components/search-results.vue';

export default {
  components: {
    SearchResults,
  },
  data() {
    return {
      hashtag: this.$f7route.params.hashtag,
      searchLoading: true,
      searchResults: [],
    };
  },
  computed: {
    ...mapGetters([
      'interactions',
      'contacts',
    ]),
  },
  mounted() {
    const options = {
      threshold: 0,
      useExtendedSearch: true,
      keys: [
        'hashtags',
      ],
    };
    const objects = [...this.interactions, ...this.contacts];
    const fuse = new Fuse(objects, options);
    this.searchResults = fuse.search(`'${this.hashtag}`).slice(0, 100);
    this.searchLoading = false;
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Hashtags",
    "no-hashtags": "<p>You haven't used hashtags yet.</p><p>Hashtags can be added in any note, whether it is a Contact or an Interaction, and are a powerful way to organize your Kipinto!</p><p><em>#hashtags are made of a hash followed by simple alphanumeric characters, dashes or underscores.</em></p>"
  },
  "fr": {
    "title": "Hashtags",
    "no-hashtags": "<p>Vous n'avez pas utilisé d'hashtags pour le moment.</p><p>Les hashtags peuvent être ajoutés dans n'importe quelle note, qu'il s'agisse d'un Contact ou d'une Interaction. C'est une manière efficace d'organiser votre Kipinto !</p><p><em>Les #hashtags sont formé d'un dièse suivi d'un mélange de caractères alpha numériques simples (sans accents), de traits d'union et d'underscore.</em></p>"
  }
}
</i18n>
