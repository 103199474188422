import Moment from 'moment';
import DBObject from './dbOject.class';
import Reminder from './reminder.class';
import { makeDate } from './utils';

export default class Contact extends DBObject {
  constructor(contact = {}, id, hasPendingWrites) {
    super(contact, id, hasPendingWrites);
    this.firstname = contact.firstname || null;
    this.lastname = contact.lastname || null;
    this.company = contact.company || null;
    this.email = contact.email || null;
    this.phone = contact.phone || null;
    this.social = new ContactSocial(contact.social);
    this.frequency = contact.frequency;
    this.postponed = makeDate(contact.postponed);
    this.lastInteraction = contact.lastInteraction ? {
      date: makeDate(contact.lastInteraction.date),
      id: contact.lastInteraction.id,
    } : null;
  }

  exportToDB(noUpdate) {
    const dbObject = super.exportToDB(noUpdate);
    dbObject.firstname = this.firstname;
    dbObject.lastname = this.lastname;
    dbObject.company = this.company;
    dbObject.email = this.email;
    dbObject.phone = this.phone;
    dbObject.social = this.social.exportToDB();
    dbObject.frequency = this.frequency;
    dbObject.postponed = this.postponed;
    dbObject.lastInteraction = this.lastInteraction;
    return dbObject;
  }

  isReadyToExportToDB() {
    const contact = this.exportToDB();
    return (contact.name || contact.firstname);
  }

  get collection() {
    return 'contacts';
  }

  get type() {
    return 'contact';
  }

  get name() {
    return `${this.firstname || ''} ${this.lastname || ''}`;
  }

  static normalizeFrequency(v) {
    const freq = parseInt(v, 10);
    if (Number.isNaN(freq)) return 0;
    if (freq <= 0) return 0;
    if (freq <= 1) return 1;
    if (freq <= 10) return 7;
    if (freq <= 20) return 15;
    if (freq <= 50) return 30;
    if (freq <= 100) return 90;
    if (freq <= 200) return 180;
    return 365;
  }

  set frequency(v) {
    this._frequency = Contact.normalizeFrequency(v);
  }

  get frequency() {
    return this._frequency;
  }

  set frequencyText(v) {
    if (v === '1days') this.frequency = 1;
    else if (v === '7days') this.frequency = 7;
    else if (v === '15days') this.frequency = 15;
    else if (v === '30days') this.frequency = 30;
    else if (v === '90days') this.frequency = 90;
    else if (v === '180days') this.frequency = 180;
    else if (v === '365days') this.frequency = 365;
    else this.frequency = 0;
  }

  get frequencyText() {
    this.frequency = parseInt(this.frequency, 10);
    if (Number.isNaN(this.frequency)) return '0days';
    if (this.frequency <= 0) return '0days';
    if (this.frequency <= 1) return '1days';
    if (this.frequency <= 10) return '7days';
    if (this.frequency <= 20) return '15days';
    if (this.frequency <= 50) return '30days';
    if (this.frequency <= 100) return '90days';
    if (this.frequency <= 200) return '180days';
    return '365days';
  }

  get frozen() {
    return !this.frequency;
  }

  futureInteractionReminder() {
    if (this._reminder && this._reminder.date.getTime() === this.futureInteractionDate.getTime()) return this._reminder;
    this._reminder = this.frozen ? null : new Reminder(this);
    return this._reminder;
  }

  get futureInteractionDate() {
    if (this.frozen) return null;
    const lastInteractionDate = this.lastInteraction ? new Date(this.lastInteraction.date) : null;
    const futureInteractionDate = lastInteractionDate
      ? new Date(lastInteractionDate.setDate(lastInteractionDate.getDate() + this.frequency))
      : new Date();
    if (this.postponed && this.postponed > futureInteractionDate) return this.postponed;
    return futureInteractionDate;
  }

  get isPostponed() {
    return this.futureInteractionDate && this.postponed && this.futureInteractionDate.getTime() === this.postponed.getTime();
  }

  get status() {
    if (!this.frequency) return 'frozen';
    if (!this.lastInteraction) return 'warning';
    const interval = Math.abs(Moment().diff(this.lastInteraction.date, 'days'));
    const healthiness = interval / this.frequency;
    if (healthiness > 1.1) return 'danger';
    if (healthiness < 0.9) return 'good';
    return 'warning';
  }

  get statusColorHex() {
    switch (this.status) {
      case 'good': return '#4caf50';
      case 'warning': return '#ff9800';
      case 'danger': return '#f44336';
      default: return 'gray';
    }
  }

  get statusColor() {
    switch (this.status) {
      case 'good': return 'green';
      case 'warning': return 'orange';
      case 'danger': return 'red';
      default: return 'gray';
    }
  }
}

class ContactSocial {
  constructor(contactSocialFromDB) {
    this.loadFromDB(contactSocialFromDB);
  }

  loadFromDB(contactSocial = {}) {
    this.linkedin = contactSocial.linkedin || null;
    this.linkedinForm = this.linkedin;
    this.facebook = contactSocial.facebook || null;
    this.facebookForm = this.facebook;
    this.twitter = contactSocial.twitter || null;
    this.twitterForm = this.twitter;
  }

  exportToDB() {
    return {
      linkedin: this.linkedin,
      facebook: this.facebook,
      twitter: this.twitter,
    };
  }

  set linkedinForm(v) {
    this._linkedinForm = v;
    if (v) {
      const regexLinkedin = /linkedin\.com\/in\/[a-zA-Z0-9_.-]{5,50}/;
      const match = regexLinkedin.exec(v.toLowerCase());
      if (match) {
        this.linkedin = match[0];
      } else {
        this.linkedin = null;
      }
    }
  }

  get linkedinForm() {
    return this._linkedinForm;
  }

  set facebookForm(v) {
    this._facebookForm = v;
    if (v) {
      const regexFacebook = /facebook\.com\/[a-zA-Z0-9_.-]{5,}/;
      const match = regexFacebook.exec(v.toLowerCase());
      if (match) {
        this.facebook = match[0];
      } else {
        this.facebook = null;
      }
    }
  }

  get facebookForm() {
    return this._facebookForm;
  }

  set twitterForm(v) {
    this._twitterForm = v;
    if (v) {
      const regexTwitter = /twitter\.com\/[a-zA-Z0-9_.-]{1,15}/;
      const match = regexTwitter.exec(v.toLowerCase());
      if (match) {
        this.twitter = match[0];
      } else {
        this.twitter = null;
      }
    }
  }

  get twitterForm() {
    return this._twitterForm;
  }
}
