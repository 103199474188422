<template>
  <f7-list
    accordion-list
    class="socials"
    v-bind="$attrs"
  >
    <f7-accordion-item>
      <f7-row class="socials-shortcuts" no-gutter>
        <f7-col class="socials-toggle-col" />
        <f7-col>
          <f7-link v-if="contact.email" external @click="open('mailto:'+contact.email, $event, 'email')">
            <f7-icon f7="at_circle_fill" />
          </f7-link>
          <f7-icon v-else f7="at_circle_fill" class="disabled" color="primary" />
        </f7-col>
        <f7-col>
          <f7-link v-if="phoneCleaned" external @click="open('tel:'+phoneCleaned, $event, 'call')">
            <f7-icon f7="phone_circle_fill" />
          </f7-link>
          <f7-icon v-else f7="phone_circle_fill" class="disabled" color="primary" />
        </f7-col>
        <f7-col>
          <f7-link v-if="phoneCleaned" external @click="open('sms:'+phoneCleaned, $event, 'chat')">
            <f7-icon f7="chat_bubble_text_fill" />
          </f7-link>
          <f7-icon v-else f7="chat_bubble_text_fill" class="disabled" color="primary" />
        </f7-col>
        <f7-col>
          <f7-link v-if="contact.social.linkedin" :href="'https://www.'+contact.social.linkedin" external target="_blank">
            <f7-icon f7="logo_linkedin" />
          </f7-link>
          <f7-icon v-else f7="logo_linkedin" class="disabled" color="primary" />
        </f7-col>
        <f7-col>
          <f7-link v-if="contact.social.facebook" :href="'https://www.'+contact.social.facebook" external target="_blank">
            <f7-icon f7="logo_facebook" />
          </f7-link>
          <f7-icon v-else f7="logo_facebook" class="disabled" color="primary" />
        </f7-col>
        <f7-col>
          <f7-link v-if="contact.social.twitter" :href="'https://'+contact.social.twitter" external target="_blank">
            <f7-icon f7="logo_twitter" />
          </f7-link>
          <f7-icon v-else f7="logo_twitter" class="disabled" color="primary" />
        </f7-col>
        <f7-col class="socials-toggle-col">
          <f7-accordion-toggle
            v-if="contact.email
              || phoneCleaned
              || contact.social.linkedin
              || contact.social.facebook
              || contact.social.twitter"
          >
            <div class="item-inner" />
          </f7-accordion-toggle>
        </f7-col>
      </f7-row>
      <f7-accordion-content>
        <f7-list class="socials-details">
          <f7-list-item
            v-if="contact.email"
            :title="contact.email"
            link
            external
            @click="open('mailto:'+contact.email, $event, 'email')"
          >
            <f7-icon slot="media" f7="at_circle_fill" color="gray" />
          </f7-list-item>
          <f7-list-item
            v-if="phoneCleaned"
            :title="contact.phone"
            link
            external
            @click="open('tel:'+phoneCleaned, $event, 'call')"
          >
            <f7-icon slot="media" f7="phone_circle_fill" color="gray" />
          </f7-list-item>
          <f7-list-item
            v-if="phoneCleaned"
            :title="contact.phone"
            link
            external
            @click="open('sms:'+phoneCleaned, $event, 'sms')"
          >
            <f7-icon slot="media" f7="chat_bubble_text_fill" color="gray" />
          </f7-list-item>
          <f7-list-item
            v-if="contact.social.linkedin"
            :link="'https://www.'+contact.social.linkedin"
            external
            :title="contact.social.linkedin"
            target="_blank"
          >
            <f7-icon slot="media" f7="logo_linkedin" color="gray" />
          </f7-list-item>
          <f7-list-item
            v-if="contact.social.facebook"
            :link="'https://www.'+contact.social.facebook"
            external
            :title="contact.social.facebook"
            target="_blank"
          >
            <f7-icon slot="media" f7="logo_facebook" color="gray" />
          </f7-list-item>
          <f7-list-item
            v-if="contact.social.twitter"
            :link="'https://'+contact.social.twitter"
            external
            :title="contact.social.twitter"
            target="_blank"
          >
            <f7-icon slot="media" f7="logo_twitter" color="gray" />
          </f7-list-item>
        </f7-list>
      </f7-accordion-content>
    </f7-accordion-item>
  </f7-list>
</template>

<script>
import Contact from '../class/contact.class';

export default {
  props: {
    contact: {
      type: Contact,
      default: new Contact(),
    },
  },
  data() {
    return { };
  },
  computed: {
    phoneCleaned() {
      // For iPhone, we clean special characters off the phone
      if (!this.contact.phone) return null;
      return this.contact.phone.replace(/^\+/, '00').replace(/[^0-9]/g, '');
    },
  },
  methods: {
    open(link, event, via) {
      const isCordova = this.$f7.device.cordova;
      const open = isCordova ? window.cordova.InAppBrowser.open : window.open;
      event.preventDefault();
      if (via) this.$f7router.navigate(`/p/event-new/?contact=${this.contact.id}&type=${via}`);
      open(link, isCordova ? '_system' : '_self');
    },
  },
};
</script>

<style lang="less" scoped>
.socials {
    margin: 0 -15px;

  .socials-shortcuts {
    text-align: center;
    align-items: center;
    min-height: var(--f7-list-item-min-height);
  }
  .socials-toggle-col {
    width: 35px !important;
  }
  .socials-shortcuts .accordion-item-toggle .item-inner::after {
    height: 0px;
  }
}
</style>

<i18n>
{
  "en": { },
  "fr": { }
}
</i18n>
