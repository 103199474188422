<template>
  <f7-swiper-slide>
    <div class="slide-bg">
      <img :src="'static/images/intro/step-'+step+'.svg'">
    </div>
    <div class="overlay">
      <div class="overlay-container">
        <div v-if="title" class="title" data-swiper-parallax="-300">
          {{ title }}
        </div>
        <div v-if="subtitle" class="subtitle" data-swiper-parallax="-200">
          {{ subtitle }}
        </div>
        <div v-if="text || $slots.text" class="text" data-swiper-parallax="-100">
          <slot name="text">
            {{ text }}
          </slot>
        </div>
        <slot />
      </div>
    </div>
  </f7-swiper-slide>
</template>

<script>

export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return { };
  },
  computed: { },
};
</script>

<i18n>
{
  "en": { },
  "fr": { }
}
</i18n>
