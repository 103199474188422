<template>
  <f7-swiper-slide>
    <template v-if="todoContacts.length">
      <div class="slide-scrollable-container">
        <f7-block data-swiper-parallax="-300">
          <p>{{ $t('reminders-count', { n: todoContacts.length }) }}</p>
        </f7-block>
        <f7-block-title data-swiper-parallax="-200">
          {{ $t('reminders-spread') }}
        </f7-block-title>
        <f7-list inset data-swiper-parallax="-100">
          <f7-list-item link @click="spreadOver(7)">
            {{ $t('reminders-spread-week') }}
          </f7-list-item>
          <f7-list-item link @click="spreadOver(30)">
            {{ $t('reminders-spread-month') }}
          </f7-list-item>
          <f7-list-item link @click="spreadOver(90)">
            {{ $t('reminders-spread-trimester') }}
          </f7-list-item>
          <f7-list-item link @click="spreadOver(180)">
            {{ $t('reminders-spread-semester') }}
          </f7-list-item>
          <f7-list-item link @click="spreadOver(365)">
            {{ $t('reminders-spread-year') }}
          </f7-list-item>
        </f7-list>
        <f7-block-title data-swiper-parallax="-200">
          {{ $t('reminders-do-not-spread') }}
        </f7-block-title>
        <f7-list inset data-swiper-parallax="-100">
          <f7-list-item link @click="spreadOver(0)">
            {{ $t('reminders-spread-none') }}
          </f7-list-item>
        </f7-list>
      </div>
    </template>
    <div v-else class="overlay">
      <div class="overlay-container">
        <p data-swiper-parallax="-300">
          <strong>{{ $t('no-reminders') }}</strong>
        </p>
        <p data-swiper-parallax="-200">
          {{ $t('no-help') }}
        </p>
        <p data-swiper-parallax="-100">
          <f7-button fill large round @click="$emit('spreaded', null)">
            {{ $t('confirm-no-reminders') }}
          </f7-button>
        </p>
      </div>
    </div>
  </f7-swiper-slide>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return { };
  },
  computed: {
    ...mapGetters([
      'contacts',
    ]),
    todoContacts() {
      // The todo contacts are all thos who have reminders
      return this.contacts.filter(contact => contact.frequency
        // && (never had interaction || are postponed || are late)
        && (!contact.lastInteraction || contact.postponed > Date.now() || contact.futureInteractionDate < Date.now()));
    },
  },
  methods: {
    spreadOver(duration) {
      this.$store.dispatch('spreadReminders', { contacts: this.todoContacts, duration });
      this.$emit('spreaded', !!duration);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "reminders-count": "You have defined {n} reminders and therefore you will find in your dashboard {n} tasks to be processed.",
    "reminders-spread": "You want to spread these reminders:",
    "reminders-do-not-spread": "You want to process them without waiting:",
    "reminders-spread-week": "Spread over 1 week",
    "reminders-spread-month": "Spread over 1 month",
    "reminders-spread-trimester": "Spread over 3 months",
    "reminders-spread-semester": "Spread over 6 months",
    "reminders-spread-year": "Spread over 1 year",
    "reminders-spread-none": "No need to spread",
    "no-reminders": "You have not set any reminders.",
    "no-help": "Kipinto will not be able to help you maintain regular interactions.",
    "confirm-no-reminders": "I don't want any reminders"
  },
  "fr": {
    "reminders-count": "Vous avez défini {n} rappels et vous trouverez donc dans votre tableau de bord {n} tâches à traiter.",
    "reminders-spread": "Vous souhaitez étaler ces rappels :",
    "reminders-do-not-spread": "Vous souhaitez les traiter sans attendre :",
    "reminders-spread-week": "Etaler sur 1 semaine",
    "reminders-spread-month": "Etaler sur 1 mois",
    "reminders-spread-trimester": "Etaler sur 3 mois",
    "reminders-spread-semester": "Etaler sur 6 mois",
    "reminders-spread-year": "Etaler sur 1 an",
    "reminders-spread-none": "Pas besoin d'étaler",
    "no-reminders": "Vous n'avez défini aucun rappel.",
    "no-help": "Kipinto ne pourra donc pas vous aider à maintenir des interactions régulières.",
    "confirm-no-reminders": "Je ne veux pas de rappels"
   }
}
</i18n>
