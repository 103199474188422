<template>
  <f7-sheet
    push
    backdrop
    v-bind="$attrs"
    class="sheet-hashtags"
    @sheet:closed="$emit('sheet:closed', $event)"
  >
    <f7-toolbar>
      <div>
        <f7-link sheet-close>
          {{ $t('common.cancel') }}
        </f7-link>
      </div>
      <div>{{ $tc('{n}-contacts-selected', contactsSelected.length) }}</div>
      <div>
        <f7-link sheet-close @click="applyHashtags">
          {{ $t('common.apply') }}
        </f7-link>
      </div>
    </f7-toolbar>
    <f7-backdrop slot="fixed" class="backdrop-seach-hashtags" />
    <f7-page-content>
      <f7-searchbar
        search-container=".hashtags-list"
        search-in=".item-title"
        backdrop-el=".backdrop-seach-hashtags"
        :disable-button-text="$t('common.cancel')"
        :placeholder="$t('common.search')"
      />
      <f7-list ref="hashtagsList" class="hashtags-list">
        <f7-list-item
          v-for="hashtag in hashtagsList"
          :key="hashtag"
          checkbox
          class="hashtag-checkbox"
          name="hashtags-checkboxes"
          :value="hashtag"
          :title="'#'+hashtag"
          :checked="hashtagCheckbox(hashtag) === 'checked'"
          :indeterminate="hashtagCheckbox(hashtag) === 'indeterminate'"
        />
        <f7-list-button :title="$t('new-hashtag')" class="searchbar-ignore" @click="addHashtag" />
      </f7-list>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    contactsSelectedId: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultHashtags: [
        this.$t('colleague'),
        this.$t('client'),
        this.$t('prospect'),
        this.$t('sendGreetings'),
      ],
    };
  },
  computed: {
    ...mapGetters([
      'hashtags',
    ]),
    hashtagsList() {
      // We initialize the array of hashtags listed based on the hashtags used by the user
      const hashtags = this.hashtags;
      // We add the default hashtags that are not already in the list
      for (const hashtag of this.defaultHashtags) {
        if (hashtags.indexOf(hashtag) === -1) {
          hashtags.push(hashtag);
        }
      }
      // We sort
      hashtags.sort((a, b) => a.localeCompare(b));
      // We return the list
      return hashtags;
    },
    contactsSelected() {
      const contactsSelected = [];
      for (const contactId of this.contactsSelectedId) {
        const contact = this.$store.getters.contactById(contactId);
        if (contact) contactsSelected.push(contact);
      }
      return contactsSelected;
    },
  },
  methods: {
    hashtagCheckbox(hashtag) {
      let present = false;
      let absent = false;
      for (const contact of this.contactsSelected) {
        if (contact.hashtags.indexOf(hashtag) === -1) {
          absent = true;
        } else {
          present = true;
        }
      }
      if (present && absent) return 'indeterminate';
      if (present) return 'checked';
      return 'unchecked';
    },
    applyHashtags() {
      const hashtagsCheckboxes = this.$refs.hashtagsList.$el.querySelectorAll('[name=hashtags-checkboxes]');
      const updatedContacts = [];
      for (const contact of this.contactsSelected) {
        let hashtagsModified = false;
        for (const hashtagCheckbox of hashtagsCheckboxes) {
          // If the hashtag is not indeterminate
          if (!hashtagCheckbox.indeterminate) {
            // If the hashtag is checked and the contact does not already have it
            if (hashtagCheckbox.checked && !contact.hasHashtag(hashtagCheckbox.value)) {
              hashtagsModified = true;
              contact.addHashtag(hashtagCheckbox.value);
            // If the hashtag is not checked and the contact already has it
            } else if (!hashtagCheckbox.checked && contact.hasHashtag(hashtagCheckbox.value)) {
              hashtagsModified = true;
              contact.removeHashtag(hashtagCheckbox.value);
            }
          }
        }
        // If hashtags have been modified, we add the contact to the list of updated contacts
        if (hashtagsModified) {
          updatedContacts.push(contact);
        }
      }
      // We save the new notes
      if (updatedContacts.length) this.$store.dispatch('batchUpdateNotes', updatedContacts);
    },
    addHashtag() {
      this.$f7.dialog.prompt(this.$t('new-hashtag-prompt'), this.$t('new-hashtag'), async (hashtag) => {
        if (/^#?[a-zA-Z0-9-_]{1,40}$/.test(hashtag)) {
          // eslint-disable-next-line no-param-reassign
          if (hashtag.substring(0, 1) === '#') hashtag = hashtag.substring(1);
          // If the hashtag does not already exist
          if (this.defaultHashtags.indexOf(hashtag) === -1) {
            this.defaultHashtags.push(hashtag);
          }
          // We select it
          await this.$nextTick();
          this.$refs.hashtagsList.$el.querySelector(`[name=hashtags-checkboxes][value=${hashtag}]`).checked = true;
        } else {
          this.$f7.dialog.alert(this.$t('new-hashtag-error'));
        }
      }, undefined, this.$el.querySelector('.searchbar input').value);
    },
  },
};
</script>

<style lang="less">
.sheet-modal.sheet-hashtags {
  .list {
    margin: 0;
  }
  .searchbar {
    overflow: hidden;
  }
}
</style>

<i18n>
{
  "en": {
    "hashtags": "Hashtags",
    "new-hashtag": "New hashtag",
    "new-hashtag-prompt": "Allowed characters: unaccented letters, numbers, hyphens, underscore. Limited to 40 characters.",
    "new-hashtag-error": "A hashtag must contain only unaccented characters, numbers, hyphens, underscore and be less than 40 characters long.",
    "{n}-contacts-selected": "No contact selected | 1 contact selected | {n} contacts selected",
    "colleague": "colleague",
    "client": "client",
    "prospect": "prospect",
    "sendGreetings": "sendGreetings"
   },
  "fr": {
    "hashtags": "Hashtags",
    "new-hashtag": "Nouveau hashtag",
    "new-hashtag-prompt": "Caractères autorisés : lettres non accentuées, chiffres, trait d'union, underscore. Limité à 40 caractères.",
    "new-hashtag-error": "Un hashtag ne doit contenir que des caractères non accentués, des chiffres, des traits d'unions, des underscore et faire moins de 40 caractères",
    "{n}-contacts-selected": "Aucun contact sélectionné | 1 contact sélectionné | {n} contacts sélectionnés",
    "colleague": "collegue",
    "client": "client",
    "prospect": "prospect",
    "sendGreetings": "envoyerVoeux"
  }
}
</i18n>
