<template>
  <f7-page name="onboarding" class="page-onboarding">
    <f7-navbar>
      <f7-nav-left>
        <f7-link v-if="prevVisible" icon="icon-back" @click="prev" />
      </f7-nav-left>
      <f7-nav-title>{{ title }}</f7-nav-title>
      <f7-nav-right>
        <f7-link v-if="nextVisible" :class="{ disabled: nextDisabled }" @click="next">
          <span>{{ $t('common.next') }}</span> <f7-icon icon="icon-forward" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-swiper ref="onboarding" pagination :params="{ speed: 1000, preventInteractionOnTransition: true, longSwipes: false, followFinger: false, parallax: true, observer: true, observeParents: true }">
      <div
        slot="before-wrapper"
        class="parallax-bg"
        :data-swiper-parallax="-100 * (numberOfActiveSlides - 1)"
      />
      <onboarding-slide-title
        v-if="activeSlides[1]"
        :step="1"
        :title="$t('slide-1-title')"
        :subtitle="$t('slide-1-subtitle')"
        :text="$t('slide-1-text')"
      />
      <onboarding-slide-title
        v-if="activeSlides[2]"
        :step="2"
        :title="$t('slide-2-title')"
        :subtitle="$t('slide-2-subtitle')"
        :text="$t('slide-2-text')"
      />
      <onboarding-slide-contacts
        v-if="activeSlides[3]"
      />
      <onboarding-slide-title
        v-if="activeSlides[4]"
        :step="3"
        :title="$t('slide-3-title')"
        :subtitle="$t('slide-3-subtitle')"
        :text="$t('slide-3-text')"
      />
      <onboarding-slide-notes
        v-if="activeSlides[5]"
      />
      <onboarding-slide-title
        v-if="activeSlides[6]"
        :step="4"
        :title="$t('slide-4-title')"
        :subtitle="$t('slide-4-subtitle')"
      >
        <template v-if="pluginNotifications" slot="text">
          {{ $t('slide-4-text') }}<br><a href="/p/notifications/">{{ $t('slide-4-link') }}</a>
        </template>
      </onboarding-slide-title>
      <onboarding-slide-reminders
        v-if="activeSlides[7]"
      />
      <onboarding-slide-spread
        v-if="activeSlides[8]"
        @spreaded="spreaded"
      />
      <onboarding-slide-title
        v-if="activeSlides[9]"
        :step="5"
        :title="$t('slide-5-title')"
      >
        <p data-swiper-parallax="-200">
          <f7-button fill large round popup-close @click="$store.dispatch('onboardingCompleted')">
            {{ $t('start') }}
          </f7-button>
        </p>
      </onboarding-slide-title>
    </f7-swiper>
  </f7-page>
</template>

<script>
import OnboardingSlideTitle from '../components/onboarding-slide-title.vue';
import OnboardingSlideContacts from '../components/onboarding-slide-contacts.vue';
import OnboardingSlideNotes from '../components/onboarding-slide-notes.vue';
import OnboardingSlideReminders from '../components/onboarding-slide-reminders.vue';
import OnboardingSlideSpread from '../components/onboarding-slide-spread.vue';

export default {
  components: {
    OnboardingSlideTitle,
    OnboardingSlideContacts,
    OnboardingSlideNotes,
    OnboardingSlideReminders,
    OnboardingSlideSpread,
  },
  data() {
    return {
      reachEnd: false,
      numberOfSlides: 9,
      activeSlide: 1,
      remindersSpreaded: false,
      pluginNotifications: window.cordova && window.cordova.plugins.notification ? window.cordova.plugins.notification.local : null,
    };
  },
  computed: {
    activeSlides() {
      const activeSlides = {};
      for (let i = 1; i <= this.numberOfSlides; i++) {
        // Some slides has special "active" state
        if (i === 1) activeSlides[i] = true;
        else if (i === 4) activeSlides[i] = !!this.$store.getters.contacts.length;
        else if (i === 9) activeSlides[i] = this.remindersSpreaded;
        // Others have the same state as the first one
        else activeSlides[i] = activeSlides[i - 1];
      }
      return activeSlides;
    },
    numberOfActiveSlides() {
      let numberOfActiveSlides = 0;
      for (let i = 1; i <= this.numberOfSlides; i++) {
        if (this.activeSlides[i]) numberOfActiveSlides = i;
        else break;
      }
      return numberOfActiveSlides;
    },
    title() {
      return this.activeSlide === 1 ? this.$t('welcome') : this.$t('onboarding');
    },
    prevVisible() {
      return this.activeSlide !== 1;
    },
    nextDisabled() {
      return this.activeSlide === this.numberOfActiveSlides;
    },
    nextVisible() {
      return this.activeSlide < 8;
    },
  },
  mounted() {
    const swiper = this.$refs.onboarding.swiper;
    swiper.on('slideChange', () => {
      this.activeSlide = swiper.activeIndex + 1;
      // On slide 7 (slide where we configure the reminders) we asj permission to send notifications
      if (this.activeSlide === 7) this.allowNotifications();
    });
    // Initialize dummy bullets
    this.addDummyBullets(swiper);
    swiper.on('paginationUpdate', this.addDummyBullets);
  },
  methods: {
    prev() {
      this.$refs.onboarding.swiper.slidePrev();
    },
    next() {
      this.$refs.onboarding.swiper.slideNext();
    },
    async spreaded() {
      console.log('spreaded');
      this.remindersSpreaded = true;
      await this.$nextTick();
      this.next();
    },
    addDummyBullets(swiper) {
      const bullets = swiper.pagination.el;
      bullets.querySelectorAll('.swiper-pagination-dummy-bullet').forEach(dummyBullet => dummyBullet.remove());
      for (let i = 0; i < this.numberOfSlides - this.numberOfActiveSlides; i++) {
        const dummyBullet = document.createElement('span');
        dummyBullet.classList.add('swiper-pagination-dummy-bullet');
        bullets.appendChild(dummyBullet);
      }
    },
    allowNotifications() {
      if (this.pluginNotifications) {
        this.pluginNotifications.hasPermission((granted) => {
          if (!granted) {
            this.pluginNotifications.requestPermission((requestGranted) => {
              if (!requestGranted) this.$f7.dialog.alert(this.$t('notifications-permission-error'));
            });
          }
        });
      }
    },
  },
};
</script>


<style lang="less">
.page-onboarding {
  // --f7-page-bg-color: #FFF;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 40px);
    width: 400%;
    background-image: url(../static/images/intro/background.svg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: left bottom;
  }

  .swiper-slide {
    position: relative;
    font-size: 18px;
    box-sizing: border-box;
    height: calc(100% - 40px);
    border-bottom: 3px solid #3d6b7b;

    .slide-scrollable-container {
      max-height: 100%;
      overflow: hidden auto;
      padding-bottom: 50px;
      box-sizing: border-box;
    }

  }

  .slide-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    overflow: hidden;

    img {
      height: 100%;
      margin: 0 -100%;
    }
  }

  .overlay {
    // background: rgba(255,255,255,0.8);
    backdrop-filter: blur(10px) saturate(150%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 50%;
    overflow: hidden;
    z-index: 10;
    background: #efeff4cc;
  }
  .overlay-container {
    padding: 35px;

    .title {
      font-size: 35px;
      font-weight: 300;
      margin: 0.5em auto;
    }
    .subtitle {
      font-size: 18px;
      margin-bottom: 1em;
    }
    .text {
      font-size: 14px;
      max-width: 400px;
      margin: auto;
      margin-top: 2em;
      color: var(--f7-block-footer-text-color);
      line-height: 1.3;
    }
    .button {
      margin: auto;
      width: fit-content;
      padding: 0 1em;
    }

    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }

  .block {
    text-align: center;
  }

  .fab {
    transform: translateX(0); // Otherwise, the fab button does not stay above the list on iOS when transitioning from slide to slide
    transition: opacity 400ms;
    --f7-safe-area-bottom: 0px;
  }

  .swiper-pagination-dummy-bullet {
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
    margin: 2px 6px;
  }
}
</style>

<i18n>
{
  "en": {
    "skip": "Skip",
    "welcome": "Welcome",
    "onboarding": "Onboarding",
    "slide-1-title": "Kipinto",
    "slide-1-subtitle": "Welcome to Kipinto, the application that will help you grow and develop your network",
    "slide-1-text": "To start the onboarding, click \"Next\" on the top right corner, or swipe the page",
    "slide-2-title": "Contacts",
    "slide-2-subtitle": "Kipinto brings together the contacts you don't want to lose sight of",
    "slide-2-text": "Give priority to quality over quantity: only add the relationships you want to maintain over the long term.",
    "slide-3-title": "Notes & Hashtags",
    "slide-3-subtitle": "Notes for remembering, with hashtags for organizing",
    "slide-3-text": "Enrich your contact files, make summaries after your interactions. Write down everything that matters, and what you could not enter in a CRM.",
    "slide-4-title": "Reminders",
    "slide-4-subtitle": "Kipinto helps you to interact on a regular basis",
    "slide-4-text": "Do not miss anything:",
    "slide-4-link": "config notifications",
    "slide-5-title": "It's all set!",
    "start": "Access Kipinto",
    "notifications-permission-error": "You must go in your phone parameters to enable notifications for Kipinto."
  },
  "fr": {
    "skip": "Passer",
    "welcome": "Bienvenue",
    "onboarding": "Embarquement",
    "slide-1-title": "Kipinto",
    "slide-1-subtitle": "Bienvenue sur Kipinto, l'application qui va vous aider à cultiver et faire fructifier votre réseau",
    "slide-1-text": "Pour l'embarquement, cliquez sur \"Suivant\" en haut à droite, ou faites glisser la page",
    "slide-2-title": "Contacts",
    "slide-2-subtitle": "Kipinto réunit les contacts que vous ne voulez pas perdre de vue",
    "slide-2-text": "Privilégiez la qualité sur la quantité : n’ajoutez que les relations que vous souhaitez entretenir, sur le long terme.",
    "slide-3-title": "Notes & Hashtags",
    "slide-3-subtitle": "Des notes pour se rappeler, avec des hashtags pour organiser",
    "slide-3-text": "Enrichissez vos fiches contacts, faites des synthèses après vos interactions. Notez tout ce qui compte, et ce que vous ne pourriez pas saisir dans un CRM.",
    "slide-4-title": "Rappels",
    "slide-4-subtitle": "Pour un réseau qui porte des fruits, Kipinto vous aide à interagir régulièrement",
    "slide-4-text": "Pour ne rien manquer :",
    "slide-4-link": "configurez les notifications",
    "slide-5-title": "Tout est prêt !",
    "start": "Accéder à Kipinto",
    "notifications-permission-error": "Vous devez aller dans les paramètres de votre téléphone pour autoriser Kipinto à vous envoyer des notifications."
  }
}
</i18n>
