import Vue from 'vue';
import Vuex from 'vuex';
import firebase from './firebase-config'; // REDUCE SIZE

import userStore from './store-user';
import dbStore from './store-db';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    notifications: JSON.parse(localStorage.getItem('notifications')) || {
      alerts: true,
      time: {
        hour: 18,
        minute: 0,
      },
      badge: true,
    },
  },
  modules: {
    userStore,
    dbStore,
  },
  mutations: {
    notifications: (state, { alerts, time, badge }) => {
      state.notifications = {
        alerts: alerts !== undefined ? !!alerts : !!state.notifications.alerts,
        time: {
          hour: time !== undefined && Number.isInteger(time.hour) && time.hour >= 0 && time.hour < 24 ? time.hour : state.notifications.time.hour || 18,
          minute: time !== undefined && Number.isInteger(time.minute) && time.minute >= 0 && time.minute < 60 ? time.minute : state.notifications.time.minute || 0,
        },
        badge: badge !== undefined ? !!badge : !!state.notifications.badge,
      };
      localStorage.setItem('notifications', JSON.stringify(state.notifications));
    },
  },
  actions: {
    loadStore({ commit, dispatch }, { user }) {
      commit('setUser', user);
      dispatch('bindContacts');
      dispatch('bindEvents');
      dispatch('bindUserDoc');
    },
    resyncStore({ dispatch }) {
      dispatch('bindContacts', { resync: true });
      dispatch('bindEvents', { resync: true });
    },
    clearStore({ commit, dispatch }) {
      dispatch('unbindContacts');
      dispatch('unbindEvents');
      dispatch('unbindUserDoc');
      commit('setUser', null);
    },
    enableAnalytics(context, enabled) {
      // If AppCenter has been attached to window (=> running on cordova)
      if (window.AppCenter) {
        // We enable the analytics
        window.AppCenter.Analytics.setEnabled(enabled, () => { /* success */ }, (error) => {
          console.error('AppCenter analytic failed', error);
        });
      }
      // If Plugin Firbease Analytic has been attached to window (=> running on cordova)
      if (window.cordova?.plugins?.firebase?.analytics) {
        // We enable the analytics
        window.cordova.plugins.firebase.analytics.setEnabled(enabled);
      // Else, if we are not on cordova
      } else {
        firebase.analytics().setAnalyticsCollectionEnabled(enabled);
      }
    },
    trackEvent(context, { name, properties }) {
      // Analytics with AppCenter (only Cordova)
      if (window.AppCenter) {
        window.AppCenter.Analytics.isEnabled(() => {
          console.log('track AppCenter event', name);
          window.AppCenter.Analytics.trackEvent(name, properties, null, (error) => {
            console.error('Track Event AppCenter trackEvent', error);
          });
        }, (error) => {
          console.error('Track Event AppCenter isEnabled', error);
        });
      }
      // Analytics with Google on Cordova
      if (window.cordova?.plugins?.firebase?.analytics) {
        console.log('track Google Analytic Cordova event', name);
        window.cordova.plugins.firebase.analytics.logEvent(name, properties);
      // Analytics with Google on Web
      } else {
        console.log('track Google Analytic event', name);
        firebase.analytics().logEvent(name, properties);
      }
    },
    trackPage(context, path) {
      // We extract a page name from the path (the first non null page that is not p)
      const page = path.split('/').find(x => x && x !== 'p') || 'UNKNOWN';
      const analytics = window.cordova?.plugins?.firebase?.analytics ?? firebase.analytics();
      if (analytics) {
        // analytics.setCurrentScreen(page); deprecated
        analytics.logEvent('screen_view', { page_location: path, screen_name: page, page_title: page });
        console.log('track page', page, path);
      }
    },
  },
  getters: {
    notifications: state => state.notifications, //
  },
});

// handle page reload
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('loadStore', { user });
  } else {
    store.dispatch('clearStore');
  }
});

export default store;
