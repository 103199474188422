<template>
  <f7-page name="page-legals" :page-content="false">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar bottom scrollable>
      <f7-link tab-link="#tab-tos" :tab-link-active="$f7route.query.tab === 'tos'">
        {{ $t('tos') }}
      </f7-link>
      <f7-link tab-link="#tab-privacy" :tab-link-active="$f7route.query.tab === 'privacy'">
        {{ $t('privacy') }}
      </f7-link>
      <f7-link tab-link="#tab-legals" :tab-link-active="$f7route.query.tab === 'legals'">
        {{ $t('legals') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs swipeable>
      <f7-tab id="tab-tos" class="page-content" :tab-active="$f7route.query.tab === 'tos'">
        <f7-block>
          <h1>Conditions générales d'utilisation de l’application web et mobile Kipinto, du site kipinto.ch et des services associés</h1>
          <p>En vigueur au 14/05/2020</p>
          <p>Avant d’entrer dans le détail des CGU, nous souhaitons rappeler certains points essentiels :</p>
          <ul>
            <li>Kipinto est conçu pour devenir <strong>votre plus fidèle allié dans le développement et l’entretien de votre réseau personnel.</strong></li>
            <li>Votre réseau est <strong>une richesse qui vous appartient en propre</strong>: toutes les informations que vous saisissez dans Kipinto ne regardent donc que vous, et ne sont partagées avec personne.</li>
            <li>L’objectif de notre service est de vous donner la même liberté et la même maîtrise sur vos notes que si vous les aviez rédigées dans un carnet ou saisies dans un tableur sur votre ordinateur personnel. En y ajoutant la possibilité d’y accéder partout, tout le temps, depuis tous vos appareils. Et avec une expérience utilisateur à la hauteur de l’enjeu !</li>
          </ul>
          <p>En cliquant sur la case « j’accepte les CGUs » vous reconnaissez accepter sans réserve les conditions générales d’utilisation ci-après décrites.</p>
          <h2>Préambule</h2>
          <p>Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet de définir et encadrer les modalités de mise à disposition de la plateforme, de l’application et du site par Kipinto et de définir les conditions d’accès et d’utilisation des services associés par « l'Utilisateur ».</p>
          <p>Les présentes CGU sont accessibles sur le site, sur la plateforme ainsi que via l’application à la rubrique «CGU».</p>
          <p>Toute inscription ou utilisation du site, de la plateforme ou de l’application implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur la plateforme ou l’application via le Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case correspondante. De la même manière, en vous inscrivant via les boutons de connexion « Google », « Apple », « Facebook », « Twitter » ou « LinkedIn » vous reconnaissez avoir pris connaissance et accepter l’intégralité des présentes conditions générales d’utilisation.</p>
          <p>En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par la plateforme, l’application et/ou le site.</p>
          <p>Kipinto se réserve la possibilité de modifier unilatéralement et à tout moment le contenu des présentes CGU, en vue notamment de les adapter aux évolutions de l’offre Kipinto ou à l’évolution de la législation en vigueur. Tout projet de modification des CGU sera communiqué à l’Utilisateur via notification, et via mail dans le cas de Comptes Enregistrés au plus tard deux (2) mois avant l’entrée en vigueur. Notification et mail incluront un lien vers la nouvelle version des CGU au format PDF.</p>
          <p>L’Utilisateur est réputé avoir accepté la modification s’il n’a pas notifié son refus à Kipinto avant la date d’entrée en vigueur, via le mail de contact disponible dans l’onglet de menu de l’application et de la plateforme, ou le contact précisé dans les présentes CGU.</p>
          <h2>Définitions</h2>
          <p>Dans le présent document :</p>
          <ul>
            <li><strong>Application Kipinto ou Application :</strong>désigne l’application web et mobile éditée par Kipinto.</li>
            <li><strong>CGU :</strong> désigne les présentes condition générales d’utilisation conclues entre Kipinto et l’Utilisateur.</li>
            <li><strong>Compte :</strong> désigne le compte qui doit être créé pour devenir Utilisateur et accéder aux Services.</li>
            <li><strong>Contenu(s) :</strong>Ensemble des éléments constituants l’information présente sur le Site, la Plateforme ou l’Application, notamment textes – images – vidéos.</li>
            <li><strong>Donnée(s) :</strong> désigne l’ensemble des données de l’Utilisateur.</li>
            <li><strong>Données Personnelles / Informations personnelles :</strong> désignent toutes les informations à caractère personnel concernant un Utilisateur, personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres. « Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</li>
            <li><strong>Données Saisies :</strong> désignent toutes les informations saisies par l’Utilisateur dans le cadre de son usage de l’Application ou de la Plateforme.</li>
            <li><strong>Informations clients :</strong>Ci-après dénommé « Information (s) » qui correspondent à l’ensemble des données personnelles susceptibles d’être détenues par <a href="https://kipinto.ch/">Kipinto</a> pour la gestion de votre compte, de la gestion de la relation client et à des fins d’analyses et de statistiques.</li>
            <li><strong>Plateforme Kipinto ou Plateforme :</strong> désigne l’application web éditée par Kipinto, utilisée ou non en synchronisation avec l’Application mobile.</li>
            <li><strong>Site Kipinto ou Site :</strong> désigne le site internet éditée par Kipinto, à l’adresse <a href="https://kipinto.ch/">https://kipinto.ch/</a>. Site vitrine présentant les Services Kipinto, et permettant de créer un compte ou d’accéder à la Plateforme.</li>
            <li><strong>Services Kipinto :</strong> désignent l’ensemble des services proposés par Kipinto, accessibles via l’Application ou la Plateforme.</li>
            <li><strong>Services Premiums :</strong> désignent les services payants auxquels l’Utilisateur peut souscrire via son espace personnel ou via le Site.</li>
            <li><strong>Utilisateur :</strong>désigne la personne physique ayant conclu les présentes CGU, utilisant le Site, l’Application et/ou la Plateforme susnommés.</li>
            <li><strong>Utilisateur non membre :</strong> désigne un Utilisateur ayant fait le choix de ne pas associer de données d’identifications à son compte. Ses données sont donc stockées en local sur son device (téléphone ou ordinateur), et ne seront donc pas accessible autrement. Ces données n’étant pas sauvegardées en ligne, elles ne seront donc pas récupérables en cas de perte de données.</li>
            <li><strong>Utilisateur membre :</strong> désigne un Utilisateur ayant associé des données d’identification à son compte.</li>
            <li><strong>Utilisateur premium :</strong> désigne un Utilisateur membre ayant souscrit les Services Premiums.</li>
          </ul>
          <h2>Article 1 : Mentions légales</h2>
          <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a href="https://kipinto.ch/">https://kipinto.ch/</a>, de la Plateforme et de l’Application l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
          <ul>
            <li><strong>Propriétaire :</strong> Jean-Baptiste Degiovanni – 3 rue des écoles 78270 Gommecourt</li>
            <li><strong>Responsable publication :</strong> Simon Degiovanni – <a href="mailto:simon@kipinto.ch">simon@kipinto.ch</a></li>
            <li><strong>Webmaster :</strong> Jean-Baptiste Degiovanni – <a href="mailto:jb@kipinto.ch">jb@kipinto.ch</a></li>
            <li><strong>Hébergeur :</strong> FIREBASE – 188 King ST CA 94107 San Francisco, United States - firebase.google.com</li>
            <li><strong>Délégué à la protection des données :</strong> <a href="mailto:contact@kipinto.ch">contact@kipinto.ch</a></li>
          </ul>
          <p>Le site Kipinto.ch et les données saisies via l’Application ou la Plateforme sont hébergés par le prestataire sur le territoire de l’Union Européenne conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD : n° 2016-679)</p>
          <p>Il convient de noter que ces mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
          <h2>ARTICLE 2 : Accès au Site, à la Plateforme, à l’Application</h2>
          <p>Le Site internet Kipinto.ch a pour objet de fournir une information concernant l’ensemble des activités et services de Kipinto, qui s’efforce de fournir sur le Site des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
          <p>Toutes les informations indiquées sur le site Kipinto.ch sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le Site ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
          <p>Le site kipinto.ch offre à l'Utilisateur un accès aux services suivants :</p>
          <ul>
            <li>Mise à disposition d'une Application web et mobile d'aide à la gestion du réseau personnel de l'utilisateur,</li>
            <li>Mise à disposition d'une Application premium payante apportant des fonctionnalités avancées aux utilisateurs individuels ou entreprises,</li>
          </ul>
          <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
          <p>L’Utilisateur non membre peut accéder aux services en accès restreint et sans garantie sur la pérennité des données saisies, celle-ci étant stockée en locale sur le device de l’utilisateur.</p>
          <p>Pour accéder à l’ensemble des services, l’Utilisateur doit s’inscrire en remplissant le formulaire. Pour que cette inscription soit valable, l’Utilisateur membre doit fournir une adresse mail exacte.</p>
          <p>Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qu’il aura définis au moment de son inscription.</p>
          <p>Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable, et entraînera la suppression de ses données personnelles ainsi que de l’ensemble des données saisies dans le cadre de son usage de l’application.</p>
          <p>Le Site, l’Application et la Plateforme sont normalement accessibles à tout moment aux Utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Kipinto, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.</p>
          <p>Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du Site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de Kipinto. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.</p>
          <p>L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.</p>
          <h2>ARTICLE 3 : Collecte des données – Politique de confidentialité</h2>
          <p>Le respect de votre vie privée est au cœur de nos engagements. Tous nos services sont pensés pour vous permettre, et à vous seul, de tirer le meilleur de son réseau personnel. Parce que nous croyons que votre réseau vous appartient, et que son développement ne regarde que vous. C’est pourquoi nous ne collectons de données personnelles que dans l’unique but d’assurer le meilleur service possible. En conséquence :</p>
          <ul>
            <li><strong>Nous ne vendons pas vos données,</strong></li>
            <li>Vos données personnelles ne sont stockées qu’afin de vous permettre d’accéder à votre compte sur l’ensemble de vos devices, de vous permettre de sauvegarder vos notes et contacts et de sécuriser votre compte via mot-de-passe,</li>
            <li>Les autres données d’activités que nous collectons sont anonymisées et ne nous servent qu’à des fins d’analyse, afin d’améliorer sans cesse nos services, à votre service.</li>
          </ul>
          <p>Pour toute question concernant le traitement de vos données, n’hésitez pas à nous contacter directement : <a href="mailto:contact@kipinto.ch">contact@kipinto.ch</a>.</p>
          <h3>Préambule</h3>
          <p>L’Utilisateur est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
          <h3>3.1 Responsables de la collecte des données personnelles</h3>
          <p>Kipinto est responsable du traitement des Données Personnelles collectées dans le cadre de la création du compte personnel de l’Utilisateur et de sa navigation sur le Site, ou au cours de son utilisation des Services Kipinto.</p>
          <p>En tant que responsable du traitement des données qu’il collecte, Kipinto s’engage à respecter le cadre des dispositions légales en vigueur. Chaque fois que Kipinto traite des Données Personnelles, il prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles il les traite.</p>
          <h3>3.2 Finalité des données collectées</h3>
          <p>Les Données Personnelles collectées auprès des Utilisateurs ont pour objectif la mise à disposition des Services Kipinto, leur amélioration et le maintien d’un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :</p>
          <ul>
            <li>Accès et utilisation du Site, de la Plateforme et/ou de l’Application par l’Utilisateur (en particulier données de connexion et d’utilisation) ;</li>
            <li>Gestion du fonctionnement et optimisation du Site, de la Plateforme et/ou de l’Application (en particulier données de connexion et d’utilisation) ;</li>
            <li>Organisation des conditions d’utilisation des Services de Paiement (en particulier données de facturation) ;</li>
            <li>Vérification, identification et authentification des données transmises par l’Utilisateur (en particulier données de connexion et d’utilisation) ;</li>
            <li>Mise en œuvre d’une assistance utilisateur (en particulier adresse email) ;</li>
            <li>Prévention et détection des fraudes, malwares (malicious software ou logiciels malveillants) et gestion des incidents de sécurité (en particulier matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)) ;</li>
            <li>Gestion des éventuels litiges avec les utilisateurs ;</li>
            <li>Envoi d’informations (en particulier adresse email) ;</li>
          </ul>
          <p>Par ailleurs Kipinto pourra également demander l’accès aux contacts téléphoniques de l’Utilisateur, afin de permettre un chargement accéléré de ses contacts. Il pourra également demander l’accès au téléphone ainsi qu’au client de messagerie afin de permettre à l’Utilisateur d’émettre des appels ou des messages via des raccourcis intégrés dans l’application. Il pourra également proposer d’intégrer un calendrier Kipinto dans son outil de calendrier afin de lui permettre de suivre au plus près ses rappels. Si l’Utilisateurs ne souhaite pas accorder ces accès, il ne pourra bénéficier des services associés, mais pourra continuer à utiliser les autres Services Kipinto.</p>
          <p>Enfin, dans tous les cas Kipinto ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.</p>
          <h3>3.3 Droit d’accès, de rectification et d’opposition</h3>
          <p>Conformément à la réglementation européenne en vigueur, les Utilisateurs de Kipinto disposent des droits suivants :</p>
          <ul>
            <li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
            <li>droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
            <li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)</li>
            <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
            <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)</li>
            <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui Kipinto devra communiquer (ou non) ses données à un tiers qu’ils auront préalablement désigné</li>
          </ul>
          <p>Dès que Kipinto a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, Kipinto s’engage à détruire ses données.  </p>
          <p>Si l’Utilisateur souhaite savoir comment Kipinto utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter Kipinto par écrit à l’adresse suivante précisée dans les mentions légales, ou par courrier électronique à l’adresse <a href="mailto:contact@kipinto.ch">contact@kipinto.ch</a>, sous réserve d’avoir justifié de son identité en joignant à sa demande une copie de sa pièce d’identité en cours de validité et en utilisant son adresse de courrier électronique renseignée lors de son inscription aux Services Kipinto.</p>
          <p>Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à Kipinto par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de Kipinto peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (<a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>) en application de l’article 77 du RGPD.</p>
          <p>Dans le cas où l’Utilisateur souhaiterait s’opposer à des traitements indispensables à la bonne marche des Services Kipinto, l’Utilisateur pourra être invité à fermer son Compte.</p>
          <p>Kipinto met en permanence à disposition de l’Utilisateur, via son Espace Personnel, la possibilité de modifier ses Données Personnels (identifiant, adresse mail), la possibilité de supprimer définitivement son compte et les Données Personnelles et Données Saisies associées, ainsi que la possibilité de télécharger (dans la limite d’une fois par 24h) l’intégralité des Données associées à son compte.</p>
          <h3>3.4 Partage des Données Personnelles avec des tiers</h3>
          <p>Kipinto s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, Kipinto reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’ils présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
          <p>Kipinto s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de Kipinto, il devra dans les meilleurs délais informer l’Utilisateur et lui communiquer les mesures de corrections prises. Par ailleurs Kipinto ne collecte aucune « données sensibles ».</p>
          <p>Les Données Personnelles peuvent être partagées avec des sociétés tierces dans les cas suivants :</p>
          <ul>
            <li>Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de Kipinto et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la politique décrite ci-avant.</li>
            <li>Lorsque l’Utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, Kipinto est en relation avec des société bancaires et financières tierces avec lesquelles elle a passé des contrats ;</li>
            <li>Lorsque l’Utilisateur se connecte aux Services via un tiers ;</li>
            <li>Si Kipinto et ses droits devaient être rachetés, alors ces informations seraient transmises à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'Utilisateur.</li>
          </ul>
          <p>Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs de Kipinto sont principalement les agents de notre service client.</p>
          <h3>3.5 Types de données collectées</h3>
          <p>Concernant les Utilisateurs des Services Kipinto, nous collectons les données suivantes qui sont indispensables au fonctionnement du service, et qui seront conservées pendant une période maximale de 36 mois après la fin de la relation contractuelle :</p>
          <p>nom, prénom, adresse électronique, données de connexion,</p>
          <p>Kipinto collecte en outre des informations anonymisées qui permettent d’améliorer l’expérience utilisateur :</p>
          <p>données analytiques associées à l'usage du compte, cookies analytiques</p>
          <p>Ces données sont conservées pour une période maximale de 36 mois après la fin de la relation contractuelle</p>
          <h3>3.6 Notification d’incident</h3>
          <p>Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.</p>
          <p>Aucune information personnelle de l'Utilisateur des Services Kipinto n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de Kipinto et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'Utilisateur.</p>
          <h3>3.7 Sécurité</h3>
          <p>Pour assurer la sécurité et la confidentialité des Données Personnelles et des Données Personnelles de Santé, Kipinto utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la pseudonymisation, l’encryption et mot de passe.</p>
          <p>Lors du traitement des Données Personnelles, Kipinto prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
          <h3>3.8 Evolution de la présente clause</h3>
          <p>Kipinto se réserve le droit d’apporter toute modification à la présente clause relative à la protection des données à caractère personnel à tout moment. Si une modification est apportée, Kipinto s’engage à publier la nouvelle version sur son site, ainsi qu’à la mettre à disposition via l’espace personnel de l’Utilisateur sur la Plateforme ou l’Application. Si l’Utilisateur n’est pas en phase avec les termes de la nouvelle rédaction de la clause, il a la possibilité de supprimer son compte.</p>
          <h2>ARTICLE 4 : Liens hypertextes « cookies » et BALISES (“TAGS”) INTERNET</h2>
          <h3>4.1 Liens hypertextes</h3>
          <p>Le Site Kipinto, l’Application ou la Plateforme peuvent contenir des liens hypertextes vers d’autres sites. Cependant, Kipinto n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
          <h3>4.2 Cookies</h3>
          <p>Sauf si vous décidez de désactiver les cookies, vous acceptez que le Site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que cela peut réduire ou empêcher l’accessibilité à tout ou partie des Services proposés par le site.</p>
          <p>Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.</p>
          <p>Kipinto est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à Kipinto d’améliorer le contenu du Site, de la navigation de l’Utilisateur.</p>
          <p>Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. Kipinto informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.</p>
          <p>Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque Kipinto ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.</p>
          <p>Le cas échéant, Kipinto décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site, de la Plateforme ou de l’Application et des services proposés par Kipinto, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour Kipinto d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.</p>
          <p>À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. Kipinto pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.</p>
          <p>Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site, sur la Plateforme ou dans son Application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet, la Plateforme ou l’Application de Kipinto, Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).</p>
          <p>Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site, la Plateforme ou l’Application de Kipinto. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que Kipinto dépose ce type de cookies.</p>
          <h3>4.3 Balises TAGS internet</h3>
          <p>Kipinto peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.</p>
          <p>Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci. </p>
          <p>Cette technologie permet à Kipinto d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’Utilisateur.</p>
          <p>Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de Kipinto et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.</p>
          <h2>ARTICLE 5 : Propriété intellectuelle</h2>
          <p>Conformément au Code de la propriété intellectuelle et plus particulièrement aux règles régissant le droit d'auteur, Kipinto est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le Site, la Plateforme ou l’Application, notamment les textes, images, graphismes, logos, vidéos, icônes et sons.</p>
          <p>L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus mis à sa disposition dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.</p>
          <p>Toute reproduction, représentation, modification, publication, adaptation totale ou partielle, par quelque procédé que ce soit, sans l’autorisation expresse de Kipinto constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.</p>
          <p>Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</p>
          <h2>ARTICLE 6 : Responsabilité</h2>
          <p>Les sources des informations diffusées sur le site kipinto.ch sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.</p>
          <p>Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, Kipinto ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.</p>
          <p>Kipinto ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce Site, de la Plateforme ou de l’Application.</p>
          <p>La responsabilité de Kipinto ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>
          <p>Kipinto ne pourra être tenu pour responsable du contenu saisi par l’Utilisateur dans le cadre de son utilisation de la Plateforme et/ou de l’Application. Par construction ces Données Saisies n’ont pas vocation à être diffusées à qui que ce soit, et ne sont accessibles qu’à l’Utilisateur seul, à travers son device dans le cas d’Utilisateurs non membres, à travers son identifiant et son mot de passe dans le cas d’Utilisateurs Membres et Premium.</p>
          <p>L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite, sauf si l’Utilisateur le fait à des fins de planification successorale. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Kipinto décline toute responsabilité.</p>
          <p>Kipinto ne pourra pas non plus être tenu pour responsable de la perte des Données Saisies par l’Utilisateur. Celui-ci est invité à régulièrement sauvegarder ses données via la fonctionnalité mise à sa disposition dans son espace personnel.</p>
          <h2>ARTICLE 7 : Droit applicable et juridiction compétente</h2>
          <p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.</p>
          <p>Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.</p>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-privacy" class="page-content" :tab-active="$f7route.query.tab === 'privacy'">
        <f7-block>
          <h1>Politique de confidentialité</h1>
          <p>Extrait des CGUs en vigueur au 14/05/2020</p>
          <p>Le respect de votre vie privée est au cœur de nos engagements. Tous nos services sont pensés pour vous permettre, et à vous seul, de tirer le meilleur de son réseau personnel. Parce que nous croyons que votre réseau vous appartient, et que son développement ne regarde que vous. C’est pourquoi nous ne collectons de données personnelles que dans l’unique but d’assurer le meilleur service possible. En conséquence :</p>
          <ul>
            <li><strong>Nous ne vendons pas vos données,</strong></li>
            <li>Vos données personnelles ne sont stockées qu’afin de vous permettre d’accéder à votre compte sur l’ensemble de vos devices, de vous permettre de sauvegarder vos notes et contacts et de sécuriser votre compte via mot-de-passe,</li>
            <li>Les autres données d’activités que nous collectons sont anonymisées et ne nous servent qu’à des fins d’analyse, afin d’améliorer sans cesse nos services, à votre service.</li>
          </ul>
          <p>Pour toute question concernant le traitement de vos données, n’hésitez pas à nous contacter directement : <a href="mailto:contact@kipinto.ch">contact@kipinto.ch</a>.</p>
          <h2>Préambule</h2>
          <p>L’Utilisateur est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
          <h2>ARTICLE 1 : Collecte des données – Politique de confidentialité</h2>
          <h3>1.1 Responsables de la collecte des données personnelles</h3>
          <p>Kipinto est responsable du traitement des Données Personnelles collectées dans le cadre de la création du compte personnel de l’Utilisateur et de sa navigation sur le Site, ou au cours de son utilisation des Services Kipinto.</p>
          <p>En tant que responsable du traitement des données qu’il collecte, Kipinto s’engage à respecter le cadre des dispositions légales en vigueur. Chaque fois que Kipinto traite des Données Personnelles, il prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles il les traite.</p>
          <h3>1.2 Finalité des données collectées</h3>
          <p>Les Données Personnelles collectées auprès des Utilisateurs ont pour objectif la mise à disposition des Services Kipinto, leur amélioration et le maintien d’un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :</p>
          <ul>
            <li>Accès et utilisation du Site, de la Plateforme et/ou de l’Application par l’Utilisateur (en particulier données de connexion et d’utilisation) ;</li>
            <li>Gestion du fonctionnement et optimisation du Site, de la Plateforme et/ou de l’Application (en particulier données de connexion et d’utilisation) ;</li>
            <li>Organisation des conditions d’utilisation des Services de Paiement (en particulier données de facturation) ;</li>
            <li>Vérification, identification et authentification des données transmises par l’Utilisateur (en particulier données de connexion et d’utilisation) ;</li>
            <li>Mise en œuvre d’une assistance utilisateur (en particulier adresse email) ;</li>
            <li>Prévention et détection des fraudes, malwares (malicious software ou logiciels malveillants) et gestion des incidents de sécurité (en particulier matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)) ;</li>
            <li>Gestion des éventuels litiges avec les utilisateurs ;</li>
            <li>Envoi d’informations (en particulier adresse email) ;</li>
          </ul>
          <p>Par ailleurs Kipinto pourra également demander l’accès aux contacts téléphoniques de l’Utilisateur, afin de permettre un chargement accéléré de ses contacts. Il pourra également demander l’accès au téléphone ainsi qu’au client de messagerie afin de permettre à l’Utilisateur d’émettre des appels ou des messages via des raccourcis intégrés dans l’application. Il pourra également proposer d’intégrer un calendrier Kipinto dans son outil de calendrier afin de lui permettre de suivre au plus près ses rappels. Si l’Utilisateurs ne souhaite pas accorder ces accès, il ne pourra bénéficier des services associés, mais pourra continuer à utiliser les autres Services Kipinto.</p>
          <p>Enfin, dans tous les cas Kipinto ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.</p>
          <h3>1.3 Droit d’accès, de rectification et d’opposition</h3>
          <p>Conformément à la réglementation européenne en vigueur, les Utilisateurs de Kipinto disposent des droits suivants :</p>
          <ul>
            <li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
            <li>droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
            <li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)</li>
            <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
            <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)</li>
            <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui Kipinto devra communiquer (ou non) ses données à un tiers qu’ils auront préalablement désigné</li>
          </ul>
          <p>Dès que Kipinto a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, Kipinto s’engage à détruire ses données.  </p>
          <p>Si l’Utilisateur souhaite savoir comment Kipinto utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter Kipinto par écrit à l’adresse suivante précisée dans les mentions légales, ou par courrier électronique à l’adresse <a href="mailto:contact@kipinto.ch">contact@kipinto.ch</a>, sous réserve d’avoir justifié de son identité en joignant à sa demande une copie de sa pièce d’identité en cours de validité et en utilisant son adresse de courrier électronique renseignée lors de son inscription aux Services Kipinto.</p>
          <p>Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à Kipinto par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de Kipinto peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (<a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>) en application de l’article 77 du RGPD.</p>
          <p>Dans le cas où l’Utilisateur souhaiterait s’opposer à des traitements indispensables à la bonne marche des Services Kipinto, l’Utilisateur pourra être invité à fermer son Compte.</p>
          <p>Kipinto met en permanence à disposition de l’Utilisateur, via son Espace Personnel, la possibilité de modifier ses Données Personnels (identifiant, adresse mail), la possibilité de supprimer définitivement son compte et les Données Personnelles et Données Saisies associées, ainsi que la possibilité de télécharger (dans la limite d’une fois par 24h) l’intégralité des Données associées à son compte.</p>
          <h3>1.4 Partage des Données Personnelles avec des tiers</h3>
          <p>Kipinto s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, Kipinto reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’ils présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
          <p>Kipinto s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de Kipinto, il devra dans les meilleurs délais informer l’Utilisateur et lui communiquer les mesures de corrections prises. Par ailleurs Kipinto ne collecte aucune « données sensibles ».</p>
          <p>Les Données Personnelles peuvent être partagées avec des sociétés tierces dans les cas suivants :</p>
          <ul>
            <li>Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de Kipinto et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la politique décrite ci-avant.</li>
            <li>Lorsque l’Utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, Kipinto est en relation avec des société bancaires et financières tierces avec lesquelles elle a passé des contrats ;</li>
            <li>Lorsque l’Utilisateur se connecte aux Services via un tiers ;</li>
            <li>Si Kipinto et ses droits devaient être rachetés, alors ces informations seraient transmises à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'Utilisateur.</li>
          </ul>
          <p>Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs de Kipinto sont principalement les agents de notre service client.</p>
          <h3>1.5 Types de données collectées</h3>
          <p>Concernant les Utilisateurs des Services Kipinto, nous collectons les données suivantes qui sont indispensables au fonctionnement du service, et qui seront conservées pendant une période maximale de 36 mois après la fin de la relation contractuelle :</p>
          <p>nom, prénom, adresse électronique, données de connexion,</p>
          <p>Kipinto collecte en outre des informations anonymisées qui permettent d’améliorer l’expérience utilisateur :</p>
          <p>données analytiques associées à l'usage du compte, cookies analytiques</p>
          <p>Ces données sont conservées pour une période maximale de 36 mois après la fin de la relation contractuelle</p>
          <h3>1.6 Notification d’incident</h3>
          <p>Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.</p>
          <p>Aucune information personnelle de l'Utilisateur des Services Kipinto n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de Kipinto et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'Utilisateur.</p>
          <h3>1.7 Sécurité</h3>
          <p>Pour assurer la sécurité et la confidentialité des Données Personnelles et des Données Personnelles de Santé, Kipinto utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la pseudonymisation, l’encryption et mot de passe.</p>
          <p>Lors du traitement des Données Personnelles, Kipinto prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
          <h3>1.8 Evolution de la présente clause</h3>
          <p>Kipinto se réserve le droit d’apporter toute modification à la présente clause relative à la protection des données à caractère personnel à tout moment. Si une modification est apportée, Kipinto s’engage à publier la nouvelle version sur son site, ainsi qu’à la mettre à disposition via l’espace personnel de l’Utilisateur sur la Plateforme ou l’Application. Si l’Utilisateur n’est pas en phase avec les termes de la nouvelle rédaction de la clause, il a la possibilité de supprimer son compte.</p>
          <h2>ARTICLE 2 : Liens hypertextes « cookies » et BALISES (“TAGS”) INTERNET</h2>
          <h3>1.1 Liens hypertextes</h3>
          <p>Le Site Kipinto, l’Application ou la Plateforme peuvent contenir des liens hypertextes vers d’autres sites. Cependant, Kipinto n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
          <h3>2.2 Cookies</h3>
          <p>Sauf si vous décidez de désactiver les cookies, vous acceptez que le Site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que cela peut réduire ou empêcher l’accessibilité à tout ou partie des Services proposés par le site.</p>
          <p>Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.</p>
          <p>Kipinto est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à Kipinto d’améliorer le contenu du Site, de la navigation de l’Utilisateur.</p>
          <p>Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. Kipinto informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.</p>
          <p>Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque Kipinto ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.</p>
          <p>Le cas échéant, Kipinto décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site, de la Plateforme ou de l’Application et des services proposés par Kipinto, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour Kipinto d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.</p>
          <p>À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. Kipinto pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.</p>
          <p>Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site, sur la Plateforme ou dans son Application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet, la Plateforme ou l’Application de Kipinto, Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).</p>
          <p>Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site, la Plateforme ou l’Application de Kipinto. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que Kipinto dépose ce type de cookies.</p>
          <h3>2.3 Balises TAGS internet</h3>
          <p>Kipinto peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.</p>
          <p>Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci. </p>
          <p>Cette technologie permet à Kipinto d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’Utilisateur.</p>
          <p>Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de Kipinto et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.</p>
          <h2>ARTICLE 3 : Responsabilité</h2>
          <p>[…]</p>
          <p>Kipinto ne pourra être tenu pour responsable du contenu saisi par l’Utilisateur dans le cadre de son utilisation de la Plateforme et/ou de l’Application. Par construction ces Données Saisies n’ont pas vocation à être diffusées à qui que ce soit, et ne sont accessibles qu’à l’Utilisateur seul, à travers son device dans le cas d’Utilisateurs non membres, à travers son identifiant et son mot de passe dans le cas d’Utilisateurs Membres et Premium.</p>
          <p>L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite, sauf si l’Utilisateur le fait à des fins de planification successorale. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Kipinto décline toute responsabilité.</p>
          <p>Kipinto ne pourra pas non plus être tenu pour responsable de la perte des Données Saisies par l’Utilisateur. Celui-ci est invité à régulièrement sauvegarder ses données via la fonctionnalité mise à sa disposition dans son espace personnel.</p>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-legals" class="page-content" :tab-active="$f7route.query.tab === 'legals'">
        <f7-block>
          <h1>Mentions légales</h1>
          <p>En vigueur au 14/05/2020</p>
          <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a href="https://kipinto.ch/">https://kipinto.ch/</a>, de la Plateforme et de l’Application l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
          <ul>
            <li><strong>Propriétaire :</strong> Jean-Baptiste Degiovanni – 3 rue des écoles 78270 Gommecourt</li>
            <li><strong>Responsable publication :</strong> Simon Degiovanni – <a href="mailto:simon@kipinto.ch">simon@kipinto.ch</a></li>
            <li><strong>Webmaster :</strong> Jean-Baptiste Degiovanni – <a href="mailto:jb@kipinto.ch">jb@kipinto.ch</a></li>
            <li><strong>Hébergeur :</strong> FIREBASE – 188 King ST CA 94107 San Francisco, United States - firebase.google.com</li>
            <li><strong>Délégué à la protection des données :</strong> <a href="mailto:contact@kipinto.ch">contact@kipinto.ch</a></li>
          </ul>
          <p>Le site Kipinto.ch et les données saisies via l’Application ou la Plateforme sont hébergés par le prestataire sur le territoire de l’Union Européenne conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD : n° 2016-679)</p>
          <p>Il convient de noter que ces mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>

<i18n>
{
  "en": {
    "title": "Kipinto",
    "tos": "TOS",
    "privacy": "Privacy",
    "legals": "Legals"
  },
  "fr": {
    "title": "Kipinto",
    "tos": "CGU",
    "privacy": "Confidentialité",
    "legals": "Mentions Légales"
  }
}
</i18n>
