import Moment from 'moment';

export default {
  methods: {
    reminderContactName(dbEvent) {
      if (dbEvent.isReminder) {
        const contact = this.$store.getters.contactById(dbEvent.contactId);
        return contact ? contact.name : null;
      }
      return null;
    },
    async toggleCompleted(dbEvent, hideItem) {
      // If there is a method to hide the item, we execute it
      if (hideItem) await hideItem();
      // Once the item has been hidden, we delete it
      this.$store.dispatch('updateEventCompletion', {
        event: dbEvent,
        completed: !dbEvent.completed,
      });
      this.$f7.toast.show({ text: this.$t(`mixins.event.complete-${dbEvent.type}-success`) });
    },
    async freezeContact(dbEvent, hideItem) {
      try {
        // If there is a method to hide the item, we execute it
        if (hideItem) await hideItem();
        // Once the item has been hidden, we delete it
        this.$store.dispatch('freezeContact', dbEvent.contactId);
        this.$f7.toast.show({ text: this.$t('mixins.event.freeze-success', { name: this.reminderContactName(dbEvent) }) });
      } catch (e) {
        this.$f7.dialog.alert(this.$t('mixins.event.freeze-failed', { name: this.reminderContactName(dbEvent), err: e.message }));
      }
    },
    confirmDeleteEvent(dbEvent) {
      return new Promise((resolve) => {
        this.$f7.dialog.confirm(
          this.$t(`mixins.event.delete-${dbEvent.type}-confirmation`),
          () => { resolve(true); },
          () => { resolve(false); },
        );
      });
    },
    async deleteEvent(dbEvent, hideItem) {
      // Get confirmation for deletion
      const confirmation = await this.confirmDeleteEvent(dbEvent);
      if (!confirmation) return; // Return if no confirmation
      try {
        // If there is a method to hide the item, we execute it
        if (hideItem) await hideItem();
        // Once the item has been hidden, we delete it
        this.$store.dispatch('deleteEvent', dbEvent);
        this.$f7.toast.show({ text: this.$t(`mixins.event.delete-${dbEvent.type}-success`) });
      } catch (e) {
        this.$f7.dialog.alert(this.$t(`mixins.event.delete-${dbEvent.type}-failed`, { err: e.message }));
      }
    },
    showPostponeAS() {
      return new Promise((resolve) => {
        if (!this.postponeActionSheet) {
          this.postponeActionSheet = this.$f7.actions.create({
            buttons: [
              [
                {
                  text: this.$t('action.postpone'),
                  label: true,
                },
                {
                  text: this.$t('mixins.event.postpone-1'),
                  onClick: () => resolve(1),
                },
                {
                  text: this.$t('mixins.event.postpone-7'),
                  onClick: () => resolve(7),
                },
                {
                  text: this.$t('mixins.event.postpone-30'),
                  onClick: () => resolve(30),
                },
              ],
              [
                {
                  text: this.$t('common.cancel'),
                  color: 'red',
                },
              ],
            ],
            on: {
              closed: () => {
                resolve(null);
              },
            },
          });
        }
        this.postponeActionSheet.open();
      });
    },
    async postpone(dbEvent, hideItem) {
      // Get the duration from Action Sheet
      const duration = await this.showPostponeAS();
      if (!duration) return; // Return if no duration provided
      // If there is a method to hide the item, we execute it
      if (hideItem) await hideItem();
      // Once the item has been hidden, we remove it
      try {
        let newDate = await this.$store.dispatch('postponeEvent', { event: dbEvent, postponed: duration });
        newDate = Moment(newDate).format('L');
        if (dbEvent.isReminder) this.$f7.toast.show({ text: this.$t('mixins.event.postpone-contact-success', { name: this.reminderContactName(dbEvent), date: newDate }) });
        else this.$f7.toast.show({ text: this.$t('mixins.event.postpone-interaction-success', { date: newDate }) });
      } catch (e) {
        if (dbEvent.isReminder) this.$f7.dialog.alert(this.$t('mixins.event.postpone-contact-failed', { name: this.reminderContactName(dbEvent), err: e.message }));
        else this.$f7.dialog.alert(this.$t('mixins.event.postpone-interaction-failed', { err: e }));
      }
    },
  },
};
