<template>
  <f7-page name="account-reauth" class="page-account-reauth">
    <f7-navbar>
      <f7-nav-title>{{ $t("title") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t("common.close") }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="authentication-wrapper">
      <f7-block class="text-align-center">
        {{ $t("explain") }}
      </f7-block>
      <provider-button
        v-for="provider in linkedOAuthProviders"
        :key="provider.id"
        :name="provider.name"
        mode="reauth"
        @click.native="reauthWithOAuth(provider.id)"
      />
      <fieldset v-if="hasEmailPasswordProvider">
        <f7-list form @submit.prevent="reauthWithEmailPassword">
          <f7-list-input
            type="email"
            autocomplete="email"
            :placeholder="$t('email-placeholder')"
            clear-button
            :value="email"
            @input="email = $event.target.value"
          />
          <f7-list-input
            type="password"
            autocomplete="new-password"
            :placeholder="$t('password-placeholder')"
            clear-button
            :value="password"
            @input="password = $event.target.value"
          />
          <f7-list-item-row class="no-padding-bottom">
            <f7-list-item-cell>
              <f7-button type="submit" large fill>
                {{ $t('login') }}
              </f7-button>
            </f7-list-item-cell>
          </f7-list-item-row>
        </f7-list>
      </fieldset>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

import ProviderButton from '../components/provider-button.vue';

export default {
  components: { ProviderButton },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters([
      'user', 'linkedOAuthProviders',
    ]),
    hasEmailPasswordProvider() {
      return this.user && this.user.providerData.findIndex(provider => provider.providerId === 'password') >= 0;
    },
  },
  methods: {
    reauthWithEmailPassword() {
      this.$store
        .dispatch('reauthWithEmailPassword', {
          email: this.email,
          password: this.password,
        })
        .then(() => this.reauthSuccessful())
        .catch(error => this.$f7.dialog.alert(`Oopsie in reauth Email. ${error.message}`));
    },
    reauthWithOAuth(provider) {
      this.$store
        .dispatch('reauthWithOAuth', provider)
        .then(() => this.reauthSuccessful())
        .catch(error => this.$f7.dialog.alert(`Oopsie in Google reauth. ${error.message}`));
    },
    reauthSuccessful() {
      this.$f7.popup.close();
      this.$f7.toast.show({ text: this.$t('reauthSuccessful') });
    },
  },
};
</script>

<style lang="less">
.page-account-reauth {
  --f7-page-bg-color: #ffffff;
  display: flex;
  flex-direction: column;

  .page-content {
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    max-height: 100%;
    width: 100%;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Re-authentication",
    "explain": "The action you attempted is sensitive and requires that you have recently signed in. Please re-authenticate.",
    "reauthSuccessful": "Reauthentication successful! You can retry your previous action.",
    "login": "Login again with your email",
    "with-email": "With an email & password",
    "with-provider": "With a service provider",
    "email-placeholder": "Your email",
    "password-placeholder": "Your password",
    "reauth-with": "Reauth with {name}"
  },
  "fr": {
    "title": "Réauthentification",
    "explain": "L'action que vous avez voulu réaliser est sensible et nécessite que vous vous soyez connecté récemment. Veuillez vous réauthentifier.",
    "reauthSuccessful": "Réauthentification réussie! Vous pouvez réessayer votre action précédente.",
    "login": "Se reconnecter avec votre email",
    "with-email": "Avec un email & un mot de passe",
    "with-provider": "Avec un fournisseur de services",
    "email-placeholder": "Votre email",
    "password-placeholder": "Votre mot de passe",
    "reauth-with": "Se reconnecter avec {name}"
  }
}
</i18n>
