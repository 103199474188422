<template>
  <f7-icon :ios="ios" :aurora="aurora" :md="md" v-bind="$attrs" />
</template>

<script>
import DBEvent from '../class/dbEvent.class';

export default {
  props: {
    event: {
      type: DBEvent,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ios: 'f7:chat_bubble_2_fill',
      aurora: 'f7:chat_bubble_2_fill',
      md: 'material:forum',
    };
  },
  mounted() {
    let icon = null;
    if (this.event && this.event.type) icon = this.event.type;
    if (icon === 'interaction' && this.event.via) icon = this.event.via;
    if (!icon && this.icon) icon = this.icon;
    if (icon === 'memo') {
      this.ios = 'f7:doc_text_fill';
      this.aurora = 'f7:doc_text_fill';
      this.md = 'material:description';
    } else if (icon === 'reminder') {
      this.ios = 'f7:bell_fill';
      this.aurora = 'f7:bell_fill';
      this.md = 'material:notifications';
    } else if (icon === 'mention') {
      this.ios = 'f7:at';
      this.aurora = 'f7:at';
      this.md = 'material:alternate_email';
    } else if (icon === 'email') {
      this.ios = 'f7:envelope_fill';
      this.aurora = 'f7:envelope_fill';
      this.md = 'material:email';
    } else if (icon === 'chat') {
      this.ios = 'f7:chat_bubble_text_fill';
      this.aurora = 'f7:chat_bubble_text_fill';
      this.md = 'material:message';
    } else if (icon === 'call') {
      this.ios = 'f7:phone_fill';
      this.aurora = 'f7:phone_fill';
      this.md = 'material:phone';
    } else if (icon === 'meeting') {
      this.ios = 'f7:person_fill';
      this.aurora = 'f7:person_fill';
      this.md = 'material:person';
    }
  },
};
</script>
