import Contact from './contact.class';
import Interaction from './interaction.class';
import Memo from './memo.class';
import UserDoc from './userDoc.class';

export const dbEventConverter = {
  toFirestore(dbEvent) {
    return dbEvent.exportToDB();
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (data.metadata && data.metadata.deleted) return null;
    switch (data.type) {
      case 'interaction': return new Interaction(data, snapshot.id, snapshot.metadata.hasPendingWrites);
      case 'memo': return new Memo(data, snapshot.id, snapshot.metadata.hasPendingWrites);
      default: throw new Error('unknown-dbevent-type');
    }
  },
};

export const contactConverter = {
  toFirestore(contact) {
    return contact.exportToDB();
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (data.metadata && data.metadata.deleted) return null;
    return new Contact(data, snapshot.id, snapshot.metadata.hasPendingWrites);
  },
};

export const userDocConverter = {
  toFirestore(userDoc) {
    return userDoc.exportToDB();
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return new UserDoc(data, snapshot.id);
  },
};

export function dbEventFactory(eventFromDB, id) {
  if (eventFromDB.metadata && eventFromDB.metadata.deleted) return null;
  switch (eventFromDB.type) {
    case 'interaction': return new Interaction(eventFromDB, id);
    case 'memo': return new Memo(eventFromDB, id);
    default: throw new Error('unknown-dbevent-type');
  }
}

export function dbContactFactory(contactFromDB, id) {
  if (contactFromDB.metadata && contactFromDB.metadata.deleted) return null;
  return new Contact(contactFromDB, id);
}

export default {
  dbEventConverter,
  contactConverter,
  userDocConverter,
  dbEventFactory,
};
