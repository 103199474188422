<template>
  <f7-list media-list :class="{ 'skeleton-text': searchLoading, 'skeleton-effect-blink': searchLoading }" class="search-results-list">
    <template v-for="result in searchResultsList">
      <contact-list-item v-if="result.item.collection === 'contacts'" :key="result.item.hash" :contact="result.item" />
      <event-list-item v-else :key="result.item.hash" :loading="searchLoading" :event="result.item" />
    </template>
  </f7-list>
</template>

<script>
import EventListItem from './event-list-item.vue';
import ContactListItem from './contact-list-item.vue';
import Interaction from '../class/interaction.class';

function getDummySearch(number) {
  const dummies = [];
  for (let i = 0; i < number; i++) {
    dummies.push({ item: new Interaction({}, `dummySearch${i}`) });
  }
  return dummies;
}
const dummySearches = getDummySearch(20);

export default {
  components: {
    EventListItem, ContactListItem,
  },
  props: {
    searchResults: {
      type: Array,
      default: null,
    },
    searchLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { };
  },
  computed: {
    searchResultsList() {
      if (this.searchLoading) {
        return dummySearches;
      }
      return this.searchResults;
    },
  },
};
</script>

<style lang="less">
.search-results-list {
  margin: 0;

  .item-media {
    width: 28px;
    justify-content: center;
  }
  .item-inner .item-text {
    white-space: nowrap;
    height: 21px;
    -webkit-line-clamp: 1;
  }
}
</style>
