<template>
  <f7-page name="page-contacts-phone-selected">
    <f7-navbar :back-link="$t('common.previous')">
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="importContacts">
          {{ $t('common.ok') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list inset>
      <f7-list-item slot="list">
        <div>
          <h3>{{ $t('intro-title') }}</h3>
          <p>{{ $t('intro-text') }}</p>
        </div>
      </f7-list-item>
      <template v-if="!$f7route.query.onboarding">
        <frequency-selector slot="list" :value="0" @change="setDefaultFrequency" />
        <f7-block-footer slot="after-list">
          {{ $t('form-frequencyExplanation') }}
        </f7-block-footer>
      </template>
    </f7-list>

    <f7-list v-for="contact in selectedContacts" :key="contact.id" inset>
      <f7-list-input
        slot="list"
        type="text"
        name="firstname"
        :value="contact.firstname"
        :placeholder="$t('form-firstname')"
        clear-button
        @input="contact.firstname = $event.target.value"
      />
      <f7-list-input
        slot="list"
        type="text"
        name="lastname"
        :value="contact.lastname"
        :placeholder="$t('form-lastname')"
        clear-button
        @input="contact.lastname = $event.target.value"
      />
      <f7-list-input
        slot="list"
        type="text"
        name="company"
        :value="contact.company"
        :placeholder="$t('form-company')"
        clear-button
        @input="contact.company = $event.target.value"
      />
      <f7-list-input
        slot="list"
        type="text"
        name="email"
        :value="contact.email"
        :placeholder="$t('form-email')"
        clear-button
        @input="contact.email = $event.target.value"
      >
        <f7-icon slot="media" f7="at_circle_fill" color="gray" />
      </f7-list-input>
      <li v-if="contact.emails && contact.emails.length > 1" slot="list" class="alternatives">
        <ul>
          <f7-list-item
            v-for="email in contact.emails"
            :key="email.id"
            :title="email.type"
            :after="email.value"
            radio
            radio-icon="end"
            :checked="contact.email === email.value"
            @click="contact.email = email.value"
          />
        </ul>
      </li>
      <f7-list-input
        slot="list"
        type="text"
        name="phone"
        :value="contact.phone"
        :placeholder="$t('form-phone')"
        clear-button
        @input="contact.phone = $event.target.value"
      >
        <f7-icon slot="media" f7="phone_circle_fill" color="gray" />
      </f7-list-input>
      <li v-if="contact.phones && contact.phones.length > 1" slot="list" class="alternatives">
        <ul>
          <f7-list-item
            v-for="phone in contact.phones"
            :key="phone.id"
            :title="phone.type"
            :after="phone.value"
            radio
            radio-icon="end"
            :checked="contact.phone === phone.value"
            @click="contact.phone = phone.value"
          />
        </ul>
      </li>
      <frequency-selector v-if="!$f7route.query.onboarding" slot="list" v-model="contact.frequency" />
      <f7-list-button :title="$t('remove')" color="red" @click="remove(contact.id)" />
    </f7-list>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';
import Contact from '../class/contact.class';
import FrequencySelector from '../components/frequency-selector.vue';

export default {
  components: { FrequencySelector },
  data() {
    return {
      selectedContacts: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([]),
  },
  mounted() {
    this.selectedContacts = this.$f7route.context.selectedContacts || [];
    for (let i = 0; i < this.selectedContacts.length; i++) {
      this.$set(this.selectedContacts[i], 'phone', this.getDefault(this.selectedContacts[i].phones));
      this.$set(this.selectedContacts[i], 'email', this.getDefault(this.selectedContacts[i].emails));
      this.$set(this.selectedContacts[i], 'frequency', 0);
    }
  },
  methods: {
    getDefault(list) {
      if (!list) return null;
      const pref = list.find(elem => elem.pref);
      if (pref) return pref.value;
      return list[0].value;
    },
    setDefaultFrequency(value) {
      // eslint-disable-next-line no-param-reassign
      this.selectedContacts.forEach((contact) => { contact.frequency = value; });
    },
    remove(contactId) {
      this.selectedContacts.splice(this.selectedContacts.findIndex(contact => contact.id === contactId), 1);
    },
    importContacts() {
      const contactsToRetry = [];
      const contactsToImport = [];
      // We prepare the list of Contacts we want to save
      this.selectedContacts.forEach((selectedContact) => {
        const contact = new Contact({
          firstname: selectedContact.firstname,
          lastname: selectedContact.lastname,
          company: selectedContact.company,
          phone: selectedContact.phone,
          email: selectedContact.email,
          frequency: selectedContact.frequency,
        });
        if (contact.isReadyToExportToDB()) {
          contactsToImport.push(contact);
        } else {
          contactsToRetry.push(selectedContact);
        }
      });
      // We import the contacts
      this.$store.dispatch('importContacts', contactsToImport);
      if (contactsToRetry.length === 0) {
        this.$f7.popup.close();
      } else {
        this.selectedContacts = contactsToRetry;
        this.$f7.dialog.alert(this.$t('save-failedMissInformation'));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.md .alternatives {
  margin-top: calc(-1 * var(--f7-list-item-padding-vertical));
}
.alternatives {
  /deep/ .item-title {
    flex-shrink: 0;
  }
  /deep/ .item-inner {
    overflow-x: auto;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Import Phone Contacts",
    "intro-title": "Almost done!",
    "intro-text": "If you wish, you can check the contacts before import, especially the email or phone to be imported for contacts who have more than one.",
    "remove": "Remove from import",
    "form-company": "Company - Role",
    "form-email": "Email address",
    "form-phone": "Phone number",
    "form-firstname": "Firstname",
    "form-lastname": "Name",
    "save-failedMissInformation": "You must provide at least a firstname or a lastname for your contact",
    "form-frequencyExplanation": "This frequency reflects the rhythm of interactions you intend to have."
  },
  "fr": {
    "title": "Importer des Contacts",
    "intro-title": "C'est presque fini !",
    "intro-text": "Si vous le souhaitez, vous pouvez vérifier les contacts avant import, en particulier l'email ou le téléphone à importer pour les contacts qui en ont plusieurs.",
    "remove": "Ne pas importer",
    "form-company": "Entreprise - Poste",
    "form-email": "Adresse Courriel",
    "form-phone": "Numéro de téléphone",
    "form-firstname": "Prénom",
    "form-lastname": "Nom",
    "save-failedMissInformation": "Vous devez fournir au moins un nom ou un prénom pour votre contact",
    "form-frequencyExplanation": "Cette fréquence reflète le rythme d'interactions que vous souhaitez avoir."
  }
}
</i18n>
