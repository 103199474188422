<template>
  <f7-page name="notifications">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block v-if="hasPermission === false && (notifications.banner || notifications.badge)" strong>
      <p><strong>{{ $t('permission-required') }}</strong></p>
      <p>
        <f7-button fill @click="allowKipinto">
          {{ $t('permission-enable') }}
        </f7-button>
      </p>
    </f7-block>
    <f7-block-title>{{ $t('alerts') }}</f7-block-title>
    <f7-list inline-labels>
      <f7-list-item :title="$t('alerts-enable')">
        <f7-toggle
          slot="after"
          :checked="notifications.alerts"
          @toggle:change="enable('alerts', $event)"
        />
      </f7-list-item>
      <f7-list-input
        :label="$t('alerts-time')"
        type="time"
        placeholder="hh:mm"
        :value="currentTime"
        @change="updateTime"
      />
      <f7-list-item>
        <div class="notification modal-in notification-demo">
          <div class="notification-header">
            <div class="notification-icon">
              <img src="static/icons/favicon.png">
            </div>
            <div class="notification-title">
              Kipinto
            </div>
            <span class="notification-close-button" />
          </div>
          <div class="notification-content">
            <div class="notification-subtitle">
              {{ $t('alerts-example-title') }}
            </div>
            <div class="notification-text">
              {{ $t('alerts-example-text') }}
            </div>
          </div>
        </div>
      </f7-list-item>
      <f7-block-footer>{{ $t('alerts-footer') }}</f7-block-footer>
    </f7-list>
    <f7-block-title>{{ $t('badge') }}</f7-block-title>
    <f7-list>
      <f7-list-item :title="$t('badge-enable')">
        <f7-toggle
          slot="after"
          :checked="notifications.badge"
          @toggle:change="enable('badge', $event)"
        />
      </f7-list-item>
      <f7-block-footer>{{ $t('badge-footer') }}</f7-block-footer>
    </f7-list>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      hasPermission: null,
    };
  },
  computed: {
    ...mapGetters(['notifications']),
    currentTime() {
      return `${(`0${this.notifications.time.hour}`).slice(-2)}:${(`0${this.notifications.time.minute}`).slice(-2)}`;
    },
  },
  mounted() {
    if (window.cordova && window.cordova.plugins.notification && window.cordova.plugins.notification.local) {
      window.cordova.plugins.notification.local.hasPermission((granted) => { this.hasPermission = granted; });
    }
  },
  methods: {
    updateTime(event) {
      const [hour, minute] = event.target.value.split(':');
      this.$store.commit('notifications', { time: { hour: parseInt(hour, 10), minute: parseInt(minute, 10) } });
    },
    allowKipinto() {
      window.cordova.plugins.notification.local.requestPermission((granted) => {
        if (!granted) this.$f7.dialog.alert(this.$t('permission-error'));
        this.hasPermission = granted;
      });
    },
    enable(type, enabled) {
      if (enabled && this.hasPermission === false) this.allowKipinto();
      this.$store.commit('notifications', { [type]: enabled });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .inline-labels .item-input {
  .item-label, .item-title {
    width: 75%;
  }
}
/deep/ .item-input .item-inner:after {
  display: block !important;
}
.notification.notification-demo {
  display: block;
  position: relative;
  margin: var(--f7-notification-margin) 0;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
</style>

<i18n>
{
  "en": {
    "title": "Notifications",
    "alerts": "Alerts",
    "alerts-enable": "Enable alerts",
    "alerts-time": "Alerts time",
    "alerts-footer": "Notification banners alert you when new tasks arrive.",
    "alerts-example-title": "Time to keep in touch!",
    "alerts-example-text": "You have 2 new tasks waiting for you in Kipinto",
    "badge": "Badge",
    "badge-enable": "Show badge",
    "badge-footer": "The badge is displayed on the application icon to indicate that there are tasks to be processed.",
    "permission-required": "Watch out! You must allow Kipinto to send you notifications!",
    "permission-enable": "Allow notifications",
    "permission-error": "You must go in your phone parameters to enable notifications for Kipinto."
  },
  "fr": {
    "title": "Notifications",
    "alerts": "Alertes",
    "alerts-enable": "Activer les alertes",
    "alerts-time": "Heure des alertes",
    "alerts-footer": "Les bannières de notifications vous alertent quand de nouvelles tâches arrivent.",
    "alerts-example-title": "Votre réseau vous attend !",
    "alerts-example-text": "2 nouvelles tâches vous attendent dans Kipinto",
    "badge": "Pastille",
    "badge-enable": "Afficher la pastille",
    "badge-footer": "La pastille s'affiche sur l'icône de l'application pour signaler que des tâches sont à traiter.",
    "permission-required": "Attention ! Vous devez autoriser Kipinto à vous envoyer des notifications !",
    "permission-enable": "Autoriser les notifications",
    "permission-error": "Vous devez aller dans les paramètres de votre téléphone pour autoriser Kipinto à vous envoyer des notifications."
  }
}
</i18n>
