<template>
  <f7-page name="contact-edit" class="page-contact-edit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          {{ $t('common.cancel') }}
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $f7route.params.contactId ? $t('editContactTitle') : $t('newContactTitle') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="saveContact">
          {{ $t('common.ok') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list v-if="showImportFromPhone" class="no-margin-top">
      <f7-list-item link="/contacts-phone-list/" :title="$t('import-from-phone')">
        <f7-icon slot="media" ios="f7:rectangle_stack_person_crop" aurora="f7:rectangle_stack_person_crop" md="material:contacts" />
      </f7-list-item>
    </f7-list>

    <form id="form-contact-edit">
      <f7-list inset>
        <f7-list-input
          type="text"
          name="firstname"
          :value="contact.firstname"
          :placeholder="$t('form-firstname')"
          clear-button
          @input="contact.firstname = $event.target.value"
        />
        <f7-list-input
          type="text"
          name="lastname"
          :value="contact.lastname"
          :placeholder="$t('form-lastname')"
          clear-button
          @input="contact.lastname = $event.target.value"
        />
        <f7-list-input
          type="text"
          name="company"
          :value="contact.company"
          :placeholder="$t('form-company')"
          clear-button
          @input="contact.company = $event.target.value"
        />
      </f7-list>

      <f7-list inset>
        <f7-list-input
          type="text"
          name="email"
          :value="contact.email"
          :placeholder="$t('form-email')"
          clear-button
          @input="contact.email = $event.target.value"
        >
          <f7-icon slot="media" f7="at_circle_fill" color="gray" />
        </f7-list-input>
        <f7-list-input
          type="text"
          name="phone"
          :value="contact.phone"
          :placeholder="$t('form-phone')"
          clear-button
          @input="contact.phone = $event.target.value"
        >
          <f7-icon slot="media" f7="phone_circle_fill" color="gray" />
        </f7-list-input>
        <f7-list-input
          type="text"
          name="linkedin"
          :value="contact.social.linkedinForm"
          :placeholder="$t('form-linkedin')"
          clear-button
          @input="contact.social.linkedinForm = $event.target.value"
        >
          <f7-icon slot="media" f7="logo_linkedin" color="gray" />
        </f7-list-input>
        <f7-list-input
          type="text"
          name="facebook"
          :value="contact.social.facebookForm"
          :placeholder="$t('form-facebook')"
          clear-button
          @input="contact.social.facebookForm = $event.target.value"
        >
          <f7-icon slot="media" f7="logo_facebook" color="gray" />
        </f7-list-input>
        <f7-list-input
          type="text"
          name="twitter"
          :value="contact.social.twitterForm"
          :placeholder="$t('form-twitter')"
          clear-button
          @input="contact.social.twitterForm = $event.target.value"
        >
          <f7-icon slot="media" f7="logo_twitter" color="gray" />
        </f7-list-input>
      </f7-list>

      <f7-list v-if="!$f7route.query.onboarding" inset>
        <frequency-selector
          slot="list"
          v-model="contact.frequency"
        />
        <f7-block-footer slot="after-list">
          {{ $t('form-frequencyExplanation') }}
        </f7-block-footer>
      </f7-list>
    </form>

    <f7-block v-if="$f7route.params.contactId">
      <f7-button block color="red" @click="deleteContact">
        {{ $t('deleteContact') }}
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import Contact from '../class/contact.class';
import FrequencySelector from '../components/frequency-selector.vue';

export default {
  components: { FrequencySelector },
  data() {
    return {
      contact: new Contact(),
      showImportFromPhone: false,
    };
  },
  mounted() {
    const contactId = this.$f7route.params.contactId;
    const contactToEdit = contactId ? this.$store.getters.contactById(contactId) : undefined;
    if (contactToEdit) {
      this.contact = new Contact(contactToEdit, contactId);
    } else {
      this.showImportFromPhone = navigator.contacts && navigator.contacts.find; // navigator.contacts is available on Chrome, but it is not the same
      this.contact.frequency = this.$f7route.query.onboarding ? 0 : 90; // New contact default to trimester frequency, except for onboarding
    }
  },
  methods: {
    saveContact() {
      // If we have the required information, we attempt to save the contact
      if (this.contact.isReadyToExportToDB()) {
        this.$store.dispatch('saveContact', this.contact)
          .catch((error) => {
            // We raise an alert to the user
            console.error('Error creating the contact: ', error);
            this.$f7.dialog.alert(this.$t('save-failed-firebase'));
          });
        // We close the "contact edit" page
        this.$f7.popup.close();
      } else {
        this.$f7.dialog.alert(this.$t('save-failed-missInformation'));
      }
    },
    deleteContact() {
      const _this = this;
      _this.$f7.dialog.confirm(
        _this.$t('delete-confirm-text'),
        _this.$t('delete-confirm-title'),
        () => {
          console.log('Start deleting the contact');
          // We delete the contact
          _this.$store.dispatch('deleteContact', _this.contact)
            .catch((error) => {
              console.error('Error deleting contact: ', error);
              // We raise an alert to the user
              _this.$f7.dialog.alert(_this.$t('delete-failed'));
            });
          // We close the popup
          _this.$f7.popup.close();
        },
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
    "deleteContact": "Delete this contact",
    "editContactTitle": "Edit Contact",
    "import-from-phone": "Import phone contacts?",
    "form-company": "Company - Role",
    "form-email": "Email address",
    "form-facebook": "facebook.com/username",
    "form-firstname": "Firstname",
    "form-lastname": "Name",
    "form-linkedin": "linkedin.com/in/username-012345XX/",
    "form-frequencyExplanation": "If you have the goal of having an interaction with this contact at least every trimester, then should shoce the frequency \"every trimester\".",
    "form-phone": "Phone number",
    "form-twitter": "twitter.com/username",
    "newContactTitle": "New Contact",
    "save-failed-firebase": "Error when attempting to save your Contact. Please retry.",
    "save-failed-missInformation": "You must provide at least a firstname or a lastname for your contact",
    "delete-failed": "Error when attempting to delete your Contact. Please retry.",
    "delete-confirm-text": "The deletion of the contact cannot be undone.",
    "delete-confirm-title": "Delete the contact?"
  },
  "fr": {
    "deleteContact": "Supprimer ce contact",
    "editContactTitle": "Modifier le Contact",
    "import-from-phone": "Importer depuis le téléphone ?",
    "form-company": "Entreprise - Poste",
    "form-email": "Adresse Courriel",
    "form-facebook": "facebook.com/pseudo",
    "form-firstname": "Prénom",
    "form-lastname": "Nom",
    "form-linkedin": "linkedin.com/in/pseudo-012345XX/",
    "form-frequencyExplanation": "Si votre objectif est d'avoir au moins une interaction avec ce contact chaque trimestre, sélectionnez la fréquence \"tous les trimestres\".",
    "form-phone": "Numéro de téléphone",
    "form-twitter": "twitter.com/pseudo",
    "newContactTitle": "Nouveau Contact",
    "save-failed-firebase": "Une erreur est survenue pendant la sauvegarde de votre contact. Veuillez réessayez.",
    "save-failed-missInformation": "Vous devez fournir au moins un nom ou un prénom pour votre contact",
    "delete-failed": "Une erreur est survenue pendant la suppression de votre contact. Veuillez réessayez.",
    "delete-confirm-text": "La suppression d'un contact ne peut pas être annulée.",
    "delete-confirm-title": "Supprimer le contact ?"
  }
}
</i18n>
