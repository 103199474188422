<template>
  <f7-swiper-slide>
    <div class="slide-scrollable-container">
      <f7-block data-swiper-parallax="-300">
        <p>{{ $t('add-reminders') }}</p>
      </f7-block>
      <f7-list inset data-swiper-parallax="-200">
        <f7-block-header>
          <selection-switcher :elements="contacts" :elements-selected-id="contactsSelectedId" @new-selection="contactsSelectedId = $event" />
        </f7-block-header>
        <f7-list-item
          v-for="contact in contacts"
          :key="contact.id"
          checkbox
          :checked="contactsSelectedId.indexOf(contact.id) >= 0"
          :title="contact.name"
          @change="checkContact($event, contact.id)"
        >
          <f7-link v-if="contact.frequency" slot="after" @click="contactClicked=contact; showFrequencyAction = true">
            {{ $t('frequency.'+contact.frequency+'days') }}
          </f7-link>
          <f7-link
            v-else
            slot="after"
            icon-md="material:notifications_off"
            icon-ios="f7:bell_slash_fill"
            icon-aurora="f7:bell_slash_fill"
            @click="contactClicked=contact; showFrequencyAction = true"
          />
        </f7-list-item>
        <f7-block-footer>
          <selection-switcher :elements="contacts" :elements-selected-id="contactsSelectedId" @new-selection="contactsSelectedId = $event" />
        </f7-block-footer>
      </f7-list>
    </div>

    <action-frequency-selector ref="frequencySelector" :opened="showFrequencyAction" :contacts-selected-id="contactsSelectedId" :contact-clicked="contactClicked" @actions:closed="contactClicked = null; showFrequencyAction = false" />

    <f7-fab :class="{ disabled: !contactsSelectedId.length }" position="right-bottom" :text="$t('reminders')" @click="showFrequencyAction = true">
      <f7-icon ios="f7:bell_fill" aurora="f7:bell_fill" md="material:notifications" />
    </f7-fab>
  </f7-swiper-slide>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectionSwitcher from './selection-switcher.vue';
import ActionFrequencySelector from './action-frequency-selector.vue';

export default {
  components: { SelectionSwitcher, ActionFrequencySelector },
  data() {
    return {
      contactClicked: null,
      contactsSelectedId: [],
      showFrequencyAction: false,
    };
  },
  computed: {
    ...mapGetters([
      'contacts',
    ]),
  },
  methods: {
    checkContact(event, contactId) {
      if (event.target.checked) {
        this.contactsSelectedId.push(contactId);
      } else {
        this.contactsSelectedId = this.contactsSelectedId.filter(el => el !== contactId);
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "add-reminders": "Set a reminder frequency for each contact, and Kipinto will notify you to interact when the time comes.",
    "reminders": "Reminders"
  },
  "fr": {
    "add-reminders": "Fixez une fréquence de rappel pour chaque contact, et Kipinto vous signalera d'interagir le moment venu.",
    "reminders": "Rappels"
  }
}
</i18n>
