/* eslint-disable class-methods-use-this */
import DBEvent from './dbEvent.class';

export default class Reminder extends DBEvent {
  constructor(contact) {
    super(contact, contact.id);
    this.contacts = [contact.id];
    this.date = contact.futureInteractionDate;
    this.completed = false;
    this.isFirstReminder = !contact.lastInteraction;
  }

  get type() { return 'reminder'; }

  get via() { return 'reminder'; }

  get contactId() {
    return this.contacts[0];
  }

  get isReminder() { return true; }

  exportToDB() {
    throw new Error('You cannot export Reminders to DB');
  }

  isReadyToExportToDB() {
    throw new Error('You cannot export Reminders to DB');
  }
}
