<template>
  <div id="illustration-todo-container" ref="illustrationTodoContainer">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 527.08 297" :class="{ 'hide-character': !tasksDone }">
      <defs>
        <linearGradient
          id="Dégradé_sans_nom_28"
          data-name="Dégradé sans nom 28"
          x1="182.08"
          y1="233.77"
          x2="213.1"
          y2="267.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#6cb1c9" />
          <stop offset="1" stop-color="#3b616e" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_28-2"
          x1="183.64"
          y1="250.26"
          x2="200.29"
          y2="267.92"
          xlink:href="#Dégradé_sans_nom_28"
        />
        <linearGradient
          id="Dégradé_sans_nom_13"
          data-name="Dégradé sans nom 13"
          x1="166.36"
          y1="257.88"
          x2="176.45"
          y2="276.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#bf714d" />
          <stop offset="1" stop-color="#6b3f2b" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_19"
          data-name="Dégradé sans nom 19"
          x1="161.77"
          y1="261.55"
          x2="171.86"
          y2="278.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#e8deb7" />
          <stop offset="1" stop-color="#8f8455" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_7"
          data-name="Dégradé sans nom 7"
          x1="215.56"
          y1="253.53"
          x2="220.61"
          y2="269.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#6f6f6e" />
          <stop offset="1" stop-color="#3c3c3b" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_13-2"
          x1="270.19"
          y1="245.78"
          x2="276.75"
          y2="266.46"
          xlink:href="#Dégradé_sans_nom_13"
        />
        <linearGradient
          id="Dégradé_sans_nom_7-2"
          x1="237.03"
          y1="219.66"
          x2="262.5"
          y2="269.86"
          xlink:href="#Dégradé_sans_nom_7"
        />
        <linearGradient
          id="Dégradé_sans_nom_19-2"
          x1="222.02"
          y1="272.51"
          x2="249.35"
          y2="272.51"
          xlink:href="#Dégradé_sans_nom_19"
        />
        <linearGradient
          id="Dégradé_sans_nom_13-3"
          x1="222.02"
          y1="268.08"
          x2="249.35"
          y2="268.08"
          xlink:href="#Dégradé_sans_nom_13"
        />
        <linearGradient
          id="Dégradé_sans_nom_55"
          data-name="Dégradé sans nom 55"
          x1="323.93"
          y1="253.42"
          x2="315.79"
          y2="241.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#78715d" />
          <stop offset="1" stop-color="#b3aa8b" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_45"
          data-name="Dégradé sans nom 45"
          x1="311.72"
          y1="243.03"
          x2="308.19"
          y2="235.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fbe0c6" />
          <stop offset="1" stop-color="#ebba8d" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_37"
          data-name="Dégradé sans nom 37"
          x1="288.91"
          y1="241.88"
          x2="296.22"
          y2="261.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#6fab45" />
          <stop offset="1" stop-color="#406329" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_45-2"
          x1="291.24"
          y1="240.99"
          x2="297.05"
          y2="249.03"
          xlink:href="#Dégradé_sans_nom_45"
        />
        <linearGradient
          id="Dégradé_sans_nom_45-3"
          x1="281.02"
          y1="242.04"
          x2="296.15"
          y2="241.79"
          xlink:href="#Dégradé_sans_nom_45"
        />
        <linearGradient
          id="Dégradé_sans_nom_38"
          data-name="Dégradé sans nom 38"
          x1="299.99"
          y1="248.82"
          x2="312.85"
          y2="266.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#6fab45" />
          <stop offset="0.85" stop-color="#406329" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_50"
          data-name="Dégradé sans nom 50"
          x1="295.55"
          y1="229.46"
          x2="317.1"
          y2="229.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff2c7" />
          <stop offset="1" stop-color="#968f75" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_50-2"
          x1="297.22"
          y1="222.88"
          x2="307.45"
          y2="229.38"
          xlink:href="#Dégradé_sans_nom_50"
        />
        <symbol id="AppleSymbole" data-name="AppleSymbole" viewBox="0 0 20 25.95">
          <path d="M18.49,1.05A5.1,5.1,0,0,1,15.71,5.7a5.07,5.07,0,0,1-5.4-.49,5.08,5.08,0,0,1,8.18-4.16Z" fill="#90c14e" fill-rule="evenodd" />
          <path d="M18.49,1.05A5.1,5.1,0,0,1,15.71,5.7a5.07,5.07,0,0,1-5.4-.49h0Z" fill="#74b62b" fill-rule="evenodd" />
          <path d="M9.33,6A.67.67,0,0,1,10,5.28a.66.66,0,0,1,.66.67v6.66a.66.66,0,0,1-.66.67.67.67,0,0,1-.67-.67Z" fill="#c35716" />
          <path d="M10,6l.4.16a.89.89,0,0,1,.27.63c0,1.92,0,3.83,0,5.75a.88.88,0,0,1-.26.63l-.4.16Z" fill="#803811" fill-rule="evenodd" />
          <path d="M0,16.9c0-2.67,1.85-7.32,3.72-8.32s3.79-.05,6.07-.05,6-1.34,7.62.05,3.21,5.51,2.33,8.32S18.66,22.77,17,24.44s-5.15,1.73-7,1.23c-1.84.29-4.35.61-6-1.07S.05,19.57,0,16.9Z" fill="#d42116" fill-rule="evenodd" />
          <path d="M16,8a2.78,2.78,0,0,1,1.53.59,9.52,9.52,0,0,1,2.27,8.32c-.85,2.8-1.05,5.87-2.65,7.54s-5,1.73-6.8,1.23l-.3,0V8.52h.07C11.73,8.53,14.16,7.77,16,8Z" fill="#be1616" fill-rule="evenodd" />
        </symbol>
        <symbol id="ApplePathSymbol" data-name="ApplePathSymbol" viewBox="0 0 176.75 170.08">
          <path
            id="ApplePath"
            d="M.5,0V74.07s4.73-8.86,13.17-8.86c13.18,0,14.59,46.36,14.59,46.36,5-3.48,12.85-3,18.54.57,0,0,120,.17,120.45,57.94"
            fill="none"
            stroke="none"
            fill-rule="evenodd"
          />
        </symbol>
      </defs>
      <g id="Scene">
        <path d="M237.61,322.76c4.86-41.77,63.54-85.4,141.93-87.93s146.8,37,141.94,78.78-72.35,77.68-150.74,80.2S232.74,364.53,237.61,322.76Z" fill="#a7c7d3" />
        <path d="M249.84,337.69c5.09-41.72,69-86.64,143.15-89.89s130.4,36.4,125.31,78.11S449,404.07,374.86,407.33,244.76,379.4,249.84,337.69Z" fill="#78a9bb" />
        <ellipse
          cx="137.31"
          cy="316.43"
          rx="144.02"
          ry="76.71"
          transform="translate(-25.41 12.28) rotate(-4.69)"
          fill="#c0d7df"
        />
        <ellipse
          cx="145.7"
          cy="324.74"
          rx="141.77"
          ry="76.7"
          transform="translate(-25.4 12.64) rotate(-4.57)"
          fill="#aeccd6"
        />
        <rect x="316.01" y="47.53" width="19.33" height="283.29" fill="#5e9aaf" />
        <rect x="326" y="47.53" width="9.33" height="283.29" fill="#366879" />
        <circle cx="326" cy="86.19" r="103.32" fill="#a0c3cf" />
        <path d="M326-17.13a103.32,103.32,0,1,1,0,206.63Z" fill="#5e9aaf" fill-rule="evenodd" />
        <ellipse
          cx="278.38"
          cy="311"
          rx="182.77"
          ry="55.53"
          transform="matrix(1, -0.06, 0.06, 1, -19.42, 18.54)"
          fill="#d7e6eb"
        />
        <ellipse
          cx="289.94"
          cy="320.03"
          rx="179.9"
          ry="55.53"
          transform="translate(-19.98 19.31) rotate(-3.69)"
          fill="#8eb7c6"
        />
        <use width="20" height="25.95" transform="translate(283.97 16.09)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(244.92 42.08)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(283.97 70.68)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(244.92 96.75)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(344.55 16.09)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(383.89 42.08)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(344.55 70.68)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(383.89 96.75)" xlink:href="#AppleSymbole" class="Apple" />
        <use width="20" height="25.95" transform="translate(344.55 126.47)" xlink:href="#AppleSymbole" class="Apple" />
      </g>
      <g id="Character">
        <path d="M219.94,236.12c3.76.24,4.68,1.18,6.66,4.27l-3.88.93-18.34,26.82c.06,0,0,2.48.4,2.14V273c-7.09,1.31-17.09,1.17-24.27.81.27-3.95-.7-4.24-2.87-7.14-6.16-11.17-9.53-26.46,5.84-31.3l5.8-.2,5.44,2c1.32,1.21,4.73,4.12,5.17,5.93l2.7.67c-.24.73.76.84,1.2,1.07,1.09,10.47-2.55,10.21,6.34,9.49L220.55,240ZM191,237.85c-6-1.19-10,.8-14.47,4.63-3.25,6.86-1,14.06,2.21,20.44l1.8,1.09c0-.33-.51-9.16-.29-9.1-.23-2.08-.73-6.44-.95-8.49l1.37-2.25c3.62-.46,11,.74,13.79-1.87l1.26,0-1-2.42Z" fill="url(#Dégradé_sans_nom_28)" />
        <path d="M195.68,242.16a61.79,61.79,0,0,1,6.91,1.58c-.24.73.76.84,1.2,1.07.3,5.71.57,18,.58,23.86.21.14-.09,1.8.41,1.61V273c-3.59.54-11.09,1.33-14.69,1.09l-9.58-.28c.08-.74-.24-3.09,0-3.68,0-6,0-14-.8-20.09-.31-2.42-.74-3.71,1-5.82l7.22-.18,5.17-.53,1.4-1.16S196.09,242.47,195.68,242.16Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_28-2)" />
        <path d="M176.69,268.16c7.92-.06,4.72-.08,5.34,7.33H178.1Z" fill="#030404" fill-rule="evenodd" />
        <path d="M181.16,274.73a15.58,15.58,0,0,1-3.35-8.25c-6.93-2.2-9.29-5.2-14.39-9.65l-1.39.7,3.21,10.35,8.38,11.61c1.29-.41,7.8-2.64,9.07-2.24Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_13)" />
        <path d="M164.65,262.7l4,7.32c3,2.18,6.61,5.3,6.08,9.43-3.73,2.36-7-2.86-9.19-5.07-3.24-4.28-5.43-10.82-4.73-16.2l2-.69Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_19)" />
        <path d="M257.54,250.16c-4.37.65-31.32,6.76-35.11,5.46-11.19,5.08-29.17,12.05-41.67,11.9l-1.4,7.28,1.68,3.36c6.41.1,20.3-2.73,26.57-4.34,7.36-1.25,22-4.59,29.23-6.58L258.1,267,262,264.3Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_7)" />
        <polygon points="238.68 264.32 238.68 259.49 247.1 259.84 247.35 266.65 240.53 266.82 238.68 264.32" fill="#030404" fill-rule="evenodd" />
        <path d="M265.35,246.44l5.58-.28,9.08,9.53-2.93,12.47-6.57-.28C272.46,251.82,276.19,257.58,265.35,246.44Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_13-2)" />
        <path d="M276.68,246.83c4-3.23,2.67-4.66,6.66-.28Z" fill="#538052" fill-rule="evenodd" />
        <path d="M277.87,259.73l-3.6-.14-.56-6c-1.71-1.55-4.2-6.69-6.93-5.86-5.23-8.47-12.31-20.74-18.84-28.31l-4.72-1.25c-4.9,2.21-4.36,4.28-6.23,8.64,0,8.61.51,18.5-1,27.06l1.11,1.95-.56,3.35c3.67,2.31,6.16,1.71,10.12,1.39,2.9-4.28-.24-12.93,1.39-18.4l4.57,11.15,2.63,10.18,3.19,5.44,6,.56,7.62-1.67c.47-1.38,2.53-5.51,2.36-7l3.6.84Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_7-2)" />
        <path d="M235.77,275.41C232.32,275.1,224,276.77,222,273l.35-1.08,9.23,1.25c2-.43,16.25-2.74,17.75-3.67V271A139.83,139.83,0,0,1,235.77,275.41Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_19-2)" />
        <path d="M222,272c1.35-2.7,2.66-2.17,5.2-2.6,3.35-1,7.84-3.92,9.23-7.25,4.73.41,2.69,2.76,8.24,3.22,2.49-.41,3.09-.44,2.87-3.22l1.25,2.51.54,4.92c-2.24,1-6.63,2.09-9,2.87C233.76,274.92,228.58,274.19,222,272Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_13-3)" />
        <path d="M316.32,232.83c2,1.93,7.09,4.61,9.68,5.64-2.23,3.74-1.22,4.13,0,7.84-4.28,10.55,1.68,11.46-6.83,12.52l-4.7-.83-1.14-7.56-4-3v-7.84Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_55)" />
        <path d="M310.59,249.49l1.11-11.63,3.61-2.35c.55-6,2.21-5.18-4-7.35l-6,8.31-6,1.53C301.66,242.78,305.62,247.35,310.59,249.49Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_45)" />
        <path d="M310.67,242.83l2.76-3.83c1.59-1.38,3.51-3.41,3.91-5.61-.17-2-.12-5.14-1.89-6.56l-3.76,5.2,2.75.27c-.06,2.11-.6,3.64-2.75,4.38Z" fill="#030404" fill-rule="evenodd" />
        <path d="M269.35,246.05a47.83,47.83,0,0,0,12-.69,36.25,36.25,0,0,1,6.95-2.21c3.51-.31,10.53-1,13.92-1.65,3.09,1.45,7.48,3.18,9,6.49a13.72,13.72,0,0,1,6.12,5.8c-1.06,2.84-7.47,6.18-9.88,7.6-1,.18-7.84,2.46-8.76,2.07-5.78,1.38-14,4-20,4.28l-3.62.42,2.22-7.32-.55-7.46C275.42,251.69,271.54,246.58,269.35,246.05Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_37)" />
        <path d="M301.34,248.22c-1.9-6.26-4-9.26-10.38-9.47-1.29,0-3.51.18-4.12,1.85-2.28,4.5.93,1.77,5.38,3.88,3,1.34,3.38,2.9,4.56,5.68Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_45-2)" />
        <path d="M281.83,245.5,280,244l3.63-3.59c1.15-.62,4.49-2.28,7.71-1.91.5.05,1.07.18,2.2.42,3.27.71,3.65,1.13,3.73,1.47a1.14,1.14,0,0,1-.36,1c-.86-.51-1.55-.93-2-1.2a6,6,0,0,0-2.47-1.17c-.61-.08-1.31,0-1.34.17s1.18.49,2.95,1.51c1.5.87,1.52,1.19,1.5,1.31-.06.28-.53.43-.93.51a14.76,14.76,0,0,0-1.53-1.06,14.42,14.42,0,0,0-1.33-.71,9.27,9.27,0,0,0-2.38-1c-.54-.1-1.33-.25-1.64.16a.9.9,0,0,0-.07.76h.42a7.2,7.2,0,0,1,2.44.51c.14.06.25.1.4.18,1.08.53,2.21,1.67,2,2.49a1,1,0,0,1-.47.56c-.82-.9-2.66-2.44-3.91-2.63a9.27,9.27,0,0,0-2.1.55l3.35,1c.92,1.2,1.72,1.85-.56,1.86C286.68,242.7,284.47,244,281.83,245.5Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_45-3)" />
        <path d="M305.74,256.59l3.69-3.16c1.92-.43,5.64-.68,7.5.14.69,1.49-.06,5.09-.41,6.6-4.29,5.37-8.19,6.41-14.74,7.32l-1.63-.29L298.51,263c-.66-10.2-2.68-5.09-4.5-12.34,3.11-2.54,3.67-4.24,8.18-3.87C303.27,247.88,305.24,255.11,305.74,256.59Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_38)" />
        <path d="M301.84,224c3.87-3.85,4.31-5.11,10.1-5.84l4.43,1.8c1.52,4.19.42,5.63-1,9.46-3.1,4.61-8.75,9.44-14.11,11.12a5.18,5.18,0,0,1-5-1.11,13.31,13.31,0,0,1-.14-8.48C297,229.57,300.77,225.29,301.84,224Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_50)" />
        <path d="M298.81,233.5a67.77,67.77,0,0,0-4.14-5.8l.86-5.8c3.46-2.8,5-3.43,9.4-3.73l6,5.24.43,4C306.56,232.62,305.32,232.17,298.81,233.5Z" fill-rule="evenodd" fill="url(#Dégradé_sans_nom_50-2)" />
        <path d="M309.31,222.17l2,1.41-.58,4.51c-2.85,2.9-7.21,6.77-11.59,6.07L298,231.9c4.62.86,5.21,0,9-2.25C308.52,227.14,309.75,225.31,309.31,222.17Z" fill="#30271c" fill-rule="evenodd" />
      </g>
      <g id="Apple" transform="translate(303.31 147.4)">
        <use width="20" height="25.95" transform="translate(-10 -12.975)" xlink:href="#AppleSymbole" />
      </g>
      <use width="176.75" height="170.08" transform="translate(302.81 147.4)" xlink:href="#ApplePathSymbol" />
      <animateTransform
        class="animation-group-1"
        xlink:href="#Apple"
        attributeName="transform"
        attributeType="XML"
        type="scale"
        begin="indefinite"
        keyTimes="0;0.1;0.88;0.89;0.91;0.98;1"
        values="1;0.5;0.5;0;0;1.5;1"
        dur="5s"
        repeatCount="0"
        additive="sum"
      />
      <animateMotion
        xlink:href="#Apple"
        begin="indefinite"
        class="animation-group-2"
        dur="3s"
        keyPoints="0;0.24;0.45;0.51;1"
        keyTimes="0;0.20;0.30;0.40;1"
        keySplines=".9 0 0.9 .6 ; .3 .4 1 .5 ; 0 0 1 1 ; 0 0 1 1"
        calcMode="spline"
        repeatCount="0"
        additive="sum"
      >
        <mpath xlink:href="#ApplePath" />
      </animateMotion>
      <animateTransform
        xlink:href="#Apple"
        class="animation-group-2"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        begin="indefinite"
        keyTimes="0;0.24;0.51;1"
        values="0;45;360;800"
        dur="3s"
        repeatCount="0"
        additive="sum"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    tasksDone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeouts: [],
    };
  },
  mounted() {
    /* Load the Apples */
    let delay = 0;
    this.$refs.illustrationTodoContainer.querySelectorAll('.Apple').forEach((elem) => {
      this.timeouts.push(window.setTimeout(() => { elem.classList.add('visible'); }, delay));
      delay += 250;
    });
    this.timeouts.push(window.setTimeout(() => { this.$refs.illustrationTodoContainer.querySelector('#Apple').classList.add('visible'); }, delay));
    /* Listen to click on the Apple */
    this.$refs.illustrationTodoContainer.querySelector('#Apple').addEventListener('click', () => {
      if (this.tasksDone) {
        this.$store.dispatch('trackEvent', { name: 'newton' });
        this.$refs.illustrationTodoContainer.querySelectorAll('.animation-group-1').forEach((elem) => { elem.beginElement(); });
        this.timeouts.push(window.setTimeout(() => { this.$refs.illustrationTodoContainer.querySelectorAll('.animation-group-2').forEach((elem) => { elem.beginElement(); }); }, 1500));
      }
    });
  },
  beforeDestroy() {
    if (this.timeouts.length) {
      this.timeouts.forEach((timeout) => { clearTimeout(timeout); });
    }
  },
};
</script>

<style lang="less">
#illustration-todo-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;

  svg {
    height: 100%;
    margin: 0 -100%;

    .device-with-keyboard .keyboard-focused & {
      height: calc(100% + var(--keyboard-height, 0px) - (var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px)));
    }
  }

  #Character {
    transition: opacity 2s ease;
    transition-delay: 1s;
  }

  .hide-character #Character {
    opacity: 0;
  }

  .Apple, #Apple {
    opacity: 0;
    transition: opacity 1s ease;
  }

  .Apple.visible, #Apple.visible {
    opacity: 1;
  }
}
</style>
