import { firestore, functions } from './firebase-config'; // REDUCE SIZE

import DBObject from '../class/dbOject.class';
import Contact from '../class/contact.class';
import DBEvent from '../class/dbEvent.class';
import Interaction from '../class/interaction.class';
import Memo from '../class/memo.class';
import Mention from '../class/mention.class';
import UserDoc from '../class/userDoc.class';
import {
  dbEventConverter,
  contactConverter,
  userDocConverter,
} from '../class/factory';
import { supportedVersion } from '../class/utils';

function checkObject(storeGetters, object, checks) {
  // We check if the user is authenticated
  if (checks.includes('user-connected') && (!storeGetters.user || !storeGetters.user.uid)) {
    throw (new Error('user-not-connected'));
  }
  // We check if the object is from the DB
  if (checks.includes('is-dbobject') && !(object instanceof DBObject)) {
    throw (new Error('is-not-dbobject'));
  }
  // We check if the object is a Contact
  if (checks.includes('is-contact') && !(object instanceof Contact)) {
    throw (new Error('is-not-contact'));
  }
  // We check if the object is an Event
  if (checks.includes('is-event') && !(object instanceof DBEvent)) {
    throw (new Error('is-not-event'));
  }
  // We check if the object is an Interaction
  if (checks.includes('is-interaction') && !(object instanceof Interaction)) {
    throw (new Error('is-not-interaction'));
  }
  // We check if the object is a Memo
  if (checks.includes('is-memo') && !(object instanceof Memo)) {
    throw (new Error('is-not-memo'));
  }
  // We check if the object has an id
  if (checks.includes('has-id') && !object.id) {
    throw (new Error('id-missing'));
  }
  // We check if the object is ready to be exported
  if (checks.includes('ready-to-export') && !object.isReadyToExportToDB()) {
    throw (new Error('information-missing'));
  }
}

function batchSave(storeGetters, object, batch = firestore().batch()) {
  // We check the object is ready for the operation
  checkObject(storeGetters, object, ['user-connected', 'is-dbobject', 'ready-to-export']);
  // We complete the batch
  let docRef = null;
  if (object.id) {
    docRef = firestore().doc(`users/${storeGetters.user.uid}/${object.collection}/${object.id}`);
  } else {
    docRef = firestore().collection(`users/${storeGetters.user.uid}/${object.collection}`).doc();
  }
  batch.set(docRef, object.exportToDB());
  return batch;
}

/* eslint-disable no-param-reassign */
function batchUpdate(storeGetters, object, update, batch = firestore().batch()) {
  // We check the object is ready for the operation
  checkObject(storeGetters, object, ['user-connected', 'is-dbobject', 'has-id']);
  // We complete the batch
  update['metadata.updated'] = new Date(); // firestore.FieldValue.serverTimestamp();
  update.v = supportedVersion;
  const docRef = firestore().doc(`users/${storeGetters.user.uid}/${object.collection}/${object.id}`);
  batch.update(docRef, update);
  return batch;
}
/* eslint-enable no-param-reassign */

function batchDelete(storeGetters, object, batch = firestore().batch()) {
  // We check the object is ready for the operation
  checkObject(storeGetters, object, ['user-connected', 'is-dbobject', 'has-id']);
  // We complete the batch
  const docRef = firestore().doc(`users/${storeGetters.user.uid}/${object.collection}/${object.id}`);
  batch.set(docRef, object.exportToDBDeleted());
  return batch;
}

function getContactLastInteraction(storeGetters, contactId, interactionBefore, interactionAfter) {
  let lastInteraction = null;
  // We get the latest completed interaction of the contact that is not the old one
  storeGetters.interactions.forEach((interaction) => {
    if (
      interaction.completed
      && (!interactionBefore || interaction.id !== interactionBefore.id)
      && interaction.contacts.includes(contactId)
      && (!lastInteraction || lastInteraction.date < interaction.date)
    ) {
      lastInteraction = interaction;
    }
  });
  // If the new interaction is more recent than the found last interaction
  if (
    interactionAfter
    && interactionAfter.completed
    && (!lastInteraction || lastInteraction.date < interactionAfter.date)
  ) {
    lastInteraction = interactionAfter;
  }
  // We return the last interaction of the contact
  return lastInteraction;
}

function contactsImpacted(eventBefore = null, eventAfter = null) {
  // We list the contacts that are impacted
  let impactedContacts = [];
  // The event has been deleted and was a completed interaction
  if (!eventAfter && eventBefore.isInteraction && eventBefore.completed) {
    impactedContacts = eventBefore.contacts;
  // The event has been created, and is a completed interaction
  } else if (!eventBefore && eventAfter.isInteraction && eventAfter.completed) {
    impactedContacts = eventAfter.contacts;
  // The event has been updated
  } else if (eventBefore && eventAfter) {
    // The event is no more a completed interaction, so we remove it from the previous contact
    if ((eventBefore.isInteraction && eventBefore.completed) && (!eventAfter.isInteraction || !eventAfter.completed)) {
      impactedContacts = eventBefore.contacts;
    // The event is now a completed interaction, so we check if it is the latest for the after contacts
    } else if ((!eventBefore.isInteraction || !eventBefore.completed) && (eventAfter.isInteraction && eventAfter.completed)) {
      impactedContacts = eventAfter.contacts;
    // Interaction was a completed interaction before and after
    } else if (eventBefore.isInteraction && eventBefore.completed && eventAfter.isInteraction && eventAfter.completed) {
      // If the date has changed
      if (eventBefore.date.getTime() !== eventAfter.date.getTime()) {
        // Impacted contacts are both the contacts present before and after
        impactedContacts = [...new Set([...eventBefore.contacts, ...eventAfter.contacts])];
      // If the date is the same
      } else {
        // Impacted contacts are those removed and those added
        eventBefore.contacts.forEach(contact => (eventAfter.contacts.includes(contact) ? null : impactedContacts.push(contact)));
        eventAfter.contacts.forEach(contact => (eventBefore.contacts.includes(contact) ? null : impactedContacts.push(contact)));
      }
    } // Note : if the event has never been marked completed or is not an interaction, it is not the "last" of any contact
  }
  console.log('Contacts Impacted:', impactedContacts);
  return impactedContacts;
}

function batchUpdateLastInteraction(storeGetters, interactionBefore, interactionAfter, batch) {
  const impactedContacts = contactsImpacted(interactionBefore, interactionAfter);
  if (impactedContacts.length) {
    impactedContacts.forEach((contactId) => {
      const contact = storeGetters.contactById(contactId);
      if (contact) {
        const lastInteraction = getContactLastInteraction(storeGetters, contact.id, interactionBefore, interactionAfter);
        // If at least one lastInteraction is defined (otherwise, it hasn't changed and its still null)
        if (contact.lastInteraction || lastInteraction) {
          // If the last interaction has changed (one is null or they both exists but are not the same date)
          if (!contact.lastInteraction || !lastInteraction || contact.lastInteraction.date.getTime() !== lastInteraction.date.getTime()) {
            // We add the update of the interaction in the batch
            batchUpdate(storeGetters, contact, {
              lastInteraction: lastInteraction ? {
                date: lastInteraction.date,
                id: lastInteraction.id,
              } : null,
            }, batch);
          }
        }
      }
    });
  }
  return batch;
}

export default {
  state: {
    contacts: [],
    events: [],
    userDoc: new UserDoc(JSON.parse(localStorage.getItem('userDoc')) || {}),
    latestUpdateSynced: new Date(parseInt(localStorage.getItem('latestUpdateSynced'), 10) || 0),
    unbindListeners: {
      contacts: null,
      events: null,
      userDoc: null,
    },
    dbLoaded: {
      contacts: false,
      events: false,
      userDoc: false,
    },
  },
  mutations: {
    objectsInit: (state, { collection, objects }) => {
      state[collection] = objects;
      console.log('Objects from cache', collection, objects.length);
    },
    objectChange: (state, { collection, doc, type }) => {
      console.log('Object change received');
      // Get the changedObject
      const changedObject = doc.data({ serverTimestamps: 'estimate' });
      // If the object was already in the collection, we find it and remove it
      // Note: we cannot use the newIndex / oldIndex, since they are only relative to the recents items
      const currentStoreIndex = state[collection].findIndex(object => object.id === doc.id);
      if (currentStoreIndex >= 0) state[collection].splice(currentStoreIndex, 1);
      // If the object is not deleted, we add it to the collection
      if (type !== 'removed' && changedObject) {
        state[collection].push(changedObject);
      }
    },
    userDoc: (state, userDoc) => {
      if (userDoc instanceof UserDoc) {
        state.userDoc = userDoc;
        localStorage.setItem('userDoc', JSON.stringify(userDoc.exportToDB()));
        console.log('userDoc updated', userDoc);
      } else throw new Error('not-a-UserDoc');
    },
    latestUpdate: (state, updateDate) => {
      if (updateDate > state.latestUpdateSynced) {
        state.latestUpdateSynced = updateDate;
        localStorage.setItem('latestUpdateSynced', state.latestUpdateSynced.getTime());
      }
    },
    dbLoaded: (state, { collection, loaded }) => {
      state.dbLoaded[collection] = loaded;
    },
    setUnbindListeners: (state, { collection, unbind }) => {
      state.unbindListeners[collection] = unbind;
    },
    resetObjects: (state, collection) => {
      if (collection === 'userDoc') {
        state.userDoc = new UserDoc();
        localStorage.removeItem('userDoc');
      } else state[collection] = [];
    },
  },
  actions: {
    async bindObjects({ getters, commit, dispatch }, { collection, firestoreRef, resync = false }) {
      if (getters.user && getters.user.uid) {
        // If collection is already binded, we unbind it
        if (getters.unbindListeners[collection]) dispatch('unbindObjects', collection);
        let firestoreRefRecent = firestoreRef;
        if (!resync) {
        // We get all the existing objects from the cache
          const querySnapshot = await firestoreRef.get({ source: 'cache' });
          const objects = [];
          let mostRecent = null;
          querySnapshot.forEach((doc) => {
            const object = doc.data();
            // If the object is not a deleted placeholder
            if (object) {
            // We add it to the collection
              objects.push(object);
              // And we check if it is the most recently updated object (note: object.metadata.updated can be null if not sync with server yet)
              mostRecent = mostRecent && mostRecent > object.metadata.updated ? mostRecent : object.metadata.updated;
            }
          });
          commit('latestUpdate', mostRecent);
          commit('objectsInit', { collection, objects });
          // We subscribe to realtime update for documents that were not in the cache
          firestoreRefRecent = mostRecent ? firestoreRef.where('metadata.updated', '>', mostRecent) : firestoreRef;
        }
        const unbind = firestoreRefRecent.onSnapshot({ includeMetadataChanges: true },
          (ref) => {
            ref.docChanges({ includeMetadataChanges: true }).forEach((change) => {
              const changedObject = change.doc.data();
              if (changedObject) { // If the object is deleted, we cannot access its metadata
                commit('latestUpdate', changedObject.metadata.updated);
              }
              commit('objectChange', { collection, doc: change.doc, type: change.type });
            });
            commit('dbLoaded', { collection, loaded: true });
          },
          error => console.error(error));
        commit('setUnbindListeners', { collection, unbind });
      }
    },
    unbindObjects({ getters, commit }, collection) {
      // Unsubscribe from realtime update
      if (getters.unbindListeners[collection]) {
        getters.unbindListeners[collection]();
        commit('setUnbindListeners', { collection, unbind: null });
      }
      // Reset the collection
      commit('resetObjects', collection);
      commit('dbLoaded', { collection, loaded: false });
    },
    bindContacts({ getters, dispatch }, { resync = false } = {}) {
      if (getters.user && getters.user.uid) {
        dispatch('bindObjects', {
          collection: 'contacts',
          firestoreRef: firestore()
            .collection(`users/${getters.user.uid}/contacts`)
            .orderBy('metadata.updated', 'desc')
            .limit(500)
            .withConverter(contactConverter),
          resync,
        });
      }
    },
    unbindContacts({ dispatch }) {
      return dispatch('unbindObjects', 'contacts');
    },
    bindEvents({ getters, dispatch }, { resync = false } = {}) {
      if (getters.user && getters.user.uid) {
        dispatch('bindObjects', {
          collection: 'events',
          firestoreRef: firestore()
            .collection(`users/${getters.user.uid}/events/`)
            .orderBy('metadata.updated', 'desc')
            .limit(1000)
            .withConverter(dbEventConverter),
          resync,
        });
      }
    },
    unbindEvents({ dispatch }) {
      return dispatch('unbindObjects', 'events');
    },
    bindUserDoc({ getters, dispatch, commit }) {
      if (getters.user && getters.user.uid) {
        // If collection is already binded, we unbind it
        if (getters.unbindListeners.userDoc) dispatch('unbindUserDoc');
        // We subscribe to realtime update
        const unbind = firestore().doc(`users/${getters.user.uid}`).withConverter(userDocConverter)
          .onSnapshot((doc) => {
            if (doc.exists) {
              const userDoc = doc.data();
              commit('userDoc', userDoc);
              if (userDoc.lastDelClean > getters.latestUpdateSynced) {
                // If the last "deleted" cleaning was more recent than the latest synced update, we resync the store
                dispatch('resyncStore');
              }
            } else commit('resetObjects', 'userDoc');
            commit('dbLoaded', { collection: 'userDoc', loaded: true });
          }, error => console.error(error));
        commit('setUnbindListeners', { collection: 'userDoc', unbind });
      }
    },
    unbindUserDoc({ dispatch }) {
      return dispatch('unbindObjects', 'userDoc');
    },
    deleteContact({ getters, dispatch }, contact) {
      checkObject(getters, contact, ['is-contact']);
      batchDelete(getters, contact).commit();
      dispatch('trackEvent', { name: 'delete_contact' });
    },
    deleteEvent({ getters, dispatch }, event) {
      checkObject(getters, event, ['is-event']);
      // We prepare a batch update
      const batch = firestore().batch();
      // This update could impact the last interaction of some contacts. We add them to the batch
      if (event.isInteraction) batchUpdateLastInteraction(getters, event, null, batch);
      // We delete the event itself
      batchDelete(getters, event, batch);
      // We commit the batch
      batch.commit();
      // We log the event
      dispatch('trackEvent', { name: 'delete_event' });
    },
    saveContact({ getters, dispatch }, contact) {
      checkObject(getters, contact, ['is-contact']);
      batchSave(getters, contact).commit();
      const properties = {
        frequency: contact.frequency,
        hasPhone: !!contact.phone,
        hasEmail: !!contact.email,
        hasLinkedin: !!contact.social.linkedin,
        hasFacebook: !!contact.social.facebook,
        hasTwitter: !!contact.social.twitter,
        hasHashtags: !!contact.hashtags.length,
        hashtags: contact.hashtags.length,
        hasMentions: !!contact.mentions.length,
        mentions: contact.mentions.length,
      };
      if (contact.id) {
        properties.scope = 'global';
        dispatch('trackEvent', {
          name: 'update_contact',
          properties,
        });
      } else {
        dispatch('trackEvent', {
          name: 'new_contact',
          properties,
        });
      }
    },
    importContacts({ getters, dispatch }, contacts) {
      // We prepare a batch save
      const batch = firestore().batch();
      // We add each contact to the batch
      contacts.forEach((contact) => {
        checkObject(getters, contact, ['is-contact']);
        batchSave(getters, contact, batch);
      });
      // We commit the batch
      batch.commit();
      // We log the event
      dispatch('trackEvent', { name: 'import_contacts', properties: { number: contacts.length } });
    },
    saveEvent({ getters, dispatch }, event) {
      checkObject(getters, event, ['is-event']);
      // We prepare a batch update
      const batch = firestore().batch();
      // This update could impact the last interaction of some contacts. We add them to the batch
      batchUpdateLastInteraction(getters, getters.eventById(event.id), event, batch);
      // We update the event itself
      batchSave(getters, event, batch);
      // We commit the batch
      batch.commit();
      // We log the event
      const properties = {
        type: event.type,
        completed: event.completed,
        via: event.via,
        hasMultipleContacts: event.contacts.length && event.contacts.length > 1,
        contacts: event.contacts.length,
        hasHashtags: !!event.hashtags.length,
        hashtags: event.hashtags.length,
        hasMentions: !!event.mentions.length,
        mentions: event.mentions.length,
      };
      if (event.id) {
        properties.scope = 'global';
        dispatch('trackEvent', {
          name: 'update_event',
          properties,
        });
      } else {
        dispatch('trackEvent', {
          name: 'new_event',
          properties,
        });
      }
    },
    freezeContact({ getters, dispatch }, contactId) {
      const contact = getters.contactById(contactId);
      batchUpdate(getters, contact, { frequency: 0, postponed: null }).commit();
      dispatch('trackEvent', { name: 'update_contact', properties: { scope: 'frequency', frequency: 0 } });
    },
    // Postpone the event to next day / week / month (if postponed === 1 / 7 /30 )
    postponeEvent({ getters, dispatch }, { event, postponed }) {
      if (!(event instanceof DBEvent) || event.completed || !Number.isInteger(postponed) || postponed < 0 || postponed > 50) throw (new Error('invalid-postponed'));
      const postponedDate = new Date();
      postponedDate.setDate(postponedDate.getDate() + postponed);
      if (event.isReminder) {
        const contact = getters.contactById(event.contactId);
        batchUpdate(getters, contact, { postponed: postponedDate }).commit();
        dispatch('trackEvent', { name: 'update_contact', properties: { scope: 'postponed', postponed } });
      } else {
        batchUpdate(getters, event, { date: postponedDate }).commit();
        dispatch('trackEvent', { name: 'update_event', properties: { scope: 'postponed', type: event.type, postponed } });
      }
      return postponedDate;
    },
    // Spread the reminders for a list of contacts over a certain duration
    spreadReminders({ getters, dispatch }, { contacts, duration }) {
      if (!Number.isInteger(duration) || duration < 0 || duration > 500) throw (new Error('invalid-duration'));
      // We compute the interval in ms between 2 postponed reminders
      let interval = (duration - 1) * (24 * 60 * 60 * 1000); // We substract 1 because we postpone the first reminder to tomorrow and not today
      if (contacts.length > 1) interval /= contacts.length - 1; // there is a computationable interval only if there is at least 2 contacts
      // We prepare a batch update
      const batch = firestore().batch();
      // We initialize the postponed date to the last one
      let postponedDate = new Date();
      postponedDate.setDate(postponedDate.getDate() + duration);
      // We order the contacts from the lowest frequency of reminders (they will be spread the further) to the highest frequency
      contacts.sort((a, b) => b.frequency - a.frequency);
      // We postpone each contact
      for (const contact of contacts) {
        // if the duration is 0, we simply set the postponed to null and ignore the postponedDate
        const postponed = duration ? postponedDate : null;
        // The postpone has been modified if it has been moved of more than 1 hour
        const postponedModified = (postponed && contact.postponed && Math.abs(contact.postponed - postponed) > 60 * 60 * 1000)
          // Or if (it becomes null or was null) but not both at the same time
          || ((!postponed || !contact.postponed) && postponed !== contact.postponed);
        // We add it to the batch if the postponed value has changed
        if (postponedModified) batchUpdate(getters, contact, { postponed }, batch);
        // We decrease the postpone for the next one
        postponedDate = new Date(postponedDate.getTime() - interval);
      }
      // We commit the batch
      batch.commit();
      // We log the event
      dispatch('trackEvent', { name: 'update_contact', properties: { scope: 'spread', spread: duration } });
    },
    // Update the contact frequency
    updateContactFrequency({ getters, dispatch }, { contact, frequency }) {
      if (!(contact instanceof Contact) || !Number.isInteger(frequency) || frequency < 0) throw (new Error('invalid-frequency'));
      const update = { frequency };
      if (frequency === 0) update.postponed = null; // If the contact is frozen, we reset the postponed date
      batchUpdate(getters, contact, update).commit();
      dispatch('trackEvent', { name: 'update_contact', properties: { scope: 'frequency', frequency } });
    },
    // Update contact frequency
    batchUpdateContactsFrequency({ getters, dispatch }, { contacts, frequency }) {
      if (!Number.isInteger(frequency) || frequency < 0) throw (new Error('invalid-frequency'));
      const update = { frequency };
      if (frequency === 0) update.postponed = null; // If the contact is frozen, we reset the postponed date
      // We prepare a batch update
      const batch = firestore().batch();
      for (const contact of contacts) {
        if (!(contact instanceof Contact)) throw (new Error('not-contact'));
        batchUpdate(getters, contact, update, batch);
      }
      // We commit the batch
      batch.commit();
      // We log the event
      dispatch('trackEvent', { name: 'batch_update_contacts', properties: { scope: 'frequency', frequency } });
    },
    updateEventCompletion({ getters, dispatch }, { event, completed }) {
      checkObject(getters, event, ['is-event']);
      // We prepare the update
      const completedBool = !!completed; // We ensure completed is a boolean
      const date = completedBool && event.date > new Date() ? new Date() : event.date; // If the event is completed and in the future, we change the date
      const update = { completed: completedBool, date };
      // We prepare a batch update
      const batch = firestore().batch();
      // This update could impact the last interaction of some contacts. We add them to the batch
      if (event.isInteraction) batchUpdateLastInteraction(getters, event, Object.assign(new Interaction(), event, update), batch);
      // We update the event itself
      batchUpdate(getters, event, update, batch);
      // We commit the batch
      batch.commit();
      // We log the event
      dispatch('trackEvent', { name: 'update_event', properties: { scope: 'completed', type: event.type, completed } });
    },
    // Update the contact lastInteraction
    updateContactLastInteraction({ getters }, { contact, lastInteraction }) {
      if (!(contact instanceof Contact) || !(lastInteraction instanceof Interaction) || !lastInteraction.date || !lastInteraction.id) throw (new Error('invalid-last-interaction'));
      batchUpdate(getters, contact, {
        lastInteraction: {
          date: lastInteraction.date,
          id: lastInteraction.id,
        },
      }).commit();
    },
    updateNotes({ getters, dispatch }, object) {
      batchUpdate(getters, object, {
        notes: object.notes,
        hashtags: object.hashtags,
        mentions: object.mentions,
      }).commit();
      const event = {
        name: 'update_object',
        properties: {
          scope: 'notes',
          type: object.type,
          hasHashtags: !!object.hashtags.length,
          hashtags: object.hashtags.length,
          hasMentions: !!object.mentions.length,
          mentions: object.mentions.length,
        },
      };
      if (object instanceof DBEvent) event.name = 'update_event';
      else if (object instanceof Contact) event.name = 'update_contact';
      dispatch('trackEvent', event);
    },
    batchUpdateNotes({ getters, dispatch }, objects) {
      // We prepare a batch update
      const batch = firestore().batch();
      // We prepare all the updates
      for (const object of objects) {
        batchUpdate(getters, object, {
          notes: object.notes,
          hashtags: object.hashtags,
          mentions: object.mentions,
        }, batch);
      }
      // We commit the batch
      batch.commit();
      // We log the event
      dispatch('trackEvent', { name: 'batch_update_notes', properties: { scope: 'notes' } });
    },
    testConnection(context) {
      return new Promise((resolve, reject) => {
        if (context.getters.user && context.getters.user.uid) {
          firestore()
          // We try to get an inexisting document
            .doc(`users/${context.getters.user.uid}/contacts/testconnexion`)
            .get()
            .then(() => {
              console.log('Test connection successful');
              resolve();
            })
          // If there is an error, it's probably that the Database crashed. So we restart the app.
            .catch((error) => {
              if (!['cancelled', 'not-found', 'permission-denied', 'resource-exhausted', 'aborted', 'unavailable'].includes(error.code)) {
                console.error('App crashed. We reload it.');
                reject(new Error('iOS killed the connection.\r\nWe reload the app.'));
                window.location.reload();
              }
              resolve();
            });
        }
      });
    },
    getStats(context) {
      return new Promise((resolve, reject) => {
        if (context.getters.user && context.getters.user.uid) {
          firestore()
            .doc(`users/${context.getters.user.uid}`)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const userStats = doc.data().stats;
                resolve({
                  contacts: userStats.contacts ? userStats.contacts : 0,
                  interactions: userStats.interactions ? userStats.interactions : 0,
                });
              } else {
                resolve({
                  contacts: 0,
                  interactions: 0,
                });
              }
            })
            .catch((error) => {
              console.error('Error retrieving stats', error);
              reject(error);
            });
        } else {
          const error = new Error('User not identified');
          console.error('Error retrieving stats', error);
          reject(error);
        }
      });
    },
    getStatsMonth(context) {
      return new Promise((resolve, reject) => {
        if (context.getters.user && context.getters.user.uid) {
          firestore()
            .collection(`users/${context.getters.user.uid}/stats/`)
            .get()
            .then((querySnapshot) => {
              const hollowStatsMonth = {};
              const now = new Date();
              let firstMonth = now;
              let lastMonth = now;
              // We populate all the stats
              querySnapshot.forEach((doc) => {
                const month = new Date(doc.id);
                if (month < firstMonth) firstMonth = month;
                if (month > lastMonth) lastMonth = month;
                hollowStatsMonth[doc.id] = {
                  contact: doc.data().contact ? doc.data().contact : 0,
                  interaction: doc.data().interaction ? doc.data().interaction : 0,
                  memo: doc.data().memo ? doc.data().memo : 0,
                };
              });
              // We build a complete array (without missing month)
              const dataContacts = {};
              const dataInteractions = {};
              const dataMemos = {};
              // We create a full array
              const startYear = firstMonth.getFullYear();
              const startMonth = firstMonth.getMonth() + 1;
              const endYear = lastMonth.getFullYear();
              const endMonth = lastMonth.getMonth() + 1;
              for (let y = startYear; y <= endYear; y++) {
                for (let m = 1; m <= 12; m++) {
                  if (!(y <= startYear && m < startMonth) && !(y >= endYear && m > endMonth)) {
                    const month = `${y}-${(`0${m}`).slice(-2)}`; // AAAA-MM
                    dataContacts[month] = hollowStatsMonth[month] ? hollowStatsMonth[month].contact : 0;
                    dataInteractions[month] = hollowStatsMonth[month] ? hollowStatsMonth[month].interaction : 0;
                    dataMemos[month] = hollowStatsMonth[month] ? hollowStatsMonth[month].memo : 0;
                  }
                }
              }
              resolve([
                { name: 'New Contacts', data: dataContacts },
                { name: 'New Interactions', data: dataInteractions },
                { name: 'New Memos', data: dataMemos },
              ]);
            })
            .catch((error) => {
              console.error('Error retrieving stats', error);
              reject(error);
            });
        } else {
          const error = new Error('User not identified');
          console.error('Error retrieving stats', error);
          reject(error);
        }
      });
    },
    onboardingCompleted({ getters }) {
      firestore()
        .doc(`users/${getters.user.uid}`)
        .set({ onboarded: true }, { merge: true });
    },
    async getICalToken() {
      const sdkICalToken = functions.httpsCallable('sdkICalToken');
      const result = await sdkICalToken();
      return result.data;
    },
    async downloadBackup() {
      const sdkBackup = functions.httpsCallable('sdkBackup');
      const result = await sdkBackup();
      return result.data;
    },
    async uploadRestore(context, backup) {
      const sdkRestore = functions.httpsCallable('sdkRestore');
      const result = await sdkRestore(backup);
      return result.data;
    },
    async updateDB() {
      const sdkUpdateDB = functions.httpsCallable('sdkUpdateDB');
      const result = await sdkUpdateDB();
      return result.data;
    },
  },
  getters: {
    unbindListeners: state => state.unbindListeners,
    latestUpdateSynced: state => new Date(state.latestUpdateSynced),
    contacts: state => [...state.contacts].sort((a, b) => a.name.localeCompare(b.name)),
    contactById: (state, getters) => contactId => getters.contacts.find(contact => contact.id === contactId),
    contactsNextInteraction: (state, getters) => { // Associative array : contactId => date of the future interaction planned
      const contactsNextInteraction = {};
      getters.interactionsUpcoming.forEach((interaction) => {
        interaction.contacts.forEach((contactId) => {
          // We save the closest future interaction for each contact
          if (contactsNextInteraction[contactId]) {
            // If the interaction is closer than the currently registered interaction
            if (contactsNextInteraction[contactId] > interaction.date) {
              // We replace it
              contactsNextInteraction[contactId] = interaction.date;
            }
          } else {
            // If there is no registered interaction
            contactsNextInteraction[contactId] = interaction.date;
          }
        });
      });
      return contactsNextInteraction;
    },
    contactsReminders: (state, getters) => { // Return the array of reminders
      const reminders = [];
      const contacts = getters.contacts;
      contacts.forEach((contact) => {
        // If the contact is not frozen
        if (!contact.frozen) {
          const reminder = contact.futureInteractionReminder();
          // If there isn't any planned interaction or if the planned interaction is too far in the future
          if (!getters.contactsNextInteraction[contact.id] || reminder.date < getters.contactsNextInteraction[contact.id]) {
            // We add the reminder to the list
            reminders.push(reminder);
          }
        }
      });
      return reminders;
    },
    events: state => state.events,
    eventById: (state, getters) => eventId => getters.events.find(event => event.id === eventId),
    interactions: state => state.events.filter(event => event.isInteraction),
    interactionById: (state, getters) => interactionId => getters.interactions.find(interaction => interaction.id === interactionId),
    eventsOfContact: (state, getters) => (contactId) => {
      // We get all the events that include the contact
      let eventsOfContact = getters.events.filter(event => event.contacts.includes(contactId));
      // We add all the events that mention the
      eventsOfContact = eventsOfContact.concat(getters.events.filter(event => event.mentions.includes(contactId)).map(event => new Mention(event, event.id)));
      // We sort the events from the newest to the oldest
      eventsOfContact.sort((a, b) => b.date - a.date);
      return eventsOfContact;
    },
    eventsCompleted: (state, getters) => {
      // We get all the interactions that are completed
      const eventsCompleted = getters.interactions.filter(interaction => interaction.completed);
      // We sort the interaction from the newest to the oldest
      eventsCompleted.sort((a, b) => b.date - a.date);
      return eventsCompleted;
    },
    eventsUpcoming: (state, getters) => {
      // We get all the events that are not completed
      const eventsUpcoming = getters.events.filter(event => !event.completed);
      // We sort the event from the oldest to the newest
      eventsUpcoming.sort((a, b) => a.date - b.date);
      return eventsUpcoming;
    },
    interactionsUpcoming: (state, getters) => getters.eventsUpcoming.filter(event => event.isInteraction),
    tasksUpcoming: (state, getters) => [...getters.eventsUpcoming, ...getters.contactsReminders].sort((a, b) => a.date - b.date),
    dbLoaded: state => state.dbLoaded.contacts && state.dbLoaded.events && state.dbLoaded.userDoc,
    stats: state => state.userDoc.stats,
    hashtagsStats: (state) => {
      const hashtags = [];
      // We transform the hashtag object in an array and we remove the unused hashtags
      Object.keys(state.userDoc.hashtags).forEach((hashtag) => {
        if (state.userDoc.hashtags[hashtag] > 0) {
          hashtags.push({ name: hashtag, hits: state.userDoc.hashtags[hashtag] });
        }
      });
      // We sort the array
      hashtags.sort((a, b) => b.hits - a.hits || a.name.localeCompare(b.name));
      return hashtags;
    },
    hashtags: (state, getters) => {
      const hashtags = [];
      getters.hashtagsStats.forEach((hashtag) => {
        hashtags.push(hashtag.name);
      });
      return hashtags;
    },
    hashtagsTribute: (state, getters) => {
      const hashtags = [];
      getters.hashtagsStats.forEach((hashtag) => {
        hashtags.push({
          key: `#${hashtag.name}`,
          value: hashtag.name,
        });
      });
      return hashtags;
    },
    pendingWrites: (state, getters) => {
      let pendingWrites = 0;
      pendingWrites += getters.contacts.filter(contact => contact.hasPendingWrites).length;
      pendingWrites += getters.events.filter(event => event.hasPendingWrites).length;
      return pendingWrites;
    },
    userDoc: state => state.userDoc,
    mustUpdateDb: (state, getters) => getters.userDoc && getters.userDoc.version && parseInt(supportedVersion, 10) > parseInt(getters.userDoc.version, 10),
    mustUpdateApp: (state, getters) => {
      if (!getters.userDoc || !getters.userDoc.version) return false;
      const serverMajor = getters.userDoc.version.split('.')[0];
      const serverMinor = getters.userDoc.version.split('.')[1];
      const supportedMajor = supportedVersion.split('.')[0];
      const supportedMinor = supportedVersion.split('.')[1];
      return (serverMajor !== supportedMajor || serverMinor !== supportedMinor);
    },
    dbLocked: (state, getters) => getters.userDoc.lockedUntil > new Date(),
    readOnly: (state, getters) => getters.dbLocked || getters.mustUpdateDb,
  },
};
