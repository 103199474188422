<template>
  <f7-swiper-slide>
    <div class="slide-scrollable-container">
      <f7-block data-swiper-parallax="-300">
        <p>{{ $t('add-notes') }}</p>
      </f7-block>
      <f7-list media-list inset data-swiper-parallax="-200">
        <f7-block-header>
          <selection-switcher :elements="contacts" :elements-selected-id="contactsSelectedId" @new-selection="contactsSelectedId = $event" />
        </f7-block-header>
        <f7-list-item
          v-for="contact in contacts"
          :key="contact.id"
          checkbox
          :checked="contactsSelectedId.indexOf(contact.id) >= 0"
          :title="contact.name"
          :text="contact.notesRaw"
          @change="checkContact($event, contact.id)"
        >
          <!-- <f7-checkbox slot="media" :checked="contactsSelected.findIndex(item => item.id === contact.id) >= 0" @change="checkContact($event, contact)" /> -->
          <f7-link slot="after" icon-md="material:edit" icon-ios="f7:square_pencil" icon-aurora="f7:square_pencil" @click="contactClicked=contact" />
          <!-- <f7-chip v-for="hashtag in contact.hashtags" slot="subtitle" :key="hashtag" :text="'#'+hashtag" /> -->
        </f7-list-item>
        <f7-block-footer>
          <selection-switcher :elements="contacts" :elements-selected-id="contactsSelectedId" @new-selection="contactsSelectedId = $event" />
        </f7-block-footer>
      </f7-list>
    </div>

    <!-- Sheet Hashtags -->
    <sheet-hashtags :opened="showHashtagsModal" :contacts-selected-id="contactsSelectedId" @sheet:closed="showHashtagsModal = false" />

    <!-- Sheet Notes -->
    <f7-sheet
      push
      backdrop
      class="onboarding-notes-editor-modal"
      style="/* height:auto; max-height: calc(0.7 * (100% - var(--keyboard-height, 0px))); */"
      :opened="!!contactClicked"
      @sheet:closed="sheetClosed"
    >
      <f7-toolbar>
        <div>
          <f7-link sheet-close>
            {{ $t('common.cancel') }}
          </f7-link>
        </div>
        <div>{{ contactClicked ? contactClicked.name : null }}</div>
        <div>
          <f7-link @click="saveNotes">
            {{ $t('common.save') }}
          </f7-link>
        </div>
      </f7-toolbar>
      <f7-page-content>
        <f7-list no-hairlines class="notes-container">
          <notes-editor
            slot="list"
            :object="contactClicked"
            @change="(value) => newNotes = value"
          />
        </f7-list>
      </f7-page-content>
    </f7-sheet>

    <f7-fab :class="{ disabled: !contactsSelectedId.length }" position="right-bottom" :text="$t('hashtags')" @click="showHashtagsModal=true">
      <f7-icon ios="f7:tag_fill" aurora="f7:tag_fill" md="material:local_offer" />
    </f7-fab>
  </f7-swiper-slide>
</template>

<script>
import { mapGetters } from 'vuex';
import NotesEditor from './notes-editor.vue';
import SelectionSwitcher from './selection-switcher.vue';
import SheetHashtags from './sheet-hashtags.vue';
import Contact from '../class/contact.class';

export default {
  components: { NotesEditor, SelectionSwitcher, SheetHashtags },
  data() {
    return {
      contactClicked: null,
      contactsSelectedId: [],
      newNotes: null,
      showHashtagsModal: false,
      dummyContact: new Contact({ notes: 'lorem ipsum' }),
    };
  },
  computed: {
    ...mapGetters([
      'contacts', 'hashtags',
    ]),
  },
  methods: {
    checkContact(event, contactId) {
      if (event.target.checked) {
        this.contactsSelectedId.push(contactId);
      } else {
        this.contactsSelectedId = this.contactsSelectedId.filter(el => el !== contactId);
      }
    },
    saveNotes() {
      const oldNotes = this.contactClicked.notes;
      this.contactClicked.notes = this.newNotes;
      // If the notes have changed we update them on the server
      if (oldNotes !== this.contactClicked.notes) {
        this.$store.dispatch('updateNotes', this.contactClicked);
      }
      this.contactClicked = null;
    },
    sheetClosed() {
      this.contactClicked = null;
      // The following lines reset the content of the textEditor after it has been modified
      // Could be replaced by a clearValue() in the future: https://github.com/framework7io/framework7/pull/3794
      const textEditor = this.$f7.textEditor.get('.onboarding-notes-editor-modal .text-editor');
      textEditor.setValue(null);
      if (!textEditor.$contentEl.html()) textEditor.insertPlaceholder();
    },
  },
};
</script>

<style lang="less" scoped>
.list /deep/ .item-title {
  font-weight: var(--f7-list-item-title-font-weight);
}
/deep/ .item-subtitle {
  white-space: pre-wrap;
}
/*
.chip {
  padding: 1px 5px;
  height: 1.3em;
  margin-right: 2px;
}
*/
.sheet-modal /deep/ .list {
  margin: 0;
}

.notes-container {
  height: 100%;
}
.notes-container,
.notes-container /deep/ ul,
.notes-container /deep/ li,
.notes-container /deep/ .event-notes,
.notes-container /deep/ .item-content,
.notes-container /deep/ .item-inner,
.notes-container /deep/ .item-input-wrap,
.notes-container /deep/ .text-editor {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}

.ios .onboarding li.media-item {
  --f7-list-item-padding-vertical: 8px;
}
.md .onboarding li.media-item {
  --f7-list-item-padding-vertical: 8px;
}
</style>

<i18n>
{
  "en": {
    "add-notes": "Add notes to your contacts, and organize them with #hashtags.",
    "hashtags": "Hashtags"
   },
  "fr": {
    "add-notes": "Ajoutez des notes à vos contacts, et organisez les avec des #hashtags.",
    "hashtags": "Hashtags"
  }
}
</i18n>
