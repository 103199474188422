<template>
  <f7-swiper-slide>
    <div v-if="!contacts.length" class="overlay">
      <div class="overlay-container">
        <p data-swiper-parallax="-300">
          {{ $t('add-contacts') }}
        </p>
        <template v-if="showImportFromPhone">
          <p data-swiper-parallax="-200">
            <f7-button fill large round href="/p/contacts-phone-list/?onboarding=true">
              {{ $t('import-contacts') }}
            </f7-button>
          </p>
          <p data-swiper-parallax="-100">
            <f7-button large href="/p/contact-new/?onboarding=true">
              {{ $t("create-contact-manually") }}
            </f7-button>
          </p>
        </template>
        <template v-else>
          <p data-swiper-parallax="-200">
            <f7-button fill large round href="/p/contact-new/?onboarding=true">
              {{ $t('create-contact') }}
            </f7-button>
          </p>
        </template>
        <p class="text" v-html="$t('examples')" />
      </div>
    </div>
    <template v-else>
      <div class="slide-scrollable-container">
        <f7-block data-swiper-parallax="-300">
          <p>{{ $t('add-contacts') }}</p>
        </f7-block>
        <f7-list inset data-swiper-parallax="-200">
          <f7-list-item v-for="contact in contacts" :key="contact.id" :title="contact.name">
            <f7-link slot="after" icon-md="material:delete" icon-ios="f7:trash_fill" icon-aurora="f7:trash_fill" @click="deleteContact(contact)" />
          </f7-list-item>
        </f7-list>
      </div>
      <f7-fab :class="{ disabled: !contacts.length }" position="right-bottom" text="Contact" href="/p/contact-new/?onboarding=true">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add" />
      </f7-fab>
    </template>
  </f7-swiper-slide>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showImportFromPhone: false,
    };
  },
  computed: {
    ...mapGetters([
      'contacts',
    ]),
    nextDisabled() {
      return !this.contacts.length;
    },
  },
  mounted() {
    this.showImportFromPhone = navigator.contacts && navigator.contacts.find; // navigator.contacts is available on Chrome, but it is not the same
  },
  methods: {
    deleteContact(contact) {
      this.$store.dispatch('deleteContact', contact);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "add-contacts": "Add up to 50 contacts that you want to interact with more often.",
    "examples": "A (former) client?<br>A (former) colleague?<br>A (former) teacher?",
    "import-contacts": "Select & import contacts",
    "create-contact-manually": "Create a contact manually",
    "create-contact": "Create a contact"
  },
  "fr": {
    "add-contacts": "Ajoutez jusqu'à 50 contacts avec lesquels vous voulez interagir plus souvent.",
    "examples": "Un (ancien) client ?<br>Un (ancien) collègue ?<br>Un (ancien) professeur ?",
    "import-contacts": "Choisir des contacts à importer",
    "create-contact-manually": "Créer un contact manuellement",
    "create-contact": "Créer un contact"
  }
}
</i18n>
