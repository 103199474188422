<template>
  <f7-page name="update-db">
    <f7-navbar>
      <f7-nav-left>
        <f7-link popover-open=".popover-menu-update-db">
          <f7-icon ios="f7:menu" aurora="f7:menu" md="material:menu" />
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
    </f7-navbar>
    <f7-block class="text-align-center">
      <h3>{{ $t('subtitle') }}</h3>
    </f7-block>
    <f7-block strong class="text-align-center">
      <p>{{ $t('explanation') }}</p>
      <p>
        <f7-button v-if="updateLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else fill large round @click="startUpdate">
          {{ $t('update-button') }}
        </f7-button>
      </p>
      <p>{{ $t('information') }}</p>
    </f7-block>
    <f7-popover class="popover-menu-update-db">
      <f7-list>
        <f7-list-button v-if="user && !user.isAnonymous" popover-close :title="$t('manage-account')" href="/p/account-management/" />
        <f7-list-button v-if="user && !user.isAnonymous" popover-close :title="$t('disconnect')" color="red" @click="logout" />
        <f7-list-button v-if="user && user.isAnonymous" popover-close :title="$t('anonymousAccount-delete')" color="red" @click="deleteUser" />
      </f7-list>
    </f7-popover>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      updateLoading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'mustUpdateDb']),
  },
  mounted() { },
  methods: {
    async startUpdate() {
      this.updateLoading = true;
      const resultUpdate = await this.$store.dispatch('updateDB');
      console.log('result Update', resultUpdate);
      // contact server
      // this.updateLoading = false;
    },
    logout() {
      this.$store.dispatch('logout');
    },
    deleteUser() {
      this.$f7.dialog.confirm(
        this.$t('anonymousAccount-delete-warning'),
        this.$t('anonymousAccount-delete'),
        () => {
          this.$store.dispatch('deleteUser');
        },
      );
    },
  },
};
</script>

<style lang="less" scoped>

</style>

<i18n>
{
  "en": {
    "title": "Update required",
    "subtitle": "This version of the app requires a database update.",
    "explanation": "If you use Kipinto on other devices and recently made offline modifications, please ensure you sync them all before starting the update.",
    "information": "The update requires an internet connection and should last around one minute.",
    "update-button": "Start update",
    "disconnect": "Disconnect",
    "manage-account": "Manage Account",
    "anonymousAccount-delete": "Delete the account",
    "anonymousAccount-delete-warning": "<p>If you delete your temporary account, all the data will be lost forever.</p>"
  },
  "fr": {
    "title": "Update nécessaire",
    "subtitle": "Cette version de l'application nécessite que nous fassions une mise-à-jour de la base de donnée.",
    "explanation": "Si vous utilisez Kipinto sur d'autres appareils et avez récemment fait des modifications hors lignes, assurez vous qu'elles ont bien été synchronisées avant de lancer la mise-à-jour.",
    "information": "La mise-à-jour nécessite une connexion internet et devrait durer environ une minute.",
    "update-button": "Démarrer la mise-à-jour",
    "disconnect": "Se déconnecter",
    "manage-account": "Gérer votre compte",
    "anonymousAccount-delete": "Supprimer le compte",
    "anonymousAccount-delete-warning": "<p>Si vous supprimez le compte de test, toutes les données seront perdues.</p>"
  }
}
</i18n>
